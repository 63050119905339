
import { fileApi, orderApi } from "./../store";
import Component, { mixins } from "vue-class-component";
import Navigation from "../components/Navigation.vue";
import OrderDetail, {
  InitialOrderValues,
} from "./../components/OrderDetail.vue";
import DeleteConfirmation from "../components/DeleteConfirmation.vue";
import { UserMixin } from "../mixins/user";
import {
  FileDTO,
  FileRedirectDTO,
  FileType,
  OrderAppOverviewDTO,
} from "./../api";
import { AppFile, AppFiles } from "./../interfaces";
import dayjs from "dayjs";
import { openFileInNewTab } from "./../helpers";

@Component({ components: { Navigation, DeleteConfirmation, OrderDetail } })
export default class PictureApp extends mixins(UserMixin) {
  $refs!: {
    imagePicker: HTMLElement;
  };

  orders: OrderAppOverviewDTO[] = [];
  loading: boolean = false;
  currentIndex: number = 0;
  currentPictureIndex: number = 0;
  filesToUpload: AppFiles[] = [];
  showDeleteDialog: boolean = false;
  modifyingReport: boolean = false;
  carouselIndex: number[] = [];
  carouselKey: string = "carousel";
  uploadButtonKey: string = "upload";
  showUploadDialog: boolean = false;
  progressText: string = "";
  showDeleteReportDialog: boolean = false;
  showOrderDialog: boolean = false;
  searchText: string = "";
  showAllAssemblies: boolean = false;

  editOrderValues: InitialOrderValues = {
    id: "",
    salesmanId: "",
    isExhibitionOrder: false,
  };

  async created() {
    this.loading = true;
    try {
      this.orders = (await orderApi.getOrdersForApp()).data;
      this.filesToUpload = this.orders.map(() => ({
        reportFiles: [],
        pictureFiles: [],
      }));
    } finally {
      this.loading = false;
    }
  }

  uploadPicture(index: number, modifyingReport: boolean) {
    this.currentIndex = index;
    this.modifyingReport = modifyingReport;
    this.$refs.imagePicker.click();
  }

  getFullAddress(order: OrderAppOverviewDTO): string {
    return `${order.address}, ${order.zipCode} ${order.city}`;
  }

  async onPictureAdded(e: any) {
    const orderFile: AppFiles = this.filesToUpload[this.currentIndex];
    const files: AppFile[] = [];
    for (const file of e.target.files) {
      files.push({ url: URL.createObjectURL(file), file });
    }
    if (this.modifyingReport) {
      orderFile.reportFiles = [...orderFile.reportFiles, ...files];
    } else {
      orderFile.pictureFiles = [...orderFile.pictureFiles, ...files];
    }
    this.$set(this.filesToUpload, this.currentIndex, orderFile);
    this.carouselKey = `carousel-${new Date().getTime()}`;
    this.carouselIndex[this.currentIndex] =
      orderFile.reportFiles.length + orderFile.pictureFiles.length - 1;
  }

  deletePicture(index: number, pictureIndex: number, isReportFile: boolean) {
    this.currentIndex = index;
    this.currentPictureIndex = pictureIndex;
    this.modifyingReport = isReportFile;
    this.showDeleteDialog = true;
  }

  confirmDelete(doDelete: boolean) {
    this.showDeleteDialog = false;
    if (!doDelete) {
      return;
    }
    if (this.modifyingReport) {
      this.filesToUpload[this.currentIndex].reportFiles.splice(
        this.currentPictureIndex,
        1
      );
    } else {
      this.filesToUpload[this.currentIndex].pictureFiles.splice(
        this.currentPictureIndex,
        1
      );
    }
  }

  showUploadImagesDialog(index: number) {
    this.currentIndex = index;
    this.showUploadDialog = true;
  }

  async uploadImages(confirm: boolean) {
    this.showUploadDialog = false;
    if (!confirm) {
      return;
    }
    try {
      this.progressText = this.$t("app.progressUploadPDF").toString();
      const imageData = [
        ...this.filesToUpload[this.currentIndex].reportFiles,
        ...this.filesToUpload[this.currentIndex].pictureFiles,
      ].map((entry) => entry.file);
      const filename = `${this.$t(
        this.isCarpenter ? "app.assemblyReport" : "app.measurementReport"
      )} ${this.$options.filters!.formatDateObject(dayjs())}.pdf`;
      await fileApi.uploadAppPictures(
        filename,
        imageData,
        this.orders[this.currentIndex].id,
        this.currentFileType
      );
      this.orders[this.currentIndex].fileExists = true;
    } finally {
      this.progressText = "";
    }
  }

  async openFile(id: string, fileType: FileType) {
    const files: FileDTO[] = (await fileApi.getFiles(id, fileType)).data;
    if (!files.length) {
      return;
    }
    const fileRedirect: FileRedirectDTO = (
      await fileApi.getFileRedirect(
        files[0].id!,
        files[0].type!,
        files[0].name!
      )
    ).data;
    openFileInNewTab(fileRedirect);
  }

  deleteReport(index: number) {
    this.currentIndex = index;
    this.showDeleteReportDialog = true;
  }

  confirmDeleteReport(doDelete: boolean) {
    this.showDeleteReportDialog = false;
    if (!doDelete) {
      return;
    }
    this.orders[this.currentIndex].fileExists = false;
  }

  editOrder(index: number) {
    this.editOrderValues = {
      id: this.orders[index].id!,
      salesmanId: this.currentUser.user!.id!,
      isExhibitionOrder: false,
    };
    this.showOrderDialog = true;
  }

  get filteredOrders(): OrderAppOverviewDTO[] {
    let filteredOrders = this.orders;
    if (this.searchText) {
      filteredOrders = filteredOrders.filter((order) =>
        JSON.stringify(order)
          .toLowerCase()
          .includes(this.searchText.toLowerCase())
      );
    }
    if (this.isCarpenter && !this.showAllAssemblies) {
      const today = dayjs().format("YYYY-MM-DD");
      filteredOrders = filteredOrders.filter(
        (order) =>
          order.assemblyDate === today || order.secondAssemblyDate === today
      );
    }
    return filteredOrders;
  }

  get currentFileType(): FileType {
    return this.isCarpenter
      ? FileType.MiscAssemblyReport
      : FileType.PlanMeasurement;
  }
}
