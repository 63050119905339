
import Component, { mixins } from "vue-class-component";
import Navigation from "../components/Navigation.vue";
import DeleteConfirmation from "../components/DeleteConfirmation.vue";
import RightManagement from "../components/RightManagement.vue";
import DefaultValues from "../components/DefaultValues.vue";
import {
  CarpenterDTO,
  ExhibitionSalesmanDTO,
  FileDTO,
  FileRedirectDTO,
  FileType,
  LeadSourceDTO,
  LocationDTO,
  SupplierDTO,
  UserDTO,
  UserRight,
  UserRole,
} from "../api";
import { fileApi, RootActions, RootGetters, userApi } from "../store";
import { Getter, Action } from "vuex-class";
import dayjs from "dayjs";
import { FileAddAction, FileDeleteAction, FileQueue } from "../components/OrderFiles.vue";
import { LocationMixin } from "../mixins/location";
import { DropdownPair } from "../interfaces";
import { UserMixin } from "../mixins/user";
import { ValidationProvider } from "vee-validate";
import Sortable, { SortableEvent } from "sortablejs";
import { Watch } from "vue-property-decorator";
import { openFileInNewTab } from "../helpers";
import { StyleValue } from "vue/types/jsx";

@Component({
  components: {
    Navigation,
    DeleteConfirmation,
    RightManagement,
    DefaultValues,
  },
})
export default class Settings extends mixins(UserMixin, LocationMixin) {
  $refs!: {
    uploader: HTMLElement;
    docsUploader: HTMLElement;
    validationObserver: InstanceType<typeof ValidationProvider>;
  };

  @Getter(RootGetters.getTableFooterProps)
  dataTableFooterProps: any;
  @Getter(RootGetters.getCarpenters)
  carpenters!: CarpenterDTO[];
  @Getter(RootGetters.getSuppliers)
  suppliers!: SupplierDTO[];
  @Getter(RootGetters.getLeadSources)
  leadSources!: LeadSourceDTO[];
  @Getter(RootGetters.getBasePath)
  basePath!: string;

  @Action(RootActions.getBaseData)
  getBaseData!: () => void;
  @Action(RootActions.createOrUpdateLocation)
  createOrUpdateLocation!: (payload: LocationDTO) => void;
  @Action(RootActions.reorderLocations)
  reorderLocations!: (payload: SortableEvent) => void;
  @Action(RootActions.createOrUpdateCarpenter)
  createOrUpdateCarpenter!: (payload: CarpenterDTO) => void;
  @Action(RootActions.reorderCarpenters)
  reorderCarpenters!: (payload: SortableEvent) => void;
  @Action(RootActions.createOrUpdateSupplier)
  createOrUpdateSupplier!: (payload: SupplierDTO) => void;
  @Action(RootActions.reorderSuppliers)
  reorderSuppliers!: (payload: SortableEvent) => void;
  @Action(RootActions.createOrUpdateExhibitionSalesman)
  createOrUpdateExhibitionSalesman!: (payload: ExhibitionSalesmanDTO) => void;
  @Action(RootActions.reorderExhibitionSalesmen)
  reorderExhibitionSalesmen!: (payload: SortableEvent) => void;
  @Action(RootActions.createOrUpdateLeadSource)
  createOrUpdateLeadSource!: (payload: LeadSourceDTO) => void;
  @Action(RootActions.reorderLeadSources)
  reorderLeadSources!: (payload: SortableEvent) => void;
  @Action(RootActions.deleteCarpenter)
  deleteCarpenter!: (id: string) => void;
  @Action(RootActions.deleteSupplier)
  deleteSupplier!: (id: string) => void;
  @Action(RootActions.deleteExhibitionSalesman)
  deleteExhibitionSalesman!: (id: string) => void;
  @Action(RootActions.deleteLeadSource)
  deleteLeadSource!: (id: string) => void;

  @Action(RootActions.getUsers)
  getUsers!: () => void;
  @Action(RootActions.createOrUpdateUser)
  createOrUpdateUser!: (payload: UserDTO) => void;
  @Action(RootActions.deleteUser)
  deleteUser!: (id: string) => void;

  baseDataTab: number = 0;
  userRoleTab: number = 0;
  userRoleTabs: UserRole[] = [UserRole.Admin, UserRole.Salesman];
  settingTab: number = 0;

  loading: boolean = false;
  saving: boolean = false;
  showDeleteDialog: boolean = false;
  showEditDialog: boolean = false;
  editElement: CarpenterDTO | SupplierDTO | LeadSourceDTO | ExhibitionSalesmanDTO = {
    name: "",
  };
  showEditLocationDialog: boolean = false;
  editLocation: LocationDTO = { vat: 20 };

  showUserEditDialog: boolean = false;
  showUserRightDialog: boolean = false;
  editUser: UserDTO = { roles: [] };

  locationFiles: FileDTO[] = [];
  locationTemplateDocsImageUrl: string = "";
  uploading: boolean = false;
  uploadFile: File | null = null;
  fileQueue: FileQueue[] = [];
  mailExists: boolean = false;

  headers = [
    { text: this.$t("name"), value: "name" },
    { text: "", align: "right", value: "actions", sortable: false },
  ];

  exhibitionSalesmenHeaders = [
    { text: this.$t("name"), value: "name" },
    { text: this.$t("location"), value: "locationId", sortable: false },
    { text: "", align: "right", value: "actions", sortable: false },
  ];

  userHeaders = [
    { text: this.$t("firstName"), value: "firstName" },
    { text: this.$t("lastName"), value: "lastName" },
    { text: this.$t("email"), value: "email" },
    { text: this.$t("userRole"), value: "roles", sortable: false },
    { text: this.$t("location"), value: "location", sortable: false },
    { text: "", align: "right", value: "actions", sortable: false },
  ];

  userRoleFilter: UserRole[] = [];
  userRoleValues: Array<DropdownPair<UserRole>> = [
    { key: UserRole.Admin, label: this.$t("userRoleEnum.ADMIN").toString() },
    {
      key: UserRole.Salesman,
      label: this.$t("userRoleEnum.SALESMAN").toString(),
    },
  ];

  locationSortable?: Sortable;
  isLocationSortable: boolean = true;
  carpenterSortable?: Sortable;
  isCarpenterSortable: boolean = true;
  supplierSortable?: Sortable;
  isSupplierSortable: boolean = true;
  exhibitionSalesmanSortable?: Sortable;
  isExhibitionSalesmanSortable: boolean = true;
  leadSourceSortable?: Sortable;
  isLeadSourceSortable: boolean = true;

  async created() {
    this.loading = true;
    try {
      await this.getBaseData();
      if (this.isAdmin) {
        await this.getUsers();
      }
    } finally {
      this.loading = false;
    }
  }

  tableRowClass(item: LocationDTO | UserDTO) {
    return item.enabled ? "" : "text--disabled";
  }

  // tslint:disable:variable-name
  renameElementDoubleClick(_event: any, element: any) {
    this.renameElement(element.item);
  }

  renameElement(element: CarpenterDTO | SupplierDTO | ExhibitionSalesmanDTO) {
    this.editElement = { ...element };
    this.$refs.validationObserver.reset();
    this.showEditDialog = true;
    this.mailExists = false;
  }

  deleteElement(element: CarpenterDTO | SupplierDTO | ExhibitionSalesmanDTO) {
    this.editElement = { ...element };
    this.showDeleteDialog = true;
  }

  addElement() {
    this.editElement = { name: "" };
    this.$refs.validationObserver.reset();
    this.showEditDialog = true;
    this.mailExists = false;
  }

  editLocationDoubleClick(_event: any, element: any) {
    this.editLocationElement(element.item);
  }

  async editLocationElement(location: LocationDTO) {
    this.editLocation = { ...location };
    this.$refs.validationObserver.reset();
    this.showEditLocationDialog = true;
    this.fileQueue = [];
    this.gatherLocationFiles(location);
  }

  async gatherLocationFiles(location: LocationDTO) {
    this.locationTemplateDocsImageUrl = "";
    this.locationFiles = location.id ? (await fileApi.getFiles(location.id)).data : [];
    if (this.currentLocationDocsFile) {
      this.locationTemplateDocsImageUrl =
        (
          await fileApi.getFileRedirect(
            location.id!,
            FileType.TemplateDocs,
            this.currentLocationDocsFile.name!
          )
        ).data.url || "";
    }
  }

  async confirmEdit() {
    if (!(await this.$refs.validationObserver.validate())) {
      return;
    }
    this.saving = true;
    try {
      if (this.currentBaseDataTabName === "carpenters") {
        const carpenter: CarpenterDTO = this.editElement as CarpenterDTO;
        if (carpenter.hasAppUser) {
          this.mailExists = (
            await userApi.doesMailExist(carpenter.email!, carpenter.linkedUserId)
          ).data;
          if (this.mailExists) {
            return;
          }
        }
        this.createOrUpdateCarpenter(carpenter);
      } else if (this.currentBaseDataTabName === "suppliers") {
        this.createOrUpdateSupplier(this.editElement as SupplierDTO);
      } else if (this.currentBaseDataTabName === "leadSources") {
        this.createOrUpdateLeadSource(this.editElement as LeadSourceDTO);
      } else {
        this.createOrUpdateExhibitionSalesman(this.editElement as ExhibitionSalesmanDTO);
      }
      this.showEditDialog = false;
    } finally {
      this.saving = false;
    }
  }

  async confirmLocationEdit(keepDialogOpen?: boolean) {
    if (!(await this.$refs.validationObserver.validate())) {
      return;
    }
    if (keepDialogOpen !== true) {
      this.showEditLocationDialog = false;
    }
    await this.createOrUpdateLocation(this.editLocation);
    await this.executeFileQueue();
  }

  // tslint:disable:variable-name
  editUserDoubleClick(_event: any, element: any) {
    this.editUserElement(element.item);
  }

  editUserElement(user: UserDTO) {
    this.$refs.validationObserver.reset();
    this.editUser = JSON.parse(JSON.stringify(user));
    this.mailExists = false;
    this.showUserEditDialog = true;
  }

  showUserRightsDialog(user: UserDTO) {
    this.editUser = user;
    this.showUserRightDialog = true;
  }

  showDeleteUserDialog(user: UserDTO) {
    this.editUser = user;
    this.showDeleteDialog = true;
  }

  async confirmUserEdit() {
    try {
      this.saving = true;
      if (!(await this.$refs.validationObserver.validate())) {
        return;
      }
      this.mailExists = (
        await userApi.doesMailExist(this.editUser.email!, this.editUser.id)
      ).data;
      if (this.mailExists) {
        return;
      }
      this.createOrUpdateUser(this.editUser);
      this.showUserEditDialog = false;
    } finally {
      this.saving = false;
    }
  }

  deleteFile(type: FileType) {
    this.showDeleteDialog = true;
  }

  confirmDelete(doDelete: boolean) {
    this.showDeleteDialog = false;
    if (!doDelete) {
      return;
    }
    if (this.editLocation.id) {
      const editFile: FileDTO = this.currentLocationDocsFile!;
      this.locationFiles.splice(
        this.locationFiles.findIndex(
          (file) => file.type === editFile.type && file.name === editFile.name
        ),
        1
      );
      this.fileQueue.push({
        deleteAction: {
          fileName: editFile.name!,
          fileType: editFile.type!,
          additionalId: "",
        },
      });
      if (editFile.type === FileType.TemplateDocs) {
        this.locationTemplateDocsImageUrl = "";
        (this.$refs.docsUploader as any).value = null;
      }
    } else if (this.currentBaseDataTabName === "carpenters") {
      this.deleteCarpenter(this.editElement.id!);
    } else if (this.currentBaseDataTabName === "suppliers") {
      this.deleteSupplier(this.editElement.id!);
    } else if (this.currentBaseDataTabName === "exhibitionSalesmen") {
      this.deleteExhibitionSalesman(this.editElement.id!);
    } else if (this.currentBaseDataTabName === "leadSources") {
      this.deleteLeadSource(this.editElement.id!);
    } else if (this.currentBaseDataTabName === "users") {
      this.deleteUser(this.editUser.id!);
    }
  }

  uploadFileClick() {
    this.$refs.docsUploader.click();
  }

  onFileChanged(e: any) {
    this.uploadFile = e.target.files[0];
    this.confirmUpload();
  }

  async onDocsFileChanged(e: any) {
    this.uploadFile = e.target.files[0];
    this.confirmUpload();
    await this.confirmLocationEdit(true);
    this.gatherLocationFiles(this.editLocation);
  }

  get currentLocationDocsFile(): FileDTO | undefined {
    return this.locationFiles.find((file) => file.type === FileType.TemplateDocs);
  }

  async openFile() {
    const file: FileDTO | undefined = this.currentLocationDocsFile;
    if (!file || !file.id) {
      return;
    }
    const fileRedirect: FileRedirectDTO = (
      await fileApi.getFileRedirect(file.id, file.type!, file.name!, file.additionalId)
    ).data;
    openFileInNewTab(fileRedirect);
  }

  async confirmUpload() {
    this.fileQueue.push({
      addAction: {
        file: this.uploadFile,
        fileType: FileType.TemplateDocs,
        additionalId: "",
      },
    });
    if (!this.currentLocationDocsFile) {
      this.locationFiles.push({
        name: this.uploadFile?.name,
        type: FileType.TemplateDocs,
      });
    }
    this.uploadFile = null;
  }

  async executeFileQueue() {
    for (const queueAction of this.fileQueue) {
      if (queueAction.deleteAction) {
        const deleteAction: FileDeleteAction = queueAction.deleteAction;
        await fileApi.deleteFile(
          this.editLocation.id!,
          deleteAction.fileType,
          deleteAction.fileName
        );
      } else if (queueAction.addAction) {
        const addAction: FileAddAction = queueAction.addAction;
        this.uploading = true;
        try {
          await fileApi.uploadFile(
            addAction.file,
            this.editLocation.id!,
            addAction.fileType,
            addAction.additionalId
          );
        } finally {
          this.uploading = false;
        }
      }
    }
    this.fileQueue = [];
  }

  get docsMarginTopClass(): StyleValue {
    if (!this.editLocation) {
      return {};
    }
    return {
      position: "relative",
      top: `${this.editLocation.marginDocsTop}%`,
      border: "1px dashed red",
    };
  }

  get docsMarginBottomClass(): StyleValue {
    if (!this.editLocation) {
      return {};
    }
    return {
      position: "relative",
      top: `${100 - this.editLocation.marginDocsBottom!}%`,
      border: "1px dashed red",
    };
  }

  get billPrefixHint(): string {
    if (!this.editLocation.billPrefix) {
      return "";
    }
    return this.$t("billPrefixHint", {
      example: `${this.editLocation.billPrefix}-${dayjs().format("YYYYMM0001")}`,
    }).toString();
  }

  get settingTabs(): string[] {
    const toReturn = ["baseData"];
    if (this.isAdmin) {
      toReturn.push("defaultValues");
      toReturn.push("userRights");
    }
    return toReturn;
  }

  get baseDataTabs(): string[] {
    const adminTabs = this.isAdmin ? ["locations", "users"] : [];
    const userTabs = [...adminTabs, "carpenters", "suppliers", "leadSources"];
    if (this.hasExhibitionPackage) {
      userTabs.push("exhibitionSalesmen");
    }
    return userTabs;
  }

  get canEdit(): boolean {
    return this.hasUserRight(UserRight.BaseDataWrite);
  }

  get canDelete(): boolean {
    return this.hasUserRight(UserRight.BaseDataDelete);
  }

  get currentSettingTabName(): string {
    return this.settingTabs[this.settingTab];
  }

  get currentBaseDataTabName(): string {
    if (this.currentSettingTabName !== "baseData") {
      return "";
    }
    return this.baseDataTabs[this.baseDataTab];
  }

  get filteredUsers(): UserDTO[] {
    if (!this.userRoleFilter.length) {
      return this.users;
    }
    return this.users.filter((user) => {
      for (const role of user.roles!) {
        if (this.userRoleFilter.includes(role)) {
          return true;
        }
      }
      return false;
    });
  }

  get amountRemainingCarpenterAppUsers(): number {
    const existingAppUserCount = this.carpenters.filter((entry) => entry.hasAppUser)
      .length;
    return this.constraint.maximumCarpenterAppUserCount - existingAppUserCount;
  }

  @Watch("currentBaseDataTabName")
  updateSortables() {
    setTimeout(() => {
      if (this.currentBaseDataTabName === "locations") {
        this.updateLocationSortable();
      } else if (this.currentBaseDataTabName === "carpenters") {
        this.updateCarpentersSortable();
      } else if (this.currentBaseDataTabName === "suppliers") {
        this.updateSuppliersSortable();
      } else if (this.currentBaseDataTabName === "exhibitionSalesmen") {
        this.updateExhibitionSalesmenSortable();
      } else if (this.currentBaseDataTabName === "leadSources") {
        this.updateLeadSourcesSortable();
      }
    }, 200);
  }

  @Watch("locations")
  updateLocationSortable() {
    const locationTable: any = document.querySelector(".location-table tbody");
    if (!locationTable) {
      return;
    }
    this.locationSortable?.destroy();
    this.locationSortable = Sortable.create(locationTable, {
      onEnd: (event: SortableEvent) => {
        this.reorderLocations(event);
      },
    });
  }

  @Watch("carpenters")
  updateCarpentersSortable() {
    const carpenterTable: any = document.querySelector(".carpenter-table tbody");
    if (!carpenterTable) {
      return;
    }
    this.carpenterSortable?.destroy();
    this.carpenterSortable = Sortable.create(carpenterTable, {
      onEnd: (event: SortableEvent) => {
        this.reorderCarpenters(event);
      },
    });
  }

  @Watch("suppliers")
  updateSuppliersSortable() {
    const supplierTable: any = document.querySelector(".supplier-table tbody");
    if (!supplierTable) {
      return;
    }
    this.supplierSortable?.destroy();
    this.supplierSortable = Sortable.create(supplierTable, {
      onEnd: (event: SortableEvent) => {
        this.reorderSuppliers(event);
      },
    });
  }

  @Watch("exhibitionSalesmen")
  updateExhibitionSalesmenSortable() {
    const exhibitionSalesmenTable: any = document.querySelector(
      ".exhibition-salesmen-table tbody"
    );
    if (!exhibitionSalesmenTable) {
      return;
    }
    this.exhibitionSalesmanSortable?.destroy();
    this.exhibitionSalesmanSortable = Sortable.create(exhibitionSalesmenTable, {
      onEnd: (event: SortableEvent) => {
        this.reorderExhibitionSalesmen(event);
      },
    });
  }

  @Watch("leadSources")
  updateLeadSourcesSortable() {
    const leadSourcesTable: any = document.querySelector(".lead-sources-table tbody");
    if (!leadSourcesTable) {
      return;
    }
    this.leadSourceSortable?.destroy();
    this.leadSourceSortable = Sortable.create(leadSourcesTable, {
      onEnd: (event: SortableEvent) => {
        this.reorderLeadSources(event);
      },
    });
  }

  updateLocationSortBy(sortBy: string) {
    this.isLocationSortable = sortBy == null;
    if (!this.locationSortable) {
      return;
    }
    this.locationSortable.options.disabled = !this.isLocationSortable;
  }

  updateCarpenterSortBy(sortBy: string) {
    this.isCarpenterSortable = sortBy == null;
    if (!this.carpenterSortable) {
      return;
    }
    this.carpenterSortable.options.disabled = !this.isCarpenterSortable;
  }

  updateSupplierSortBy(sortBy: string) {
    this.isSupplierSortable = sortBy == null;
    if (!this.supplierSortable) {
      return;
    }
    this.supplierSortable.options.disabled = !this.isSupplierSortable;
  }

  updateExhibitionSalesmanSortBy(sortBy: string) {
    this.isExhibitionSalesmanSortable = sortBy == null;
    if (!this.exhibitionSalesmanSortable) {
      return;
    }
    this.exhibitionSalesmanSortable.options.disabled = !this.isExhibitionSalesmanSortable;
  }

  updateLeadSourceSortBy(sortBy: string) {
    this.isLeadSourceSortable = sortBy == null;
    if (!this.leadSourceSortable) {
      return;
    }
    this.leadSourceSortable.options.disabled = !this.isLeadSourceSortable;
  }
}
