/* tslint:disable */
/* eslint-disable */
/**
 * KOMO API
 * Backend for KOMO
 *
 * The version of the OpenAPI document: 1.0
 * Contact: office@alphaport.at
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdditionalExpense
 */
export interface AdditionalExpense {
    /**
     * 
     * @type {string}
     * @memberof AdditionalExpense
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AdditionalExpense
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface AdditionalExpenseDTO
 */
export interface AdditionalExpenseDTO {
    /**
     * 
     * @type {string}
     * @memberof AdditionalExpenseDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AdditionalExpenseDTO
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface AdditionalPackageDTO
 */
export interface AdditionalPackageDTO {
    /**
     * 
     * @type {AdditionalPackageType}
     * @memberof AdditionalPackageDTO
     */
    type: AdditionalPackageType;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AdditionalPackageDTO
     */
    price: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof AdditionalPackageDTO
     */
    maximumAppUserCount?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AdditionalPackageType {
    Complaint = 'COMPLAINT',
    Exhibition = 'EXHIBITION',
    Todo = 'TODO',
    SalesStatistics = 'SALES_STATISTICS',
    CarpenterApp = 'CARPENTER_APP',
    DocumentPrint = 'DOCUMENT_PRINT',
    Stock = 'STOCK'
}

/**
 * 
 * @export
 * @interface BaseDataDTO
 */
export interface BaseDataDTO {
    /**
     * 
     * @type {Array<CarpenterGetDTO>}
     * @memberof BaseDataDTO
     */
    carpenters?: Array<CarpenterGetDTO>;
    /**
     * 
     * @type {Array<SupplierGetDTO>}
     * @memberof BaseDataDTO
     */
    suppliers?: Array<SupplierGetDTO>;
    /**
     * 
     * @type {Array<ExhibitionSalesmanGetDTO>}
     * @memberof BaseDataDTO
     */
    exhibitionSalesmen?: Array<ExhibitionSalesmanGetDTO>;
    /**
     * 
     * @type {Array<LocationDTO>}
     * @memberof BaseDataDTO
     */
    locations?: Array<LocationDTO>;
    /**
     * 
     * @type {Array<LeadSourceDTO>}
     * @memberof BaseDataDTO
     */
    leadSources?: Array<LeadSourceDTO>;
    /**
     * 
     * @type {ConstraintDTO}
     * @memberof BaseDataDTO
     */
    constraint?: ConstraintDTO;
}
/**
 * 
 * @export
 * @interface BasePackageDTO
 */
export interface BasePackageDTO {
    /**
     * 
     * @type {BasePackageType}
     * @memberof BasePackageDTO
     */
    type: BasePackageType;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof BasePackageDTO
     */
    price: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof BasePackageDTO
     */
    maximumUserCount: number;
    /**
     * 
     * @type {number}
     * @memberof BasePackageDTO
     */
    maximumLocationCount: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof BasePackageDTO
     */
    priceForAdditionalLocation: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof BasePackageDTO
     */
    priceForAdditionalUser: { [key: string]: number; };
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BasePackageType {
    S = 'BASE_S',
    M = 'BASE_M',
    L = 'BASE_L'
}

/**
 * 
 * @export
 * @interface BilledOrderFilePostDTO
 */
export interface BilledOrderFilePostDTO {
    /**
     * 
     * @type {string}
     * @memberof BilledOrderFilePostDTO
     */
    title?: string;
    /**
     * 
     * @type {Array<BilledOrderOverviewDTO>}
     * @memberof BilledOrderFilePostDTO
     */
    billedOrders?: Array<BilledOrderOverviewDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BilledOrderFilePostDTO
     */
    headlineNames?: Array<string>;
}
/**
 * 
 * @export
 * @interface BilledOrderOverviewDTO
 */
export interface BilledOrderOverviewDTO {
    /**
     * 
     * @type {string}
     * @memberof BilledOrderOverviewDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BilledOrderOverviewDTO
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof BilledOrderOverviewDTO
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof BilledOrderOverviewDTO
     */
    clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof BilledOrderOverviewDTO
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BilledOrderOverviewDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof BilledOrderOverviewDTO
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof BilledOrderOverviewDTO
     */
    carpenterId?: string;
    /**
     * 
     * @type {Array<PaymentDTO>}
     * @memberof BilledOrderOverviewDTO
     */
    payments?: Array<PaymentDTO>;
    /**
     * 
     * @type {number}
     * @memberof BilledOrderOverviewDTO
     */
    finalPrice?: number;
    /**
     * 
     * @type {OrderBillType}
     * @memberof BilledOrderOverviewDTO
     */
    billType?: OrderBillType;
    /**
     * 
     * @type {number}
     * @memberof BilledOrderOverviewDTO
     */
    margin?: number;
    /**
     * 
     * @type {number}
     * @memberof BilledOrderOverviewDTO
     */
    marginSum?: number;
    /**
     * 
     * @type {string}
     * @memberof BilledOrderOverviewDTO
     */
    carpenterName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BilledOrderOverviewDTO
     */
    exhibitionSale?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BilledOrderOverviewDTO
     */
    salesmanName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BilledOrderOverviewDTO
     */
    missingDataForMargin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BilledOrderOverviewDTO
     */
    missingDataForMarginSum?: boolean;
}
/**
 * 
 * @export
 * @interface CarpenterDTO
 */
export interface CarpenterDTO {
    /**
     * 
     * @type {string}
     * @memberof CarpenterDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CarpenterDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CarpenterDTO
     */
    linkedUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarpenterDTO
     */
    hasAppUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarpenterDTO
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface CarpenterGetDTO
 */
export interface CarpenterGetDTO {
    /**
     * 
     * @type {string}
     * @memberof CarpenterGetDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CarpenterGetDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CarpenterGetDTO
     */
    linkedUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarpenterGetDTO
     */
    hasAppUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarpenterGetDTO
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarpenterGetDTO
     */
    isDeletable?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ChartAreaValuesGrouping {
    Week = 'WEEK',
    Month = 'MONTH'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ChartBarValuesGrouping {
    Year = 'YEAR',
    Month = 'MONTH',
    Day = 'DAY'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ChartDuration {
    Last30Days = 'LAST_30_DAYS',
    Last3Months = 'LAST_3_MONTHS',
    LastYear = 'LAST_YEAR',
    ThisMonth = 'THIS_MONTH',
    ThisYear = 'THIS_YEAR'
}

/**
 * 
 * @export
 * @interface ClientDTO
 */
export interface ClientDTO {
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    id?: string;
    /**
     * 
     * @type {ClientTargetGroup}
     * @memberof ClientDTO
     */
    targetGroup?: ClientTargetGroup;
    /**
     * 
     * @type {ClientSalutation}
     * @memberof ClientDTO
     */
    salutation?: ClientSalutation;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    vat?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    phone1?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    phone2?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    externalCustomerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    leadSourceId?: string;
}
/**
 * 
 * @export
 * @interface ClientData
 */
export interface ClientData {
    /**
     * 
     * @type {boolean}
     * @memberof ClientData
     */
    isCompany?: boolean;
    /**
     * 
     * @type {ClientSalutation}
     * @memberof ClientData
     */
    salutation?: ClientSalutation;
    /**
     * 
     * @type {string}
     * @memberof ClientData
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientData
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientData
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientData
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientData
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientData
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientData
     */
    vat?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientData
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientData
     */
    externalCustomerId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ClientSalutation {
    Mr = 'MR',
    Mrs = 'MRS',
    Family = 'FAMILY',
    NonBinary = 'NON_BINARY',
    NotSpecified = 'NOT_SPECIFIED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ClientTargetGroup {
    _020 = 'AGE_0_20',
    _2030 = 'AGE_20_30',
    _3040 = 'AGE_30_40',
    _4050 = 'AGE_40_50',
    _5060 = 'AGE_50_60',
    _60Plus = 'AGE_60_PLUS'
}

/**
 * 
 * @export
 * @interface Complaint
 */
export interface Complaint {
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    id?: string;
    /**
     * 
     * @type {ComplaintState}
     * @memberof Complaint
     */
    state?: ComplaintState;
    /**
     * 
     * @type {boolean}
     * @memberof Complaint
     */
    doNotForget?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Complaint
     */
    importance?: number;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    fixedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    availability?: string;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    stockToDo?: string;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    tasksToDo?: string;
    /**
     * 
     * @type {Array<OrderConnection>}
     * @memberof Complaint
     */
    connections?: Array<OrderConnection>;
    /**
     * 
     * @type {number}
     * @memberof Complaint
     */
    repairTimeInMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof Complaint
     */
    remainingAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Complaint
     */
    carpenterExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof Complaint
     */
    materialExpense?: number;
    /**
     * 
     * @type {ComplaintImposed}
     * @memberof Complaint
     */
    imposed?: ComplaintImposed;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    lastModifiedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    lastModifiedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    carpenterId?: string;
    /**
     * 
     * @type {string}
     * @memberof Complaint
     */
    orderId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Complaint
     */
    lockedDoneState?: boolean;
}
/**
 * 
 * @export
 * @interface ComplaintDTO
 */
export interface ComplaintDTO {
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    id?: string;
    /**
     * 
     * @type {ComplaintState}
     * @memberof ComplaintDTO
     */
    state?: ComplaintState;
    /**
     * 
     * @type {boolean}
     * @memberof ComplaintDTO
     */
    doNotForget?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ComplaintDTO
     */
    importance?: number;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    carpenterId?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    fixedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    availability?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    stockToDo?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    tasksToDo?: string;
    /**
     * 
     * @type {Array<OrderConnection>}
     * @memberof ComplaintDTO
     */
    connections?: Array<OrderConnection>;
    /**
     * 
     * @type {number}
     * @memberof ComplaintDTO
     */
    repairTimeInMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof ComplaintDTO
     */
    remainingAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ComplaintDTO
     */
    carpenterExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof ComplaintDTO
     */
    materialExpense?: number;
    /**
     * 
     * @type {ComplaintImposed}
     * @memberof ComplaintDTO
     */
    imposed?: ComplaintImposed;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    lastModifiedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintDTO
     */
    lastModifiedDate?: string;
}
/**
 * 
 * @export
 * @interface ComplaintDataPlotValueDTO
 */
export interface ComplaintDataPlotValueDTO {
    /**
     * 
     * @type {string}
     * @memberof ComplaintDataPlotValueDTO
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof ComplaintDataPlotValueDTO
     */
    orderCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ComplaintDataPlotValueDTO
     */
    selfImposedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ComplaintDataPlotValueDTO
     */
    supplierImposedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ComplaintDataPlotValueDTO
     */
    clientImposedCount?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ComplaintImposed {
    Self = 'SELF',
    Supplier = 'SUPPLIER',
    Client = 'CLIENT'
}

/**
 * 
 * @export
 * @interface ComplaintOverviewDTO
 */
export interface ComplaintOverviewDTO {
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    id?: string;
    /**
     * 
     * @type {ComplaintState}
     * @memberof ComplaintOverviewDTO
     */
    state?: ComplaintState;
    /**
     * 
     * @type {boolean}
     * @memberof ComplaintOverviewDTO
     */
    doNotForget?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ComplaintOverviewDTO
     */
    importance?: number;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    salesmanName?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    availability?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    location?: string;
    /**
     * 
     * @type {Array<OrderConnection>}
     * @memberof ComplaintOverviewDTO
     */
    connections?: Array<OrderConnection>;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    carpenterName?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof ComplaintOverviewDTO
     */
    repairTimeInMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    lastModifiedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplaintOverviewDTO
     */
    lastModifiedDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ComplaintOverviewDTO
     */
    lockedDoneState?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ComplaintState {
    New = 'NEW',
    InProgress = 'IN_PROGRESS',
    Assigned = 'ASSIGNED',
    Done = 'DONE'
}

/**
 * 
 * @export
 * @interface ConstraintDTO
 */
export interface ConstraintDTO {
    /**
     * 
     * @type {number}
     * @memberof ConstraintDTO
     */
    maximumLocationCount: number;
    /**
     * 
     * @type {number}
     * @memberof ConstraintDTO
     */
    maximumUserCount: number;
    /**
     * 
     * @type {number}
     * @memberof ConstraintDTO
     */
    maximumCarpenterAppUserCount: number;
    /**
     * 
     * @type {Array<AdditionalPackageType>}
     * @memberof ConstraintDTO
     */
    additionalPackageTypes: Array<AdditionalPackageType>;
}
/**
 * 
 * @export
 * @interface CurrentUserDTO
 */
export interface CurrentUserDTO {
    /**
     * 
     * @type {UserDTO}
     * @memberof CurrentUserDTO
     */
    user: UserDTO;
    /**
     * 
     * @type {Array<UserRight>}
     * @memberof CurrentUserDTO
     */
    userRights: Array<UserRight>;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDTO
     */
    userGroupName: string;
}
/**
 * 
 * @export
 * @interface DashboardPlotDataDTO
 */
export interface DashboardPlotDataDTO {
    /**
     * 
     * @type {ChartAreaValuesGrouping}
     * @memberof DashboardPlotDataDTO
     */
    areaValuesGrouping?: ChartAreaValuesGrouping;
    /**
     * 
     * @type {Array<DeliverySellDataPlotValueDTO>}
     * @memberof DashboardPlotDataDTO
     */
    deliveryPlotData?: Array<DeliverySellDataPlotValueDTO>;
    /**
     * 
     * @type {Array<DeliverySellDataPlotValueDTO>}
     * @memberof DashboardPlotDataDTO
     */
    sellPlotData?: Array<DeliverySellDataPlotValueDTO>;
    /**
     * 
     * @type {ChartBarValuesGrouping}
     * @memberof DashboardPlotDataDTO
     */
    barValuesGrouping?: ChartBarValuesGrouping;
    /**
     * 
     * @type {Array<ComplaintDataPlotValueDTO>}
     * @memberof DashboardPlotDataDTO
     */
    complaintPlotData?: Array<ComplaintDataPlotValueDTO>;
    /**
     * 
     * @type {Array<MarginDataPlotValueDTO>}
     * @memberof DashboardPlotDataDTO
     */
    marginPlotData?: Array<MarginDataPlotValueDTO>;
    /**
     * 
     * @type {Array<DeliverySellDataPlotValueDTO>}
     * @memberof DashboardPlotDataDTO
     */
    compareDeliveryPlotData?: Array<DeliverySellDataPlotValueDTO>;
    /**
     * 
     * @type {Array<DeliverySellDataPlotValueDTO>}
     * @memberof DashboardPlotDataDTO
     */
    compareSellPlotData?: Array<DeliverySellDataPlotValueDTO>;
}
/**
 * 
 * @export
 * @interface DefaultValuesDTO
 */
export interface DefaultValuesDTO {
    /**
     * 
     * @type {ProvisionDTO}
     * @memberof DefaultValuesDTO
     */
    provision: ProvisionDTO;
    /**
     * 
     * @type {number}
     * @memberof DefaultValuesDTO
     */
    shipmentExpense: number;
    /**
     * 
     * @type {number}
     * @memberof DefaultValuesDTO
     */
    assemblyExpense: number;
    /**
     * 
     * @type {TargetCompareValueDTO}
     * @memberof DefaultValuesDTO
     */
    targetMargin: TargetCompareValueDTO;
    /**
     * 
     * @type {TargetCompareValueDTO}
     * @memberof DefaultValuesDTO
     */
    targetMarginSum: TargetCompareValueDTO;
}
/**
 * 
 * @export
 * @interface DeliverySellDataPlotValueDTO
 */
export interface DeliverySellDataPlotValueDTO {
    /**
     * 
     * @type {string}
     * @memberof DeliverySellDataPlotValueDTO
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliverySellDataPlotValueDTO
     */
    sellingSum?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliverySellDataPlotValueDTO
     */
    orderCount?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DiscountSelection {
    Percentage = 'PERCENTAGE',
    Amount = 'AMOUNT',
    TotalPrice = 'TOTAL_PRICE'
}

/**
 * 
 * @export
 * @interface DocumentData
 */
export interface DocumentData {
    /**
     * 
     * @type {string}
     * @memberof DocumentData
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentData
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentData
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentData
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentData
     */
    documentNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentData
     */
    hasCustomClientData?: boolean;
    /**
     * 
     * @type {ClientData}
     * @memberof DocumentData
     */
    clientData?: ClientData;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentData
     */
    enterNetPrices?: boolean;
    /**
     * 
     * @type {Array<ProductGroup>}
     * @memberof DocumentData
     */
    productGroups?: Array<ProductGroup>;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentData
     */
    hasDiscountData?: boolean;
    /**
     * 
     * @type {DiscountSelection}
     * @memberof DocumentData
     */
    discountSelection?: DiscountSelection;
    /**
     * 
     * @type {number}
     * @memberof DocumentData
     */
    discountPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentData
     */
    discountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentData
     */
    discountTotalPrice?: number;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof DocumentData
     */
    payments?: Array<Payment>;
    /**
     * 
     * @type {string}
     * @memberof DocumentData
     */
    noteRemainingPayment?: string;
    /**
     * 
     * @type {DocumentDataSettingBaseValues}
     * @memberof DocumentData
     */
    settings?: DocumentDataSettingBaseValues;
}
/**
 * 
 * @export
 * @interface DocumentDataDTO
 */
export interface DocumentDataDTO {
    /**
     * 
     * @type {string}
     * @memberof DocumentDataDTO
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataDTO
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataDTO
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataDTO
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataDTO
     */
    documentNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataDTO
     */
    hasCustomClientData?: boolean;
    /**
     * 
     * @type {ClientData}
     * @memberof DocumentDataDTO
     */
    clientData?: ClientData;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataDTO
     */
    enterNetPrices?: boolean;
    /**
     * 
     * @type {Array<ProductGroup>}
     * @memberof DocumentDataDTO
     */
    productGroups?: Array<ProductGroup>;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataDTO
     */
    hasDiscountData?: boolean;
    /**
     * 
     * @type {DiscountSelection}
     * @memberof DocumentDataDTO
     */
    discountSelection?: DiscountSelection;
    /**
     * 
     * @type {number}
     * @memberof DocumentDataDTO
     */
    discountPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentDataDTO
     */
    discountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentDataDTO
     */
    discountTotalPrice?: number;
    /**
     * 
     * @type {Array<PaymentDTO>}
     * @memberof DocumentDataDTO
     */
    payments: Array<PaymentDTO>;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataDTO
     */
    noteRemainingPayment?: string;
    /**
     * 
     * @type {DocumentDataSettingDTO}
     * @memberof DocumentDataDTO
     */
    settings: DocumentDataSettingDTO;
}
/**
 * 
 * @export
 * @interface DocumentDataHistory
 */
export interface DocumentDataHistory {
    /**
     * 
     * @type {string}
     * @memberof DocumentDataHistory
     */
    id?: string;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentDataHistory
     */
    type?: DocumentType;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataHistory
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataHistory
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataHistory
     */
    mailRecipient?: string;
    /**
     * 
     * @type {DocumentData}
     * @memberof DocumentDataHistory
     */
    documentData?: DocumentData;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataHistory
     */
    showInFiles?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentDataHistoryDTO
 */
export interface DocumentDataHistoryDTO {
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentDataHistoryDTO
     */
    type: DocumentType;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataHistoryDTO
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataHistoryDTO
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataHistoryDTO
     */
    mailRecipient?: string;
    /**
     * 
     * @type {DocumentDataDTO}
     * @memberof DocumentDataHistoryDTO
     */
    documentData?: DocumentDataDTO;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataHistoryDTO
     */
    showInFiles?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentDataSettingBaseValues
 */
export interface DocumentDataSettingBaseValues {
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingBaseValues
     */
    hideArticlePrices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingBaseValues
     */
    showSubtotalPerSupplier?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingBaseValues
     */
    showDiscounts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingBaseValues
     */
    customPreText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataSettingBaseValues
     */
    preText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingBaseValues
     */
    customPostText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataSettingBaseValues
     */
    postText?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataSettingBaseValues
     */
    emailSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataSettingBaseValues
     */
    emailBody?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingBaseValues
     */
    useTotalAmountForOrder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingBaseValues
     */
    usePaymentsForOrder?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentDataSettingDTO
 */
export interface DocumentDataSettingDTO {
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingDTO
     */
    hideArticlePrices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingDTO
     */
    showSubtotalPerSupplier?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingDTO
     */
    showDiscounts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingDTO
     */
    customPreText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataSettingDTO
     */
    preText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingDTO
     */
    customPostText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataSettingDTO
     */
    postText?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataSettingDTO
     */
    emailSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataSettingDTO
     */
    emailBody?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingDTO
     */
    useTotalAmountForOrder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataSettingDTO
     */
    usePaymentsForOrder?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentType {
    PrepaymentInvoice = 'PREPAYMENT_INVOICE',
    Offer = 'OFFER',
    PurchaseContract = 'PURCHASE_CONTRACT',
    Invoice = 'INVOICE',
    SupplierConfirmation = 'SUPPLIER_CONFIRMATION'
}

/**
 * 
 * @export
 * @interface ExhibitionProvisionDTO
 */
export interface ExhibitionProvisionDTO {
    /**
     * 
     * @type {number}
     * @memberof ExhibitionProvisionDTO
     */
    salesPercentage: number;
    /**
     * 
     * @type {SalesProvisionValue}
     * @memberof ExhibitionProvisionDTO
     */
    salesValue: SalesProvisionValue;
    /**
     * 
     * @type {number}
     * @memberof ExhibitionProvisionDTO
     */
    exhibitionPercentage: number;
    /**
     * 
     * @type {ExhibitionProvisionValue}
     * @memberof ExhibitionProvisionDTO
     */
    exhibitionValue: ExhibitionProvisionValue;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ExhibitionProvisionDTO
     */
    valuesByAttributes?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ExhibitionProvisionValue {
    PriceGross = 'EXHIBITION_PRICE_GROSS',
    Price = 'EXHIBITION_PRICE'
}

/**
 * 
 * @export
 * @interface ExhibitionSalesmanDTO
 */
export interface ExhibitionSalesmanDTO {
    /**
     * 
     * @type {string}
     * @memberof ExhibitionSalesmanDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionSalesmanDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionSalesmanDTO
     */
    locationId?: string;
}
/**
 * 
 * @export
 * @interface ExhibitionSalesmanGetDTO
 */
export interface ExhibitionSalesmanGetDTO {
    /**
     * 
     * @type {string}
     * @memberof ExhibitionSalesmanGetDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionSalesmanGetDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitionSalesmanGetDTO
     */
    locationId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExhibitionSalesmanGetDTO
     */
    isDeletable?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ExportFileType {
    Pdf = 'PDF',
    Doc = 'DOC',
    Xls = 'XLS'
}

/**
 * 
 * @export
 * @interface FileDTO
 */
export interface FileDTO {
    /**
     * 
     * @type {string}
     * @memberof FileDTO
     */
    id?: string;
    /**
     * 
     * @type {FileType}
     * @memberof FileDTO
     */
    type?: FileType;
    /**
     * 
     * @type {string}
     * @memberof FileDTO
     */
    additionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDTO
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface FileRedirectDTO
 */
export interface FileRedirectDTO {
    /**
     * 
     * @type {string}
     * @memberof FileRedirectDTO
     */
    url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileRedirectDTO
     */
    isRelativeUrl?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FileRedirectDTO
     */
    filename?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FileType {
    Contract = 'CONTRACT',
    ContractPurchase = 'CONTRACT_PURCHASE',
    ContractExhibitionPurchase = 'CONTRACT_EXHIBITION_PURCHASE',
    ContractInvoice = 'CONTRACT_INVOICE',
    ContractChange = 'CONTRACT_CHANGE',
    Plans = 'PLANS',
    PlanInstallation = 'PLAN_INSTALLATION',
    PlanSigned = 'PLAN_SIGNED',
    PlanMeasurement = 'PLAN_MEASUREMENT',
    SupplierConfirmation = 'SUPPLIER_CONFIRMATION',
    SupplierOrder = 'SUPPLIER_ORDER',
    MiscDeliveryNote = 'MISC_DELIVERY_NOTE',
    MiscAssemblyOrder = 'MISC_ASSEMBLY_ORDER',
    MiscAssemblyReport = 'MISC_ASSEMBLY_REPORT',
    Misc = 'MISC',
    Complaint = 'COMPLAINT',
    ComplaintReport = 'COMPLAINT_REPORT',
    TemplateDocs = 'TEMPLATE_DOCS',
    DeliveryNote = 'DELIVERY_NOTE',
    DeliveryNoteManufacturer = 'DELIVERY_NOTE_MANUFACTURER'
}

/**
 * 
 * @export
 * @interface FullPaymentPutDTO
 */
export interface FullPaymentPutDTO {
    /**
     * 
     * @type {string}
     * @memberof FullPaymentPutDTO
     */
    date?: string;
    /**
     * 
     * @type {OrderPaymentType}
     * @memberof FullPaymentPutDTO
     */
    paymentType?: OrderPaymentType;
    /**
     * 
     * @type {number}
     * @memberof FullPaymentPutDTO
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface FuturePaymentDTO
 */
export interface FuturePaymentDTO {
    /**
     * 
     * @type {string}
     * @memberof FuturePaymentDTO
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof FuturePaymentDTO
     */
    deliveryDate: string;
    /**
     * 
     * @type {string}
     * @memberof FuturePaymentDTO
     */
    clientName: string;
    /**
     * 
     * @type {string}
     * @memberof FuturePaymentDTO
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof FuturePaymentDTO
     */
    salesmanName: string;
    /**
     * 
     * @type {number}
     * @memberof FuturePaymentDTO
     */
    remainingAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof FuturePaymentDTO
     */
    paidAt?: string;
    /**
     * 
     * @type {number}
     * @memberof FuturePaymentDTO
     */
    paidAmount?: number;
    /**
     * 
     * @type {OrderPaymentType}
     * @memberof FuturePaymentDTO
     */
    paymentType?: OrderPaymentType;
    /**
     * 
     * @type {ComplaintState}
     * @memberof FuturePaymentDTO
     */
    complaintState?: ComplaintState;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum GlobalValueKey {
    LastOrderLockDate = 'LAST_ORDER_LOCK_DATE'
}

/**
 * 
 * @export
 * @interface LeadSourceDTO
 */
export interface LeadSourceDTO {
    /**
     * 
     * @type {string}
     * @memberof LeadSourceDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceDTO
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface LocationDTO
 */
export interface LocationDTO {
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocationDTO
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    billPrefix?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationDTO
     */
    vat: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationDTO
     */
    marginDocsTop?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationDTO
     */
    marginDocsBottom?: number;
}
/**
 * 
 * @export
 * @interface MarginDataPlotValueDTO
 */
export interface MarginDataPlotValueDTO {
    /**
     * 
     * @type {string}
     * @memberof MarginDataPlotValueDTO
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof MarginDataPlotValueDTO
     */
    sellingSum?: number;
    /**
     * 
     * @type {number}
     * @memberof MarginDataPlotValueDTO
     */
    margin?: number;
    /**
     * 
     * @type {number}
     * @memberof MarginDataPlotValueDTO
     */
    marginSum?: number;
}
/**
 * 
 * @export
 * @interface OpenPaymentDTO
 */
export interface OpenPaymentDTO {
    /**
     * 
     * @type {string}
     * @memberof OpenPaymentDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenPaymentDTO
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenPaymentDTO
     */
    clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenPaymentDTO
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenPaymentDTO
     */
    salesmanName?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenPaymentDTO
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof OpenPaymentDTO
     */
    price?: number;
    /**
     * 
     * @type {OrderPaymentType}
     * @memberof OpenPaymentDTO
     */
    paymentType?: OrderPaymentType;
    /**
     * 
     * @type {string}
     * @memberof OpenPaymentDTO
     */
    location?: string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    groupName?: string;
    /**
     * 
     * @type {OrderType}
     * @memberof Order
     */
    type?: OrderType;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    lastModifiedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    lastModifiedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    exhibitionSalesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    exhibitionScheduleDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    carpenterId?: string;
    /**
     * 
     * @type {Array<SupplierCalculation>}
     * @memberof Order
     */
    suppliers?: Array<SupplierCalculation>;
    /**
     * 
     * @type {Array<Complaint>}
     * @memberof Order
     */
    complaints?: Array<Complaint>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    sellDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    exhibitionSellDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    deliveryDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    deliveryDateNotDefined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    assemblyDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    secondAssemblyDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    location?: string;
    /**
     * 
     * @type {Array<OrderConnection>}
     * @memberof Order
     */
    connections?: Array<OrderConnection>;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    exhibitionSale?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    exhibitionPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    orderPrice?: number;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof Order
     */
    changes?: Array<Payment>;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof Order
     */
    payments?: Array<Payment>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    noteRemainingPayment?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    assemblyExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    shipmentExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    salesmanProvision?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    exhibitionProvision?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    calculatedSalesmanProvision?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    calculatedExhibitionProvision?: number;
    /**
     * 
     * @type {Array<AdditionalExpense>}
     * @memberof Order
     */
    additionalExpenses?: Array<AdditionalExpense>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    notes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    doneToDoRemainingAmount?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    doneToDoRemainingAmountDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    cancelled?: boolean;
    /**
     * 
     * @type {{ [key: string]: DocumentData; }}
     * @memberof Order
     */
    documentData?: { [key: string]: DocumentData; };
    /**
     * 
     * @type {Array<DocumentDataHistory>}
     * @memberof Order
     */
    documentDataHistory?: Array<DocumentDataHistory>;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    deliverySum?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    sellingSum?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    margin?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    marginSum?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    percentageAssembly?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    remainingAmount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Order
     */
    missingFileTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Order
     */
    hiddenFileTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    billDateToCompare?: string;
    /**
     * 
     * @type {ComplaintState}
     * @memberof Order
     */
    complaintState?: ComplaintState;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    finalPrice?: number;
}
/**
 * 
 * @export
 * @interface OrderAppOverviewDTO
 */
export interface OrderAppOverviewDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderAppOverviewDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAppOverviewDTO
     */
    salesmanName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAppOverviewDTO
     */
    deliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAppOverviewDTO
     */
    assemblyDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAppOverviewDTO
     */
    secondAssemblyDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAppOverviewDTO
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAppOverviewDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAppOverviewDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAppOverviewDTO
     */
    clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAppOverviewDTO
     */
    clientPhone1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAppOverviewDTO
     */
    clientPhone2?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAppOverviewDTO
     */
    fileExists?: boolean;
    /**
     * 
     * @type {Array<FileType>}
     * @memberof OrderAppOverviewDTO
     */
    fileTypesToDownload: Array<FileType>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderBillType {
    Order = 'ORDER',
    OrderPrePayment = 'ORDER_PRE_PAYMENT',
    SmallOrder = 'SMALL_ORDER'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderConnection {
    Water = 'WATER',
    Electric = 'ELECTRIC'
}

/**
 * 
 * @export
 * @interface OrderDTO
 */
export interface OrderDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    id?: string;
    /**
     * 
     * @type {OrderType}
     * @memberof OrderDTO
     */
    type: OrderType;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    lastModifiedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    lastModifiedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    exhibitionSalesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    exhibitionScheduleDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    orderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    sellDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    exhibitionSellDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    clientId?: string;
    /**
     * 
     * @type {ClientTargetGroup}
     * @memberof OrderDTO
     */
    targetGroup?: ClientTargetGroup;
    /**
     * 
     * @type {ClientSalutation}
     * @memberof OrderDTO
     */
    salutation?: ClientSalutation;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    vat?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    phone1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    phone2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    externalCustomerId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    leadSourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    deliveryDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDTO
     */
    deliveryDateNotDefined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    assemblyDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    secondAssemblyDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    carpenterId?: string;
    /**
     * 
     * @type {Array<OrderConnection>}
     * @memberof OrderDTO
     */
    connections?: Array<OrderConnection>;
    /**
     * 
     * @type {Array<SupplierCalculationDTO>}
     * @memberof OrderDTO
     */
    suppliers: Array<SupplierCalculationDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDTO
     */
    exhibitionSale?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderDTO
     */
    exhibitionPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDTO
     */
    orderPrice?: number;
    /**
     * 
     * @type {Array<PaymentDTO>}
     * @memberof OrderDTO
     */
    changes?: Array<PaymentDTO>;
    /**
     * 
     * @type {Array<PaymentDTO>}
     * @memberof OrderDTO
     */
    payments: Array<PaymentDTO>;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    noteRemainingPayment?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDTO
     */
    assemblyExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDTO
     */
    shipmentExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDTO
     */
    salesmanProvision?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDTO
     */
    exhibitionProvision?: number;
    /**
     * 
     * @type {Array<AdditionalExpenseDTO>}
     * @memberof OrderDTO
     */
    additionalExpenses?: Array<AdditionalExpenseDTO>;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    notes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDTO
     */
    cancelled?: boolean;
    /**
     * 
     * @type {Array<ComplaintDTO>}
     * @memberof OrderDTO
     */
    complaints?: Array<ComplaintDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDTO
     */
    missingFileTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDTO
     */
    hiddenFileTypes?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: DocumentDataDTO; }}
     * @memberof OrderDTO
     */
    documentData: { [key: string]: DocumentDataDTO; };
    /**
     * 
     * @type {Array<DocumentDataHistoryDTO>}
     * @memberof OrderDTO
     */
    documentDataHistory: Array<DocumentDataHistoryDTO>;
}
/**
 * 
 * @export
 * @interface OrderFilePostDTO
 */
export interface OrderFilePostDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderFilePostDTO
     */
    title?: string;
    /**
     * 
     * @type {Array<OrderOverviewDTO>}
     * @memberof OrderFilePostDTO
     */
    orders?: Array<OrderOverviewDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderFilePostDTO
     */
    selectedFields?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderFilePostDTO
     */
    headlineNames?: Array<string>;
}
/**
 * 
 * @export
 * @interface OrderOverviewDTO
 */
export interface OrderOverviewDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    id?: string;
    /**
     * 
     * @type {OrderType}
     * @memberof OrderOverviewDTO
     */
    type: OrderType;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    lastModifiedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    lastModifiedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    orderNumber?: string;
    /**
     * 
     * @type {OrderState}
     * @memberof OrderOverviewDTO
     */
    orderState: OrderState;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    salesmanName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    exhibitionSalesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    exhibitionSalesmanName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    locationName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    sellDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    deliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    assemblyDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    secondAssemblyDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    exhibitionSellDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    exhibitionScheduleDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderOverviewDTO
     */
    exhibitionSale?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    city?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    sellingSumGross?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    sellingSum?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    deliverySum?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    exhibitionPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    margin?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    marginSum?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    percentageAssembly?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    remainingAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    unpaidAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    assemblyExpense?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    carpenterName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderOverviewDTO
     */
    missingDataForMargin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderOverviewDTO
     */
    missingDataForMarginSum?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderOverviewDTO
     */
    toDoEnterOrderData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderOverviewDTO
     */
    toDoRemainingAmount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderOverviewDTO
     */
    doneToDoRemainingAmount?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    doneToDoRemainingAmountDate?: string;
    /**
     * 
     * @type {Array<PaymentDTO>}
     * @memberof OrderOverviewDTO
     */
    payments?: Array<PaymentDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderOverviewDTO
     */
    missingFileTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderOverviewDTO
     */
    missingSupplierData?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderOverviewDTO
     */
    paid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderOverviewDTO
     */
    cancelled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    calculatedSalesmanProvision?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    calculatedExhibitionProvision?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    expenses?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    clientEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    clientPhone1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    clientPhone2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOverviewDTO
     */
    notes?: string;
    /**
     * 
     * @type {Array<SupplierMappingDTO>}
     * @memberof OrderOverviewDTO
     */
    supplierMapping?: Array<SupplierMappingDTO>;
    /**
     * 
     * @type {ComplaintState}
     * @memberof OrderOverviewDTO
     */
    complaintState?: ComplaintState;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    differenceTargetMargin?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    differenceTargetMarginPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    targetMargin?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    differenceTargetMarginSum?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    differenceTargetMarginSumPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOverviewDTO
     */
    targetMarginSum?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderPaymentType {
    Cash = 'CASH',
    Bank = 'BANK'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderState {
    Unassigned = 'UNASSIGNED',
    InProgress = 'IN_PROGRESS',
    Sold = 'SOLD',
    InDelivery = 'IN_DELIVERY',
    Delivered = 'DELIVERED',
    Complaint = 'COMPLAINT'
}

/**
 * 
 * @export
 * @interface OrderTemplateDTO
 */
export interface OrderTemplateDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderTemplateDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTemplateDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTemplateDTO
     */
    selectedFields: Array<string>;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof OrderTemplateDTO
     */
    tabs: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface OrderTemplateSelectDTO
 */
export interface OrderTemplateSelectDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderTemplateSelectDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTemplateSelectDTO
     */
    tab?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderType {
    Normal = 'NORMAL',
    Small = 'SMALL'
}

/**
 * 
 * @export
 * @interface OrdersGetDTO
 */
export interface OrdersGetDTO {
    /**
     * 
     * @type {Array<OrderOverviewDTO>}
     * @memberof OrdersGetDTO
     */
    orders?: Array<OrderOverviewDTO>;
    /**
     * 
     * @type {Array<OrderOverviewDTO>}
     * @memberof OrdersGetDTO
     */
    exhibitionOrders?: Array<OrderOverviewDTO>;
}
/**
 * 
 * @export
 * @interface PackageOverviewDTO
 */
export interface PackageOverviewDTO {
    /**
     * 
     * @type {Array<BasePackageDTO>}
     * @memberof PackageOverviewDTO
     */
    basePackages?: Array<BasePackageDTO>;
    /**
     * 
     * @type {Array<AdditionalPackageDTO>}
     * @memberof PackageOverviewDTO
     */
    additionalPackages?: Array<AdditionalPackageDTO>;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    notes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Payment
     */
    paid?: boolean;
    /**
     * 
     * @type {OrderPaymentType}
     * @memberof Payment
     */
    paymentType?: OrderPaymentType;
    /**
     * 
     * @type {DocumentData}
     * @memberof Payment
     */
    documentData?: DocumentData;
}
/**
 * 
 * @export
 * @interface PaymentDTO
 */
export interface PaymentDTO {
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDTO
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    notes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentDTO
     */
    paid?: boolean;
    /**
     * 
     * @type {OrderPaymentType}
     * @memberof PaymentDTO
     */
    paymentType: OrderPaymentType;
    /**
     * 
     * @type {DocumentDataDTO}
     * @memberof PaymentDTO
     */
    documentData?: DocumentDataDTO;
}
/**
 * 
 * @export
 * @interface ProductGroup
 */
export interface ProductGroup {
    /**
     * 
     * @type {string}
     * @memberof ProductGroup
     */
    supplierId?: string;
    /**
     * 
     * @type {Array<ProductLine>}
     * @memberof ProductGroup
     */
    products?: Array<ProductLine>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductGroup
     */
    hasDiscountData?: boolean;
    /**
     * 
     * @type {DiscountSelection}
     * @memberof ProductGroup
     */
    discountSelection?: DiscountSelection;
    /**
     * 
     * @type {number}
     * @memberof ProductGroup
     */
    discountPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductGroup
     */
    discountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductGroup
     */
    discountTotalPrice?: number;
}
/**
 * 
 * @export
 * @interface ProductLine
 */
export interface ProductLine {
    /**
     * 
     * @type {number}
     * @memberof ProductLine
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductLine
     */
    pricePerUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductLine
     */
    articleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLine
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface ProvisionDTO
 */
export interface ProvisionDTO {
    /**
     * 
     * @type {SalesProvisionDTO}
     * @memberof ProvisionDTO
     */
    salesProvision: SalesProvisionDTO;
    /**
     * 
     * @type {ExhibitionProvisionDTO}
     * @memberof ProvisionDTO
     */
    exhibitionProvision: ExhibitionProvisionDTO;
}
/**
 * 
 * @export
 * @interface SalesProvisionDTO
 */
export interface SalesProvisionDTO {
    /**
     * 
     * @type {number}
     * @memberof SalesProvisionDTO
     */
    percentage: number;
    /**
     * 
     * @type {SalesProvisionValue}
     * @memberof SalesProvisionDTO
     */
    value: SalesProvisionValue;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof SalesProvisionDTO
     */
    valuesByAttributes?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SalesProvisionValue {
    SellingSumGross = 'SELLING_SUM_GROSS',
    SellingSum = 'SELLING_SUM',
    Margin = 'MARGIN'
}

/**
 * 
 * @export
 * @interface SalesmanSaleDTO
 */
export interface SalesmanSaleDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesmanSaleDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesmanSaleDTO
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesmanSaleDTO
     */
    salesmanName?: string;
    /**
     * 
     * @type {number}
     * @memberof SalesmanSaleDTO
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesmanSaleDTO
     */
    orderCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesmanSaleDTO
     */
    exhibitionCount?: number;
}
/**
 * 
 * @export
 * @interface SetPasswordPostDTO
 */
export interface SetPasswordPostDTO {
    /**
     * 
     * @type {string}
     * @memberof SetPasswordPostDTO
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPasswordPostDTO
     */
    resetToken?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPasswordPostDTO
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface SmallOrderDTO
 */
export interface SmallOrderDTO {
    /**
     * 
     * @type {string}
     * @memberof SmallOrderDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SmallOrderDTO
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmallOrderDTO
     */
    salesmanId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmallOrderDTO
     */
    clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof SmallOrderDTO
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SmallOrderDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof SmallOrderDTO
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof SmallOrderDTO
     */
    carpenterId?: string;
    /**
     * 
     * @type {Array<PaymentDTO>}
     * @memberof SmallOrderDTO
     */
    payments?: Array<PaymentDTO>;
    /**
     * 
     * @type {number}
     * @memberof SmallOrderDTO
     */
    finalPrice?: number;
}
/**
 * 
 * @export
 * @interface SupplierCalculation
 */
export interface SupplierCalculation {
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculation
     */
    supplierId?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculation
     */
    orderNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof SupplierCalculation
     */
    orderSum?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculation
     */
    accountingNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof SupplierCalculation
     */
    accountingSum?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculation
     */
    confirmedDeliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculation
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculation
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculation
     */
    deliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculation
     */
    deliveryNoteNumber?: string;
}
/**
 * 
 * @export
 * @interface SupplierCalculationDTO
 */
export interface SupplierCalculationDTO {
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculationDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculationDTO
     */
    supplierId?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculationDTO
     */
    orderNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof SupplierCalculationDTO
     */
    orderSum?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculationDTO
     */
    accountingNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof SupplierCalculationDTO
     */
    accountingSum?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculationDTO
     */
    confirmedDeliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculationDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculationDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculationDTO
     */
    deliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCalculationDTO
     */
    deliveryNoteNumber?: string;
}
/**
 * 
 * @export
 * @interface SupplierDTO
 */
export interface SupplierDTO {
    /**
     * 
     * @type {string}
     * @memberof SupplierDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDTO
     */
    name: string;
}
/**
 * 
 * @export
 * @interface SupplierGetDTO
 */
export interface SupplierGetDTO {
    /**
     * 
     * @type {string}
     * @memberof SupplierGetDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierGetDTO
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierGetDTO
     */
    isDeletable?: boolean;
}
/**
 * 
 * @export
 * @interface SupplierMappingDTO
 */
export interface SupplierMappingDTO {
    /**
     * 
     * @type {string}
     * @memberof SupplierMappingDTO
     */
    supplierCalculationId?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierMappingDTO
     */
    supplierId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TargetCompareValue {
    SumGross = 'SELLING_SUM_GROSS',
    Sum = 'SELLING_SUM'
}

/**
 * 
 * @export
 * @interface TargetCompareValueDTO
 */
export interface TargetCompareValueDTO {
    /**
     * 
     * @type {number}
     * @memberof TargetCompareValueDTO
     */
    percentage: number;
    /**
     * 
     * @type {TargetCompareValue}
     * @memberof TargetCompareValueDTO
     */
    compareValue: TargetCompareValue;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TenantAccountingPeriod {
    Monthly = 'MONTHLY',
    Yearly = 'YEARLY'
}

/**
 * 
 * @export
 * @interface TenantDTO
 */
export interface TenantDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    identifier?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDTO
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    vat: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    emailInvoice: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    lastInvoiceDate: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    licenseValidUntil: string;
    /**
     * 
     * @type {TenantAccountingPeriod}
     * @memberof TenantDTO
     */
    accountingPeriod: TenantAccountingPeriod;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    additionalUserCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    additionalLocationCount: number;
    /**
     * 
     * @type {BasePackageType}
     * @memberof TenantDTO
     */
    basePackageType: BasePackageType;
    /**
     * 
     * @type {Array<AdditionalPackageType>}
     * @memberof TenantDTO
     */
    additionalPackageTypes: Array<AdditionalPackageType>;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    additionalCarpenterAppUserCount?: number;
}
/**
 * 
 * @export
 * @interface TokenDTO
 */
export interface TokenDTO {
    /**
     * 
     * @type {string}
     * @memberof TokenDTO
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenDTO
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenDTO
     */
    refresh_token?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenDTO
     */
    access_token?: string;
}
/**
 * 
 * @export
 * @interface TokenPostDTO
 */
export interface TokenPostDTO {
    /**
     * 
     * @type {string}
     * @memberof TokenPostDTO
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenPostDTO
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    location?: string;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof UserDTO
     */
    roles?: Array<UserRole>;
    /**
     * 
     * @type {Array<UserRight>}
     * @memberof UserDTO
     */
    userRights?: Array<UserRight>;
}
/**
 * 
 * @export
 * @interface UserGetDTO
 */
export interface UserGetDTO {
    /**
     * 
     * @type {string}
     * @memberof UserGetDTO
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserGetDTO
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserGetDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGetDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGetDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGetDTO
     */
    location?: string;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof UserGetDTO
     */
    roles?: Array<UserRole>;
    /**
     * 
     * @type {Array<UserRight>}
     * @memberof UserGetDTO
     */
    userRights?: Array<UserRight>;
    /**
     * 
     * @type {boolean}
     * @memberof UserGetDTO
     */
    isDeletable?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserRight {
    OwnOrderRead = 'OWN_ORDER_READ',
    OwnOrderWrite = 'OWN_ORDER_WRITE',
    OwnOrderDelete = 'OWN_ORDER_DELETE',
    OwnOrderCalculationRead = 'OWN_ORDER_CALCULATION_READ',
    OwnOrderSupplierDataRead = 'OWN_ORDER_SUPPLIER_DATA_READ',
    OwnOrderSupplierDataWrite = 'OWN_ORDER_SUPPLIER_DATA_WRITE',
    OwnOrderMarginRead = 'OWN_ORDER_MARGIN_READ',
    OwnOrderExpensesRead = 'OWN_ORDER_EXPENSES_READ',
    OwnOrderExpensesWrite = 'OWN_ORDER_EXPENSES_WRITE',
    OwnOrderMarginSumRead = 'OWN_ORDER_MARGIN_SUM_READ',
    OwnOrderTargetMarginRead = 'OWN_ORDER_TARGET_MARGIN_READ',
    OwnOrderPriceAndPaymentRead = 'OWN_ORDER_PRICE_AND_PAYMENT_READ',
    OwnOrderExhibitionPriceRead = 'OWN_ORDER_EXHIBITION_PRICE_READ',
    OwnOrderExhibitionPriceWrite = 'OWN_ORDER_EXHIBITION_PRICE_WRITE',
    OwnOrderOrderPriceRead = 'OWN_ORDER_ORDER_PRICE_READ',
    OwnOrderOrderPriceWrite = 'OWN_ORDER_ORDER_PRICE_WRITE',
    OwnOrderChangesRead = 'OWN_ORDER_CHANGES_READ',
    OwnOrderChangesWrite = 'OWN_ORDER_CHANGES_WRITE',
    OwnOrderPrePaymentsRead = 'OWN_ORDER_PRE_PAYMENTS_READ',
    OwnOrderPrePaymentsWrite = 'OWN_ORDER_PRE_PAYMENTS_WRITE',
    OwnOrderRemainingAmountRead = 'OWN_ORDER_REMAINING_AMOUNT_READ',
    OtherOrderRead = 'OTHER_ORDER_READ',
    OtherOrderWrite = 'OTHER_ORDER_WRITE',
    OtherOrderDelete = 'OTHER_ORDER_DELETE',
    OtherOrderCalculationRead = 'OTHER_ORDER_CALCULATION_READ',
    OtherOrderSupplierDataRead = 'OTHER_ORDER_SUPPLIER_DATA_READ',
    OtherOrderSupplierDataWrite = 'OTHER_ORDER_SUPPLIER_DATA_WRITE',
    OtherOrderMarginRead = 'OTHER_ORDER_MARGIN_READ',
    OtherOrderExpensesRead = 'OTHER_ORDER_EXPENSES_READ',
    OtherOrderExpensesWrite = 'OTHER_ORDER_EXPENSES_WRITE',
    OtherOrderMarginSumRead = 'OTHER_ORDER_MARGIN_SUM_READ',
    OtherOrderTargetMarginRead = 'OTHER_ORDER_TARGET_MARGIN_READ',
    OtherOrderPriceAndPaymentRead = 'OTHER_ORDER_PRICE_AND_PAYMENT_READ',
    OtherOrderExhibitionPriceRead = 'OTHER_ORDER_EXHIBITION_PRICE_READ',
    OtherOrderExhibitionPriceWrite = 'OTHER_ORDER_EXHIBITION_PRICE_WRITE',
    OtherOrderOrderPriceRead = 'OTHER_ORDER_ORDER_PRICE_READ',
    OtherOrderOrderPriceWrite = 'OTHER_ORDER_ORDER_PRICE_WRITE',
    OtherOrderChangesRead = 'OTHER_ORDER_CHANGES_READ',
    OtherOrderChangesWrite = 'OTHER_ORDER_CHANGES_WRITE',
    OtherOrderPrePaymentsRead = 'OTHER_ORDER_PRE_PAYMENTS_READ',
    OtherOrderPrePaymentsWrite = 'OTHER_ORDER_PRE_PAYMENTS_WRITE',
    OtherOrderRemainingAmountRead = 'OTHER_ORDER_REMAINING_AMOUNT_READ',
    OwnOrderOverviewMarginRead = 'OWN_ORDER_OVERVIEW_MARGIN_READ',
    OwnOrderOverviewTargetMarginRead = 'OWN_ORDER_OVERVIEW_TARGET_MARGIN_READ',
    OwnOrderOverviewMarginSumRead = 'OWN_ORDER_OVERVIEW_MARGIN_SUM_READ',
    OwnOrderOverviewTargetMarginSumRead = 'OWN_ORDER_OVERVIEW_TARGET_MARGIN_SUM_READ',
    OwnOrderOverviewPrePaymentsRead = 'OWN_ORDER_OVERVIEW_PRE_PAYMENTS_READ',
    OwnOrderOverviewRemainingAmountRead = 'OWN_ORDER_OVERVIEW_REMAINING_AMOUNT_READ',
    OwnOrderOverviewSellingSumRead = 'OWN_ORDER_OVERVIEW_SELLING_SUM_READ',
    OwnOrderOverviewSellingSumGrossRead = 'OWN_ORDER_OVERVIEW_SELLING_SUM_GROSS_READ',
    OwnOrderOverviewDeliverySumRead = 'OWN_ORDER_OVERVIEW_DELIVERY_SUM_READ',
    OwnOrderOverviewExpensesRead = 'OWN_ORDER_OVERVIEW_EXPENSES_READ',
    OwnOrderOverviewSalesmanProvisionRead = 'OWN_ORDER_OVERVIEW_SALESMAN_PROVISION_READ',
    OwnOrderOverviewProvisionExhibitonRead = 'OWN_ORDER_OVERVIEW_PROVISION_EXHIBITON_READ',
    OwnOrderOverviewExhibitonPriceRead = 'OWN_ORDER_OVERVIEW_EXHIBITON_PRICE_READ',
    OtherOrderOverviewMarginRead = 'OTHER_ORDER_OVERVIEW_MARGIN_READ',
    OtherOrderOverviewTargetMarginRead = 'OTHER_ORDER_OVERVIEW_TARGET_MARGIN_READ',
    OtherOrderOverviewMarginSumRead = 'OTHER_ORDER_OVERVIEW_MARGIN_SUM_READ',
    OtherOrderOverviewTargetMarginSumRead = 'OTHER_ORDER_OVERVIEW_TARGET_MARGIN_SUM_READ',
    OtherOrderOverviewPrePaymentsRead = 'OTHER_ORDER_OVERVIEW_PRE_PAYMENTS_READ',
    OtherOrderOverviewRemainingAmountRead = 'OTHER_ORDER_OVERVIEW_REMAINING_AMOUNT_READ',
    OtherOrderOverviewSellingSumRead = 'OTHER_ORDER_OVERVIEW_SELLING_SUM_READ',
    OtherOrderOverviewSellingSumGrossRead = 'OTHER_ORDER_OVERVIEW_SELLING_SUM_GROSS_READ',
    OtherOrderOverviewDeliverySumRead = 'OTHER_ORDER_OVERVIEW_DELIVERY_SUM_READ',
    OtherOrderOverviewExpensesRead = 'OTHER_ORDER_OVERVIEW_EXPENSES_READ',
    OtherOrderOverviewSalesmanProvisionRead = 'OTHER_ORDER_OVERVIEW_SALESMAN_PROVISION_READ',
    OtherOrderOverviewProvisionExhibitonRead = 'OTHER_ORDER_OVERVIEW_PROVISION_EXHIBITON_READ',
    OtherOrderOverviewExhibitonPriceRead = 'OTHER_ORDER_OVERVIEW_EXHIBITON_PRICE_READ',
    OtherOrderChangeSalesman = 'OTHER_ORDER_CHANGE_SALESMAN',
    PrintOrderOverview = 'PRINT_ORDER_OVERVIEW',
    PrintOrderDetail = 'PRINT_ORDER_DETAIL',
    OwnComplaintRead = 'OWN_COMPLAINT_READ',
    OwnComplaintWrite = 'OWN_COMPLAINT_WRITE',
    OwnComplaintDelete = 'OWN_COMPLAINT_DELETE',
    OtherComplaintRead = 'OTHER_COMPLAINT_READ',
    OtherComplaintWrite = 'OTHER_COMPLAINT_WRITE',
    OtherComplaintDelete = 'OTHER_COMPLAINT_DELETE',
    SalesStatisticsRead = 'SALES_STATISTICS_READ',
    SalesStatisticsWrite = 'SALES_STATISTICS_WRITE',
    WeekOverviewRead = 'WEEK_OVERVIEW_READ',
    WeekOverviewWrite = 'WEEK_OVERVIEW_WRITE',
    BaseDataRead = 'BASE_DATA_READ',
    BaseDataWrite = 'BASE_DATA_WRITE',
    BaseDataDelete = 'BASE_DATA_DELETE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserRole {
    SuperAdmin = 'SUPER_ADMIN',
    Admin = 'ADMIN',
    Salesman = 'SALESMAN',
    Carpenter = 'CARPENTER'
}

/**
 * 
 * @export
 * @interface WeekOverviewDTO
 */
export interface WeekOverviewDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof WeekOverviewDTO
     */
    bankBalances?: Array<number>;
    /**
     * 
     * @type {Array<OpenPaymentDTO>}
     * @memberof WeekOverviewDTO
     */
    openPayments?: Array<OpenPaymentDTO>;
    /**
     * 
     * @type {Array<OpenPaymentDTO>}
     * @memberof WeekOverviewDTO
     */
    openExhibitionPayments?: Array<OpenPaymentDTO>;
    /**
     * 
     * @type {Array<FuturePaymentDTO>}
     * @memberof WeekOverviewDTO
     */
    futurePayments?: Array<FuturePaymentDTO>;
    /**
     * 
     * @type {Array<FuturePaymentDTO>}
     * @memberof WeekOverviewDTO
     */
    unpaidPastOrders?: Array<FuturePaymentDTO>;
    /**
     * 
     * @type {Array<SalesmanSaleDTO>}
     * @memberof WeekOverviewDTO
     */
    salesmanSales?: Array<SalesmanSaleDTO>;
    /**
     * 
     * @type {Array<SalesmanSaleDTO>}
     * @memberof WeekOverviewDTO
     */
    salesmanDeliveries?: Array<SalesmanSaleDTO>;
}

/**
 * AuthenticationControllerApi - axios parameter creator
 * @export
 */
export const AuthenticationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authentication/current-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefreshToken: async (body: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getRefreshToken', 'body', body)
            const localVarPath = `/api/authentication/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenPostDTO} tokenPostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (tokenPostDTO: TokenPostDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenPostDTO' is not null or undefined
            assertParamExists('getToken', 'tokenPostDTO', tokenPostDTO)
            const localVarPath = `/api/authentication/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenPostDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordMail: async (body: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sendResetPasswordMail', 'body', body)
            const localVarPath = `/api/authentication/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetPasswordPostDTO} setPasswordPostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword: async (setPasswordPostDTO: SetPasswordPostDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setPasswordPostDTO' is not null or undefined
            assertParamExists('setPassword', 'setPasswordPostDTO', setPasswordPostDTO)
            const localVarPath = `/api/authentication/set-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPasswordPostDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationControllerApi - functional programming interface
 * @export
 */
export const AuthenticationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRefreshToken(body: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRefreshToken(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TokenPostDTO} tokenPostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(tokenPostDTO: TokenPostDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(tokenPostDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendResetPasswordMail(body: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendResetPasswordMail(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetPasswordPostDTO} setPasswordPostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPassword(setPasswordPostDTO: SetPasswordPostDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPassword(setPasswordPostDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationControllerApi - factory interface
 * @export
 */
export const AuthenticationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<CurrentUserDTO> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefreshToken(body: string, options?: any): AxiosPromise<TokenDTO> {
            return localVarFp.getRefreshToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TokenPostDTO} tokenPostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken(tokenPostDTO: TokenPostDTO, options?: any): AxiosPromise<TokenDTO> {
            return localVarFp.getToken(tokenPostDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordMail(body: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendResetPasswordMail(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetPasswordPostDTO} setPasswordPostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword(setPasswordPostDTO: SetPasswordPostDTO, options?: any): AxiosPromise<void> {
            return localVarFp.setPassword(setPasswordPostDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationControllerApi - object-oriented interface
 * @export
 * @class AuthenticationControllerApi
 * @extends {BaseAPI}
 */
export class AuthenticationControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public getCurrentUser(options?: any) {
        return AuthenticationControllerApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public getRefreshToken(body: string, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).getRefreshToken(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TokenPostDTO} tokenPostDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public getToken(tokenPostDTO: TokenPostDTO, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).getToken(tokenPostDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public sendResetPasswordMail(body: string, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).sendResetPasswordMail(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetPasswordPostDTO} setPasswordPostDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public setPassword(setPasswordPostDTO: SetPasswordPostDTO, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).setPassword(setPasswordPostDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BaseDataControllerApi - axios parameter creator
 * @export
 */
export const BaseDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CarpenterDTO} carpenterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateCarpenter: async (carpenterDTO: CarpenterDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'carpenterDTO' is not null or undefined
            assertParamExists('createOrUpdateCarpenter', 'carpenterDTO', carpenterDTO)
            const localVarPath = `/api/base-data/carpenters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(carpenterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExhibitionSalesmanDTO} exhibitionSalesmanDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateExhibitionSalesman: async (exhibitionSalesmanDTO: ExhibitionSalesmanDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exhibitionSalesmanDTO' is not null or undefined
            assertParamExists('createOrUpdateExhibitionSalesman', 'exhibitionSalesmanDTO', exhibitionSalesmanDTO)
            const localVarPath = `/api/base-data/exhibition-salesman`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exhibitionSalesmanDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LeadSourceDTO} leadSourceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateLeadSource: async (leadSourceDTO: LeadSourceDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leadSourceDTO' is not null or undefined
            assertParamExists('createOrUpdateLeadSource', 'leadSourceDTO', leadSourceDTO)
            const localVarPath = `/api/base-data/lead-sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leadSourceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LocationDTO} locationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateLocation: async (locationDTO: LocationDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationDTO' is not null or undefined
            assertParamExists('createOrUpdateLocation', 'locationDTO', locationDTO)
            const localVarPath = `/api/base-data/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SupplierDTO} supplierDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSupplier: async (supplierDTO: SupplierDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'supplierDTO' is not null or undefined
            assertParamExists('createOrUpdateSupplier', 'supplierDTO', supplierDTO)
            const localVarPath = `/api/base-data/suppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supplierDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCarpenter: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCarpenter', 'id', id)
            const localVarPath = `/api/base-data/carpenters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExhibitionSalesman: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteExhibitionSalesman', 'id', id)
            const localVarPath = `/api/base-data/exhibition-salesmen/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeadSource: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLeadSource', 'id', id)
            const localVarPath = `/api/base-data/lead-sources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplier: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSupplier', 'id', id)
            const localVarPath = `/api/base-data/suppliers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaseData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/base-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCarpenterSort: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateCarpenterSort', 'requestBody', requestBody)
            const localVarPath = `/api/base-data/carpenters/sort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExhibitionSalesmanSort: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateExhibitionSalesmanSort', 'requestBody', requestBody)
            const localVarPath = `/api/base-data/exhibition-salesman/sort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLeadSourceSort: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateLeadSourceSort', 'requestBody', requestBody)
            const localVarPath = `/api/base-data/lead-sources/sort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationSort: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateLocationSort', 'requestBody', requestBody)
            const localVarPath = `/api/base-data/locations/sort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierSort: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateSupplierSort', 'requestBody', requestBody)
            const localVarPath = `/api/base-data/suppliers/sort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseDataControllerApi - functional programming interface
 * @export
 */
export const BaseDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CarpenterDTO} carpenterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateCarpenter(carpenterDTO: CarpenterDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarpenterDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateCarpenter(carpenterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExhibitionSalesmanDTO} exhibitionSalesmanDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateExhibitionSalesman(exhibitionSalesmanDTO: ExhibitionSalesmanDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExhibitionSalesmanDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateExhibitionSalesman(exhibitionSalesmanDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LeadSourceDTO} leadSourceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateLeadSource(leadSourceDTO: LeadSourceDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadSourceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateLeadSource(leadSourceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LocationDTO} locationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateLocation(locationDTO: LocationDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateLocation(locationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SupplierDTO} supplierDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateSupplier(supplierDTO: SupplierDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateSupplier(supplierDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCarpenter(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCarpenter(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExhibitionSalesman(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExhibitionSalesman(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLeadSource(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLeadSource(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupplier(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupplier(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBaseData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseDataDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBaseData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCarpenterSort(requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarpenterDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCarpenterSort(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExhibitionSalesmanSort(requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExhibitionSalesmanDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExhibitionSalesmanSort(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLeadSourceSort(requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadSourceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLeadSourceSort(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocationSort(requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocationSort(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupplierSort(requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SupplierDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSupplierSort(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseDataControllerApi - factory interface
 * @export
 */
export const BaseDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CarpenterDTO} carpenterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateCarpenter(carpenterDTO: CarpenterDTO, options?: any): AxiosPromise<CarpenterDTO> {
            return localVarFp.createOrUpdateCarpenter(carpenterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExhibitionSalesmanDTO} exhibitionSalesmanDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateExhibitionSalesman(exhibitionSalesmanDTO: ExhibitionSalesmanDTO, options?: any): AxiosPromise<ExhibitionSalesmanDTO> {
            return localVarFp.createOrUpdateExhibitionSalesman(exhibitionSalesmanDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LeadSourceDTO} leadSourceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateLeadSource(leadSourceDTO: LeadSourceDTO, options?: any): AxiosPromise<LeadSourceDTO> {
            return localVarFp.createOrUpdateLeadSource(leadSourceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationDTO} locationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateLocation(locationDTO: LocationDTO, options?: any): AxiosPromise<LocationDTO> {
            return localVarFp.createOrUpdateLocation(locationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SupplierDTO} supplierDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSupplier(supplierDTO: SupplierDTO, options?: any): AxiosPromise<SupplierDTO> {
            return localVarFp.createOrUpdateSupplier(supplierDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCarpenter(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCarpenter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExhibitionSalesman(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteExhibitionSalesman(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeadSource(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLeadSource(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplier(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSupplier(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaseData(options?: any): AxiosPromise<BaseDataDTO> {
            return localVarFp.getBaseData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCarpenterSort(requestBody: Array<string>, options?: any): AxiosPromise<Array<CarpenterDTO>> {
            return localVarFp.updateCarpenterSort(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExhibitionSalesmanSort(requestBody: Array<string>, options?: any): AxiosPromise<Array<ExhibitionSalesmanDTO>> {
            return localVarFp.updateExhibitionSalesmanSort(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLeadSourceSort(requestBody: Array<string>, options?: any): AxiosPromise<Array<LeadSourceDTO>> {
            return localVarFp.updateLeadSourceSort(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationSort(requestBody: Array<string>, options?: any): AxiosPromise<Array<LocationDTO>> {
            return localVarFp.updateLocationSort(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierSort(requestBody: Array<string>, options?: any): AxiosPromise<Array<SupplierDTO>> {
            return localVarFp.updateSupplierSort(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseDataControllerApi - object-oriented interface
 * @export
 * @class BaseDataControllerApi
 * @extends {BaseAPI}
 */
export class BaseDataControllerApi extends BaseAPI {
    /**
     * 
     * @param {CarpenterDTO} carpenterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public createOrUpdateCarpenter(carpenterDTO: CarpenterDTO, options?: any) {
        return BaseDataControllerApiFp(this.configuration).createOrUpdateCarpenter(carpenterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExhibitionSalesmanDTO} exhibitionSalesmanDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public createOrUpdateExhibitionSalesman(exhibitionSalesmanDTO: ExhibitionSalesmanDTO, options?: any) {
        return BaseDataControllerApiFp(this.configuration).createOrUpdateExhibitionSalesman(exhibitionSalesmanDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadSourceDTO} leadSourceDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public createOrUpdateLeadSource(leadSourceDTO: LeadSourceDTO, options?: any) {
        return BaseDataControllerApiFp(this.configuration).createOrUpdateLeadSource(leadSourceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationDTO} locationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public createOrUpdateLocation(locationDTO: LocationDTO, options?: any) {
        return BaseDataControllerApiFp(this.configuration).createOrUpdateLocation(locationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SupplierDTO} supplierDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public createOrUpdateSupplier(supplierDTO: SupplierDTO, options?: any) {
        return BaseDataControllerApiFp(this.configuration).createOrUpdateSupplier(supplierDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public deleteCarpenter(id: string, options?: any) {
        return BaseDataControllerApiFp(this.configuration).deleteCarpenter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public deleteExhibitionSalesman(id: string, options?: any) {
        return BaseDataControllerApiFp(this.configuration).deleteExhibitionSalesman(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public deleteLeadSource(id: string, options?: any) {
        return BaseDataControllerApiFp(this.configuration).deleteLeadSource(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public deleteSupplier(id: string, options?: any) {
        return BaseDataControllerApiFp(this.configuration).deleteSupplier(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public getBaseData(options?: any) {
        return BaseDataControllerApiFp(this.configuration).getBaseData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public updateCarpenterSort(requestBody: Array<string>, options?: any) {
        return BaseDataControllerApiFp(this.configuration).updateCarpenterSort(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public updateExhibitionSalesmanSort(requestBody: Array<string>, options?: any) {
        return BaseDataControllerApiFp(this.configuration).updateExhibitionSalesmanSort(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public updateLeadSourceSort(requestBody: Array<string>, options?: any) {
        return BaseDataControllerApiFp(this.configuration).updateLeadSourceSort(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public updateLocationSort(requestBody: Array<string>, options?: any) {
        return BaseDataControllerApiFp(this.configuration).updateLocationSort(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDataControllerApi
     */
    public updateSupplierSort(requestBody: Array<string>, options?: any) {
        return BaseDataControllerApiFp(this.configuration).updateSupplierSort(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ComplaintControllerApi - axios parameter creator
 * @export
 */
export const ComplaintControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ComplaintDTO} complaintDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateComplaint: async (complaintDTO: ComplaintDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complaintDTO' is not null or undefined
            assertParamExists('createOrUpdateComplaint', 'complaintDTO', complaintDTO)
            const localVarPath = `/api/complaints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(complaintDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComplaint: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteComplaint', 'id', id)
            const localVarPath = `/api/complaints/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplaint: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getComplaint', 'id', id)
            const localVarPath = `/api/complaints/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplaints: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/complaints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setComplaintState: async (id: string, body: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setComplaintState', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('setComplaintState', 'body', body)
            const localVarPath = `/api/complaints/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComplaintControllerApi - functional programming interface
 * @export
 */
export const ComplaintControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComplaintControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ComplaintDTO} complaintDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateComplaint(complaintDTO: ComplaintDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplaintOverviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateComplaint(complaintDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComplaint(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComplaint(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComplaint(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplaintDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComplaint(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComplaints(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComplaintOverviewDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComplaints(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setComplaintState(id: string, body: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplaintOverviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setComplaintState(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComplaintControllerApi - factory interface
 * @export
 */
export const ComplaintControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComplaintControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ComplaintDTO} complaintDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateComplaint(complaintDTO: ComplaintDTO, options?: any): AxiosPromise<ComplaintOverviewDTO> {
            return localVarFp.createOrUpdateComplaint(complaintDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComplaint(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.deleteComplaint(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplaint(id: string, options?: any): AxiosPromise<ComplaintDTO> {
            return localVarFp.getComplaint(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplaints(options?: any): AxiosPromise<Array<ComplaintOverviewDTO>> {
            return localVarFp.getComplaints(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setComplaintState(id: string, body: string, options?: any): AxiosPromise<ComplaintOverviewDTO> {
            return localVarFp.setComplaintState(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComplaintControllerApi - object-oriented interface
 * @export
 * @class ComplaintControllerApi
 * @extends {BaseAPI}
 */
export class ComplaintControllerApi extends BaseAPI {
    /**
     * 
     * @param {ComplaintDTO} complaintDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplaintControllerApi
     */
    public createOrUpdateComplaint(complaintDTO: ComplaintDTO, options?: any) {
        return ComplaintControllerApiFp(this.configuration).createOrUpdateComplaint(complaintDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplaintControllerApi
     */
    public deleteComplaint(id: string, options?: any) {
        return ComplaintControllerApiFp(this.configuration).deleteComplaint(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplaintControllerApi
     */
    public getComplaint(id: string, options?: any) {
        return ComplaintControllerApiFp(this.configuration).getComplaint(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplaintControllerApi
     */
    public getComplaints(options?: any) {
        return ComplaintControllerApiFp(this.configuration).getComplaints(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplaintControllerApi
     */
    public setComplaintState(id: string, body: string, options?: any) {
        return ComplaintControllerApiFp(this.configuration).setComplaintState(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExportControllerApi - axios parameter creator
 * @export
 */
export const ExportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ExportFileType} type 
         * @param {BilledOrderFilePostDTO} billedOrderFilePostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBilledOrderFile: async (type: ExportFileType, billedOrderFilePostDTO: BilledOrderFilePostDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getBilledOrderFile', 'type', type)
            // verify required parameter 'billedOrderFilePostDTO' is not null or undefined
            assertParamExists('getBilledOrderFile', 'billedOrderFilePostDTO', billedOrderFilePostDTO)
            const localVarPath = `/api/export/billed-order-file/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billedOrderFilePostDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplaintFile: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getComplaintFile', 'id', id)
            const localVarPath = `/api/export/complaints/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (key: string, filename: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getFile', 'key', key)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('getFile', 'filename', filename)
            const localVarPath = `/api/export/files/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportFileType} type 
         * @param {OrderFilePostDTO} orderFilePostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderFile: async (type: ExportFileType, orderFilePostDTO: OrderFilePostDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getOrderFile', 'type', type)
            // verify required parameter 'orderFilePostDTO' is not null or undefined
            assertParamExists('getOrderFile', 'orderFilePostDTO', orderFilePostDTO)
            const localVarPath = `/api/export/order-file/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderFilePostDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChartDuration} chartDuration 
         * @param {Array<OrderType>} [types] 
         * @param {Array<string>} [locationIds] 
         * @param {Array<string>} [userIds] 
         * @param {number} [compareYear] 
         * @param {string} [compareMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlotData: async (chartDuration: ChartDuration, types?: Array<OrderType>, locationIds?: Array<string>, userIds?: Array<string>, compareYear?: number, compareMonth?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chartDuration' is not null or undefined
            assertParamExists('getPlotData', 'chartDuration', chartDuration)
            const localVarPath = `/api/export/plot-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chartDuration !== undefined) {
                localVarQueryParameter['chartDuration'] = chartDuration;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (locationIds) {
                localVarQueryParameter['locationIds'] = locationIds;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (compareYear !== undefined) {
                localVarQueryParameter['compareYear'] = compareYear;
            }

            if (compareMonth !== undefined) {
                localVarQueryParameter['compareMonth'] = compareMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportFileType} type 
         * @param {string} title 
         * @param {Array<Array<string>>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableFile: async (type: ExportFileType, title: string, requestBody: Array<Array<string>>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getTableFile', 'type', type)
            // verify required parameter 'title' is not null or undefined
            assertParamExists('getTableFile', 'title', title)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getTableFile', 'requestBody', requestBody)
            const localVarPath = `/api/export/table-file/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<string>} [locationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekOverview: async (date: string, locationIds?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getWeekOverview', 'date', date)
            const localVarPath = `/api/export/week`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (locationIds) {
                localVarQueryParameter['locationIds'] = locationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportFileType} type 
         * @param {string} date 
         * @param {Array<string>} [locations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekOverviewFile: async (type: ExportFileType, date: string, locations?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getWeekOverviewFile', 'type', type)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getWeekOverviewFile', 'date', date)
            const localVarPath = `/api/export/week/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (locations) {
                localVarQueryParameter['locations'] = locations;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentType} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printOrderDocumentFile: async (type: DocumentType, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('printOrderDocumentFile', 'type', type)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('printOrderDocumentFile', 'id', id)
            const localVarPath = `/api/export/order/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printOrderPaymentDocumentFile: async (id: string, paymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('printOrderPaymentDocumentFile', 'id', id)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('printOrderPaymentDocumentFile', 'paymentId', paymentId)
            const localVarPath = `/api/export/order/{id}/payments/{paymentId}/document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankBalances: async (requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateBankBalances', 'requestBody', requestBody)
            const localVarPath = `/api/export/bank-balances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportControllerApi - functional programming interface
 * @export
 */
export const ExportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ExportFileType} type 
         * @param {BilledOrderFilePostDTO} billedOrderFilePostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBilledOrderFile(type: ExportFileType, billedOrderFilePostDTO: BilledOrderFilePostDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileRedirectDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBilledOrderFile(type, billedOrderFilePostDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComplaintFile(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileRedirectDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComplaintFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} key 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(key: string, filename: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFile(key, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExportFileType} type 
         * @param {OrderFilePostDTO} orderFilePostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderFile(type: ExportFileType, orderFilePostDTO: OrderFilePostDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileRedirectDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderFile(type, orderFilePostDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChartDuration} chartDuration 
         * @param {Array<OrderType>} [types] 
         * @param {Array<string>} [locationIds] 
         * @param {Array<string>} [userIds] 
         * @param {number} [compareYear] 
         * @param {string} [compareMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlotData(chartDuration: ChartDuration, types?: Array<OrderType>, locationIds?: Array<string>, userIds?: Array<string>, compareYear?: number, compareMonth?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardPlotDataDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlotData(chartDuration, types, locationIds, userIds, compareYear, compareMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExportFileType} type 
         * @param {string} title 
         * @param {Array<Array<string>>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTableFile(type: ExportFileType, title: string, requestBody: Array<Array<string>>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileRedirectDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTableFile(type, title, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<string>} [locationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeekOverview(date: string, locationIds?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeekOverviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeekOverview(date, locationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExportFileType} type 
         * @param {string} date 
         * @param {Array<string>} [locations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeekOverviewFile(type: ExportFileType, date: string, locations?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileRedirectDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeekOverviewFile(type, date, locations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DocumentType} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printOrderDocumentFile(type: DocumentType, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileRedirectDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.printOrderDocumentFile(type, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printOrderPaymentDocumentFile(id: string, paymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileRedirectDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.printOrderPaymentDocumentFile(id, paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBankBalances(requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBankBalances(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportControllerApi - factory interface
 * @export
 */
export const ExportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ExportFileType} type 
         * @param {BilledOrderFilePostDTO} billedOrderFilePostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBilledOrderFile(type: ExportFileType, billedOrderFilePostDTO: BilledOrderFilePostDTO, options?: any): AxiosPromise<FileRedirectDTO> {
            return localVarFp.getBilledOrderFile(type, billedOrderFilePostDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplaintFile(id: string, options?: any): AxiosPromise<FileRedirectDTO> {
            return localVarFp.getComplaintFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(key: string, filename: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getFile(key, filename, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportFileType} type 
         * @param {OrderFilePostDTO} orderFilePostDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderFile(type: ExportFileType, orderFilePostDTO: OrderFilePostDTO, options?: any): AxiosPromise<FileRedirectDTO> {
            return localVarFp.getOrderFile(type, orderFilePostDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChartDuration} chartDuration 
         * @param {Array<OrderType>} [types] 
         * @param {Array<string>} [locationIds] 
         * @param {Array<string>} [userIds] 
         * @param {number} [compareYear] 
         * @param {string} [compareMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlotData(chartDuration: ChartDuration, types?: Array<OrderType>, locationIds?: Array<string>, userIds?: Array<string>, compareYear?: number, compareMonth?: string, options?: any): AxiosPromise<DashboardPlotDataDTO> {
            return localVarFp.getPlotData(chartDuration, types, locationIds, userIds, compareYear, compareMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportFileType} type 
         * @param {string} title 
         * @param {Array<Array<string>>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableFile(type: ExportFileType, title: string, requestBody: Array<Array<string>>, options?: any): AxiosPromise<FileRedirectDTO> {
            return localVarFp.getTableFile(type, title, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<string>} [locationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekOverview(date: string, locationIds?: Array<string>, options?: any): AxiosPromise<WeekOverviewDTO> {
            return localVarFp.getWeekOverview(date, locationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportFileType} type 
         * @param {string} date 
         * @param {Array<string>} [locations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekOverviewFile(type: ExportFileType, date: string, locations?: Array<string>, options?: any): AxiosPromise<FileRedirectDTO> {
            return localVarFp.getWeekOverviewFile(type, date, locations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentType} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printOrderDocumentFile(type: DocumentType, id: string, options?: any): AxiosPromise<FileRedirectDTO> {
            return localVarFp.printOrderDocumentFile(type, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printOrderPaymentDocumentFile(id: string, paymentId: string, options?: any): AxiosPromise<FileRedirectDTO> {
            return localVarFp.printOrderPaymentDocumentFile(id, paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankBalances(requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.updateBankBalances(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportControllerApi - object-oriented interface
 * @export
 * @class ExportControllerApi
 * @extends {BaseAPI}
 */
export class ExportControllerApi extends BaseAPI {
    /**
     * 
     * @param {ExportFileType} type 
     * @param {BilledOrderFilePostDTO} billedOrderFilePostDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportControllerApi
     */
    public getBilledOrderFile(type: ExportFileType, billedOrderFilePostDTO: BilledOrderFilePostDTO, options?: any) {
        return ExportControllerApiFp(this.configuration).getBilledOrderFile(type, billedOrderFilePostDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportControllerApi
     */
    public getComplaintFile(id: string, options?: any) {
        return ExportControllerApiFp(this.configuration).getComplaintFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} key 
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportControllerApi
     */
    public getFile(key: string, filename: string, options?: any) {
        return ExportControllerApiFp(this.configuration).getFile(key, filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportFileType} type 
     * @param {OrderFilePostDTO} orderFilePostDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportControllerApi
     */
    public getOrderFile(type: ExportFileType, orderFilePostDTO: OrderFilePostDTO, options?: any) {
        return ExportControllerApiFp(this.configuration).getOrderFile(type, orderFilePostDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChartDuration} chartDuration 
     * @param {Array<OrderType>} [types] 
     * @param {Array<string>} [locationIds] 
     * @param {Array<string>} [userIds] 
     * @param {number} [compareYear] 
     * @param {string} [compareMonth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportControllerApi
     */
    public getPlotData(chartDuration: ChartDuration, types?: Array<OrderType>, locationIds?: Array<string>, userIds?: Array<string>, compareYear?: number, compareMonth?: string, options?: any) {
        return ExportControllerApiFp(this.configuration).getPlotData(chartDuration, types, locationIds, userIds, compareYear, compareMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportFileType} type 
     * @param {string} title 
     * @param {Array<Array<string>>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportControllerApi
     */
    public getTableFile(type: ExportFileType, title: string, requestBody: Array<Array<string>>, options?: any) {
        return ExportControllerApiFp(this.configuration).getTableFile(type, title, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {Array<string>} [locationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportControllerApi
     */
    public getWeekOverview(date: string, locationIds?: Array<string>, options?: any) {
        return ExportControllerApiFp(this.configuration).getWeekOverview(date, locationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportFileType} type 
     * @param {string} date 
     * @param {Array<string>} [locations] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportControllerApi
     */
    public getWeekOverviewFile(type: ExportFileType, date: string, locations?: Array<string>, options?: any) {
        return ExportControllerApiFp(this.configuration).getWeekOverviewFile(type, date, locations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentType} type 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportControllerApi
     */
    public printOrderDocumentFile(type: DocumentType, id: string, options?: any) {
        return ExportControllerApiFp(this.configuration).printOrderDocumentFile(type, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportControllerApi
     */
    public printOrderPaymentDocumentFile(id: string, paymentId: string, options?: any) {
        return ExportControllerApiFp(this.configuration).printOrderPaymentDocumentFile(id, paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportControllerApi
     */
    public updateBankBalances(requestBody: Array<number>, options?: any) {
        return ExportControllerApiFp(this.configuration).updateBankBalances(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileControllerApi - axios parameter creator
 * @export
 */
export const FileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {FileType} type 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (id: string, type: FileType, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFile', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deleteFile', 'type', type)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteFile', 'name', name)
            const localVarPath = `/api/files/{id}/{type}/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FileType} type 
         * @param {string} additionalId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileWithAdditionalId: async (id: string, type: FileType, additionalId: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFileWithAdditionalId', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deleteFileWithAdditionalId', 'type', type)
            // verify required parameter 'additionalId' is not null or undefined
            assertParamExists('deleteFileWithAdditionalId', 'additionalId', additionalId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteFileWithAdditionalId', 'name', name)
            const localVarPath = `/api/files/{id}/{type}/{additionalId}/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"additionalId"}}`, encodeURIComponent(String(additionalId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FileType} type 
         * @param {string} name 
         * @param {string} [additionalId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileRedirect: async (id: string, type: FileType, name: string, additionalId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFileRedirect', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getFileRedirect', 'type', type)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getFileRedirect', 'name', name)
            const localVarPath = `/api/files/{id}/{type}/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (additionalId !== undefined) {
                localVarQueryParameter['additionalId'] = additionalId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FileType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles: async (id: string, type?: FileType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFiles', 'id', id)
            const localVarPath = `/api/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {Array<any>} files 
         * @param {string} [id] 
         * @param {FileType} [type] 
         * @param {string} [additionalId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAppPictures: async (filename: string, files: Array<any>, id?: string, type?: FileType, additionalId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('uploadAppPictures', 'filename', filename)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadAppPictures', 'files', files)
            const localVarPath = `/api/files/app/pictures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (additionalId !== undefined) {
                localVarQueryParameter['additionalId'] = additionalId;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file 
         * @param {string} [id] 
         * @param {FileType} [type] 
         * @param {string} [additionalId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (file: any, id?: string, type?: FileType, additionalId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFile', 'file', file)
            const localVarPath = `/api/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (additionalId !== undefined) {
                localVarQueryParameter['additionalId'] = additionalId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileControllerApi - functional programming interface
 * @export
 */
export const FileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {FileType} type 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(id: string, type: FileType, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(id, type, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {FileType} type 
         * @param {string} additionalId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileWithAdditionalId(id: string, type: FileType, additionalId: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileWithAdditionalId(id, type, additionalId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {FileType} type 
         * @param {string} name 
         * @param {string} [additionalId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileRedirect(id: string, type: FileType, name: string, additionalId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileRedirectDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileRedirect(id, type, name, additionalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {FileType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiles(id: string, type?: FileType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiles(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {Array<any>} files 
         * @param {string} [id] 
         * @param {FileType} [type] 
         * @param {string} [additionalId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAppPictures(filename: string, files: Array<any>, id?: string, type?: FileType, additionalId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAppPictures(filename, files, id, type, additionalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} file 
         * @param {string} [id] 
         * @param {FileType} [type] 
         * @param {string} [additionalId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(file: any, id?: string, type?: FileType, additionalId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(file, id, type, additionalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileControllerApi - factory interface
 * @export
 */
export const FileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {FileType} type 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(id: string, type: FileType, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFile(id, type, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {FileType} type 
         * @param {string} additionalId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileWithAdditionalId(id: string, type: FileType, additionalId: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFileWithAdditionalId(id, type, additionalId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {FileType} type 
         * @param {string} name 
         * @param {string} [additionalId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileRedirect(id: string, type: FileType, name: string, additionalId?: string, options?: any): AxiosPromise<FileRedirectDTO> {
            return localVarFp.getFileRedirect(id, type, name, additionalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {FileType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles(id: string, type?: FileType, options?: any): AxiosPromise<Array<FileDTO>> {
            return localVarFp.getFiles(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {Array<any>} files 
         * @param {string} [id] 
         * @param {FileType} [type] 
         * @param {string} [additionalId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAppPictures(filename: string, files: Array<any>, id?: string, type?: FileType, additionalId?: string, options?: any): AxiosPromise<FileDTO> {
            return localVarFp.uploadAppPictures(filename, files, id, type, additionalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file 
         * @param {string} [id] 
         * @param {FileType} [type] 
         * @param {string} [additionalId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file: any, id?: string, type?: FileType, additionalId?: string, options?: any): AxiosPromise<FileDTO> {
            return localVarFp.uploadFile(file, id, type, additionalId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileControllerApi - object-oriented interface
 * @export
 * @class FileControllerApi
 * @extends {BaseAPI}
 */
export class FileControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {FileType} type 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public deleteFile(id: string, type: FileType, name: string, options?: any) {
        return FileControllerApiFp(this.configuration).deleteFile(id, type, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {FileType} type 
     * @param {string} additionalId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public deleteFileWithAdditionalId(id: string, type: FileType, additionalId: string, name: string, options?: any) {
        return FileControllerApiFp(this.configuration).deleteFileWithAdditionalId(id, type, additionalId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {FileType} type 
     * @param {string} name 
     * @param {string} [additionalId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public getFileRedirect(id: string, type: FileType, name: string, additionalId?: string, options?: any) {
        return FileControllerApiFp(this.configuration).getFileRedirect(id, type, name, additionalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {FileType} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public getFiles(id: string, type?: FileType, options?: any) {
        return FileControllerApiFp(this.configuration).getFiles(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filename 
     * @param {Array<any>} files 
     * @param {string} [id] 
     * @param {FileType} [type] 
     * @param {string} [additionalId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public uploadAppPictures(filename: string, files: Array<any>, id?: string, type?: FileType, additionalId?: string, options?: any) {
        return FileControllerApiFp(this.configuration).uploadAppPictures(filename, files, id, type, additionalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file 
     * @param {string} [id] 
     * @param {FileType} [type] 
     * @param {string} [additionalId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public uploadFile(file: any, id?: string, type?: FileType, additionalId?: string, options?: any) {
        return FileControllerApiFp(this.configuration).uploadFile(file, id, type, additionalId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalValueControllerApi - axios parameter creator
 * @export
 */
export const GlobalValueControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultDocumentDataSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/global-values/document-data-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultValues: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/global-values/default-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GlobalValueKey} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalValueByKey: async (key: GlobalValueKey, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getGlobalValueByKey', 'key', key)
            const localVarPath = `/api/global-values/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentType} type 
         * @param {DocumentDataSettingDTO} documentDataSettingDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultDocumentDataSetting: async (type: DocumentType, documentDataSettingDTO: DocumentDataSettingDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('updateDefaultDocumentDataSetting', 'type', type)
            // verify required parameter 'documentDataSettingDTO' is not null or undefined
            assertParamExists('updateDefaultDocumentDataSetting', 'documentDataSettingDTO', documentDataSettingDTO)
            const localVarPath = `/api/global-values/document-data-setting/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentDataSettingDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DefaultValuesDTO} defaultValuesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultValues: async (defaultValuesDTO: DefaultValuesDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'defaultValuesDTO' is not null or undefined
            assertParamExists('updateDefaultValues', 'defaultValuesDTO', defaultValuesDTO)
            const localVarPath = `/api/global-values/default-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defaultValuesDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalValueControllerApi - functional programming interface
 * @export
 */
export const GlobalValueControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalValueControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultDocumentDataSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: DocumentDataSettingDTO; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultDocumentDataSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultValues(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultValuesDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultValues(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GlobalValueKey} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalValueByKey(key: GlobalValueKey, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalValueByKey(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DocumentType} type 
         * @param {DocumentDataSettingDTO} documentDataSettingDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDefaultDocumentDataSetting(type: DocumentType, documentDataSettingDTO: DocumentDataSettingDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDefaultDocumentDataSetting(type, documentDataSettingDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DefaultValuesDTO} defaultValuesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDefaultValues(defaultValuesDTO: DefaultValuesDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDefaultValues(defaultValuesDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalValueControllerApi - factory interface
 * @export
 */
export const GlobalValueControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalValueControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultDocumentDataSettings(options?: any): AxiosPromise<{ [key: string]: DocumentDataSettingDTO; }> {
            return localVarFp.getDefaultDocumentDataSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultValues(options?: any): AxiosPromise<DefaultValuesDTO> {
            return localVarFp.getDefaultValues(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GlobalValueKey} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalValueByKey(key: GlobalValueKey, options?: any): AxiosPromise<string> {
            return localVarFp.getGlobalValueByKey(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentType} type 
         * @param {DocumentDataSettingDTO} documentDataSettingDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultDocumentDataSetting(type: DocumentType, documentDataSettingDTO: DocumentDataSettingDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateDefaultDocumentDataSetting(type, documentDataSettingDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultValuesDTO} defaultValuesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultValues(defaultValuesDTO: DefaultValuesDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateDefaultValues(defaultValuesDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalValueControllerApi - object-oriented interface
 * @export
 * @class GlobalValueControllerApi
 * @extends {BaseAPI}
 */
export class GlobalValueControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalValueControllerApi
     */
    public getDefaultDocumentDataSettings(options?: any) {
        return GlobalValueControllerApiFp(this.configuration).getDefaultDocumentDataSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalValueControllerApi
     */
    public getDefaultValues(options?: any) {
        return GlobalValueControllerApiFp(this.configuration).getDefaultValues(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GlobalValueKey} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalValueControllerApi
     */
    public getGlobalValueByKey(key: GlobalValueKey, options?: any) {
        return GlobalValueControllerApiFp(this.configuration).getGlobalValueByKey(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentType} type 
     * @param {DocumentDataSettingDTO} documentDataSettingDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalValueControllerApi
     */
    public updateDefaultDocumentDataSetting(type: DocumentType, documentDataSettingDTO: DocumentDataSettingDTO, options?: any) {
        return GlobalValueControllerApiFp(this.configuration).updateDefaultDocumentDataSetting(type, documentDataSettingDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultValuesDTO} defaultValuesDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalValueControllerApi
     */
    public updateDefaultValues(defaultValuesDTO: DefaultValuesDTO, options?: any) {
        return GlobalValueControllerApiFp(this.configuration).updateDefaultValues(defaultValuesDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderControllerApi - axios parameter creator
 * @export
 */
export const OrderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OrderDTO} orderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateOrder: async (orderDTO: OrderDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderDTO' is not null or undefined
            assertParamExists('createOrUpdateOrder', 'orderDTO', orderDTO)
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SmallOrderDTO} smallOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSmallOrder: async (smallOrderDTO: SmallOrderDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'smallOrderDTO' is not null or undefined
            assertParamExists('createOrUpdateSmallOrder', 'smallOrderDTO', smallOrderDTO)
            const localVarPath = `/api/orders/small`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(smallOrderDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderTemplateDTO} orderTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateTemplate: async (orderTemplateDTO: OrderTemplateDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderTemplateDTO' is not null or undefined
            assertParamExists('createOrUpdateTemplate', 'orderTemplateDTO', orderTemplateDTO)
            const localVarPath = `/api/orders/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderTemplateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOrder', 'id', id)
            const localVarPath = `/api/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSmallOrder: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSmallOrder', 'id', id)
            const localVarPath = `/api/orders/small/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTemplate', 'id', id)
            const localVarPath = `/api/orders/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {Array<string>} [locationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBilledOrders: async (startDate: string, endDate: string, locationIds?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getBilledOrders', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getBilledOrders', 'endDate', endDate)
            const localVarPath = `/api/orders/billed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (locationIds) {
                localVarQueryParameter['locationIds'] = locationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinimumDate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/minimum-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrder', 'id', id)
            const localVarPath = `/api/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForApp: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockOrders: async (body: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lockOrders', 'body', body)
            const localVarPath = `/api/orders/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FullPaymentPutDTO} fullPaymentPutDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payRemainingAmount: async (id: string, fullPaymentPutDTO: FullPaymentPutDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('payRemainingAmount', 'id', id)
            // verify required parameter 'fullPaymentPutDTO' is not null or undefined
            assertParamExists('payRemainingAmount', 'fullPaymentPutDTO', fullPaymentPutDTO)
            const localVarPath = `/api/orders/{id}/payments/pay`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fullPaymentPutDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderTemplateSelectDTO} orderTemplateSelectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectTemplate: async (orderTemplateSelectDTO: OrderTemplateSelectDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderTemplateSelectDTO' is not null or undefined
            assertParamExists('selectTemplate', 'orderTemplateSelectDTO', orderTemplateSelectDTO)
            const localVarPath = `/api/orders/templates/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderTemplateSelectDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setExhibitionOrderPaid: async (id: string, body: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setExhibitionOrderPaid', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('setExhibitionOrderPaid', 'body', body)
            const localVarPath = `/api/orders/{id}/paid`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} paymentId 
         * @param {FullPaymentPutDTO} fullPaymentPutDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderPaymentPaid: async (id: string, paymentId: string, fullPaymentPutDTO: FullPaymentPutDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOrderPaymentPaid', 'id', id)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('setOrderPaymentPaid', 'paymentId', paymentId)
            // verify required parameter 'fullPaymentPutDTO' is not null or undefined
            assertParamExists('setOrderPaymentPaid', 'fullPaymentPutDTO', fullPaymentPutDTO)
            const localVarPath = `/api/orders/{id}/payments/{paymentId}/paid`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fullPaymentPutDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockOrders: async (body: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('unlockOrders', 'body', body)
            const localVarPath = `/api/orders/unlock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderToDoRemainingAmount: async (id: string, body: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrderToDoRemainingAmount', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateOrderToDoRemainingAmount', 'body', body)
            const localVarPath = `/api/orders/{id}/order-to-do-remaining-amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderControllerApi - functional programming interface
 * @export
 */
export const OrderControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OrderDTO} orderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateOrder(orderDTO: OrderDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOverviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateOrder(orderDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SmallOrderDTO} smallOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateSmallOrder(smallOrderDTO: SmallOrderDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmallOrderDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateSmallOrder(smallOrderDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderTemplateDTO} orderTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateTemplate(orderTemplateDTO: OrderTemplateDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderTemplateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateTemplate(orderTemplateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrder(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSmallOrder(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSmallOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplate(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {Array<string>} [locationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBilledOrders(startDate: string, endDate: string, locationIds?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BilledOrderOverviewDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBilledOrders(startDate, endDate, locationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClients(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMinimumDate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMinimumDate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrder(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersGetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersForApp(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderAppOverviewDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForApp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderTemplateDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockOrders(body: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockOrders(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {FullPaymentPutDTO} fullPaymentPutDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payRemainingAmount(id: string, fullPaymentPutDTO: FullPaymentPutDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payRemainingAmount(id, fullPaymentPutDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderTemplateSelectDTO} orderTemplateSelectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectTemplate(orderTemplateSelectDTO: OrderTemplateSelectDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderTemplateDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectTemplate(orderTemplateSelectDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setExhibitionOrderPaid(id: string, body: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOverviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setExhibitionOrderPaid(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} paymentId 
         * @param {FullPaymentPutDTO} fullPaymentPutDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setOrderPaymentPaid(id: string, paymentId: string, fullPaymentPutDTO: FullPaymentPutDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrderPaymentPaid(id, paymentId, fullPaymentPutDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockOrders(body: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockOrders(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderToDoRemainingAmount(id: string, body: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderToDoRemainingAmount(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderControllerApi - factory interface
 * @export
 */
export const OrderControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {OrderDTO} orderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateOrder(orderDTO: OrderDTO, options?: any): AxiosPromise<OrderOverviewDTO> {
            return localVarFp.createOrUpdateOrder(orderDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SmallOrderDTO} smallOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSmallOrder(smallOrderDTO: SmallOrderDTO, options?: any): AxiosPromise<SmallOrderDTO> {
            return localVarFp.createOrUpdateSmallOrder(smallOrderDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderTemplateDTO} orderTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateTemplate(orderTemplateDTO: OrderTemplateDTO, options?: any): AxiosPromise<OrderTemplateDTO> {
            return localVarFp.createOrUpdateTemplate(orderTemplateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSmallOrder(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSmallOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {Array<string>} [locationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBilledOrders(startDate: string, endDate: string, locationIds?: Array<string>, options?: any): AxiosPromise<Array<BilledOrderOverviewDTO>> {
            return localVarFp.getBilledOrders(startDate, endDate, locationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients(options?: any): AxiosPromise<Array<ClientDTO>> {
            return localVarFp.getClients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinimumDate(options?: any): AxiosPromise<string> {
            return localVarFp.getMinimumDate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(id: string, options?: any): AxiosPromise<OrderDTO> {
            return localVarFp.getOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders(options?: any): AxiosPromise<OrdersGetDTO> {
            return localVarFp.getOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForApp(options?: any): AxiosPromise<Array<OrderAppOverviewDTO>> {
            return localVarFp.getOrdersForApp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(options?: any): AxiosPromise<Array<OrderTemplateDTO>> {
            return localVarFp.getTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockOrders(body: string, options?: any): AxiosPromise<void> {
            return localVarFp.lockOrders(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {FullPaymentPutDTO} fullPaymentPutDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payRemainingAmount(id: string, fullPaymentPutDTO: FullPaymentPutDTO, options?: any): AxiosPromise<Order> {
            return localVarFp.payRemainingAmount(id, fullPaymentPutDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderTemplateSelectDTO} orderTemplateSelectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectTemplate(orderTemplateSelectDTO: OrderTemplateSelectDTO, options?: any): AxiosPromise<Array<OrderTemplateDTO>> {
            return localVarFp.selectTemplate(orderTemplateSelectDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setExhibitionOrderPaid(id: string, body: boolean, options?: any): AxiosPromise<OrderOverviewDTO> {
            return localVarFp.setExhibitionOrderPaid(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} paymentId 
         * @param {FullPaymentPutDTO} fullPaymentPutDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderPaymentPaid(id: string, paymentId: string, fullPaymentPutDTO: FullPaymentPutDTO, options?: any): AxiosPromise<void> {
            return localVarFp.setOrderPaymentPaid(id, paymentId, fullPaymentPutDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockOrders(body: string, options?: any): AxiosPromise<void> {
            return localVarFp.unlockOrders(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderToDoRemainingAmount(id: string, body: boolean, options?: any): AxiosPromise<Order> {
            return localVarFp.updateOrderToDoRemainingAmount(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderControllerApi - object-oriented interface
 * @export
 * @class OrderControllerApi
 * @extends {BaseAPI}
 */
export class OrderControllerApi extends BaseAPI {
    /**
     * 
     * @param {OrderDTO} orderDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public createOrUpdateOrder(orderDTO: OrderDTO, options?: any) {
        return OrderControllerApiFp(this.configuration).createOrUpdateOrder(orderDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SmallOrderDTO} smallOrderDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public createOrUpdateSmallOrder(smallOrderDTO: SmallOrderDTO, options?: any) {
        return OrderControllerApiFp(this.configuration).createOrUpdateSmallOrder(smallOrderDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderTemplateDTO} orderTemplateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public createOrUpdateTemplate(orderTemplateDTO: OrderTemplateDTO, options?: any) {
        return OrderControllerApiFp(this.configuration).createOrUpdateTemplate(orderTemplateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public deleteOrder(id: string, options?: any) {
        return OrderControllerApiFp(this.configuration).deleteOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public deleteSmallOrder(id: string, options?: any) {
        return OrderControllerApiFp(this.configuration).deleteSmallOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public deleteTemplate(id: string, options?: any) {
        return OrderControllerApiFp(this.configuration).deleteTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {Array<string>} [locationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public getBilledOrders(startDate: string, endDate: string, locationIds?: Array<string>, options?: any) {
        return OrderControllerApiFp(this.configuration).getBilledOrders(startDate, endDate, locationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public getClients(options?: any) {
        return OrderControllerApiFp(this.configuration).getClients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public getMinimumDate(options?: any) {
        return OrderControllerApiFp(this.configuration).getMinimumDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public getOrder(id: string, options?: any) {
        return OrderControllerApiFp(this.configuration).getOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public getOrders(options?: any) {
        return OrderControllerApiFp(this.configuration).getOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public getOrdersForApp(options?: any) {
        return OrderControllerApiFp(this.configuration).getOrdersForApp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public getTemplates(options?: any) {
        return OrderControllerApiFp(this.configuration).getTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public lockOrders(body: string, options?: any) {
        return OrderControllerApiFp(this.configuration).lockOrders(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {FullPaymentPutDTO} fullPaymentPutDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public payRemainingAmount(id: string, fullPaymentPutDTO: FullPaymentPutDTO, options?: any) {
        return OrderControllerApiFp(this.configuration).payRemainingAmount(id, fullPaymentPutDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderTemplateSelectDTO} orderTemplateSelectDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public selectTemplate(orderTemplateSelectDTO: OrderTemplateSelectDTO, options?: any) {
        return OrderControllerApiFp(this.configuration).selectTemplate(orderTemplateSelectDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public setExhibitionOrderPaid(id: string, body: boolean, options?: any) {
        return OrderControllerApiFp(this.configuration).setExhibitionOrderPaid(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} paymentId 
     * @param {FullPaymentPutDTO} fullPaymentPutDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public setOrderPaymentPaid(id: string, paymentId: string, fullPaymentPutDTO: FullPaymentPutDTO, options?: any) {
        return OrderControllerApiFp(this.configuration).setOrderPaymentPaid(id, paymentId, fullPaymentPutDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public unlockOrders(body: string, options?: any) {
        return OrderControllerApiFp(this.configuration).unlockOrders(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public updateOrderToDoRemainingAmount(id: string, body: boolean, options?: any) {
        return OrderControllerApiFp(this.configuration).updateOrderToDoRemainingAmount(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantControllerApi - axios parameter creator
 * @export
 */
export const TenantControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TenantDTO} tenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateTenant: async (tenantDTO: TenantDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantDTO' is not null or undefined
            assertParamExists('createOrUpdateTenant', 'tenantDTO', tenantDTO)
            const localVarPath = `/api/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tenants/packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenants: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdditionalPackageDTO} additionalPackageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdditionalPackage: async (additionalPackageDTO: AdditionalPackageDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'additionalPackageDTO' is not null or undefined
            assertParamExists('updateAdditionalPackage', 'additionalPackageDTO', additionalPackageDTO)
            const localVarPath = `/api/tenants/additional-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionalPackageDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BasePackageDTO} basePackageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBasePackage: async (basePackageDTO: BasePackageDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'basePackageDTO' is not null or undefined
            assertParamExists('updateBasePackage', 'basePackageDTO', basePackageDTO)
            const localVarPath = `/api/tenants/base-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(basePackageDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantControllerApi - functional programming interface
 * @export
 */
export const TenantControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TenantDTO} tenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateTenant(tenantDTO: TenantDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateTenant(tenantDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageOverviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenants(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenants(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdditionalPackageDTO} additionalPackageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdditionalPackage(additionalPackageDTO: AdditionalPackageDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdditionalPackage(additionalPackageDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BasePackageDTO} basePackageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBasePackage(basePackageDTO: BasePackageDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBasePackage(basePackageDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantControllerApi - factory interface
 * @export
 */
export const TenantControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantDTO} tenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateTenant(tenantDTO: TenantDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createOrUpdateTenant(tenantDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackages(options?: any): AxiosPromise<PackageOverviewDTO> {
            return localVarFp.getPackages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenants(options?: any): AxiosPromise<Array<TenantDTO>> {
            return localVarFp.getTenants(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdditionalPackageDTO} additionalPackageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdditionalPackage(additionalPackageDTO: AdditionalPackageDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateAdditionalPackage(additionalPackageDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BasePackageDTO} basePackageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBasePackage(basePackageDTO: BasePackageDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateBasePackage(basePackageDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantControllerApi - object-oriented interface
 * @export
 * @class TenantControllerApi
 * @extends {BaseAPI}
 */
export class TenantControllerApi extends BaseAPI {
    /**
     * 
     * @param {TenantDTO} tenantDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantControllerApi
     */
    public createOrUpdateTenant(tenantDTO: TenantDTO, options?: any) {
        return TenantControllerApiFp(this.configuration).createOrUpdateTenant(tenantDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantControllerApi
     */
    public getPackages(options?: any) {
        return TenantControllerApiFp(this.configuration).getPackages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantControllerApi
     */
    public getTenants(options?: any) {
        return TenantControllerApiFp(this.configuration).getTenants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdditionalPackageDTO} additionalPackageDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantControllerApi
     */
    public updateAdditionalPackage(additionalPackageDTO: AdditionalPackageDTO, options?: any) {
        return TenantControllerApiFp(this.configuration).updateAdditionalPackage(additionalPackageDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BasePackageDTO} basePackageDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantControllerApi
     */
    public updateBasePackage(basePackageDTO: BasePackageDTO, options?: any) {
        return TenantControllerApiFp(this.configuration).updateBasePackage(basePackageDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateUser: async (userDTO: UserDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDTO' is not null or undefined
            assertParamExists('createOrUpdateUser', 'userDTO', userDTO)
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mail 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doesMailExist: async (mail: string, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mail' is not null or undefined
            assertParamExists('doesMailExist', 'mail', mail)
            const localVarPath = `/api/users/mail-exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mail !== undefined) {
                localVarQueryParameter['mail'] = mail;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvision: async (userId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/provision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRightsForRole: async (role: UserRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('getUserRightsForRole', 'role', role)
            const localVarPath = `/api/users/rights/roles/{role}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRightsForUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserRightsForUser', 'userId', userId)
            const localVarPath = `/api/users/rights/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProvisionForUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resetProvisionForUser', 'userId', userId)
            const localVarPath = `/api/users/provision/users/{userId}/reset`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserRightsForRole: async (role: UserRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('resetUserRightsForRole', 'role', role)
            const localVarPath = `/api/users/rights/roles/{role}/reset`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserRightsForUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resetUserRightsForUser', 'userId', userId)
            const localVarPath = `/api/users/rights/users/{userId}/reset`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserRole} role 
         * @param {Array<UserRight>} userRight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserRightsForRole: async (role: UserRole, userRight: Array<UserRight>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('setUserRightsForRole', 'role', role)
            // verify required parameter 'userRight' is not null or undefined
            assertParamExists('setUserRightsForRole', 'userRight', userRight)
            const localVarPath = `/api/users/rights/roles/{role}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRight, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {Array<UserRight>} userRight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserRightsForUser: async (userId: string, userRight: Array<UserRight>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setUserRightsForUser', 'userId', userId)
            // verify required parameter 'userRight' is not null or undefined
            assertParamExists('setUserRightsForUser', 'userRight', userRight)
            const localVarPath = `/api/users/rights/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRight, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProvisionDTO} provisionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvision: async (provisionDTO: ProvisionDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'provisionDTO' is not null or undefined
            assertParamExists('updateProvision', 'provisionDTO', provisionDTO)
            const localVarPath = `/api/users/provision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(provisionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {ProvisionDTO} provisionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvisionForUser: async (userId: string, provisionDTO: ProvisionDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateProvisionForUser', 'userId', userId)
            // verify required parameter 'provisionDTO' is not null or undefined
            assertParamExists('updateProvisionForUser', 'provisionDTO', provisionDTO)
            const localVarPath = `/api/users/provision/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(provisionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateUser(userDTO: UserDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateUser(userDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mail 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doesMailExist(mail: string, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doesMailExist(mail, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvision(userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProvisionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvision(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRightsForRole(role: UserRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRight>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRightsForRole(role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRightsForUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRight>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRightsForUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserGetDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetProvisionForUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProvisionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetProvisionForUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserRightsForRole(role: UserRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRight>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserRightsForRole(role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserRightsForUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRight>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserRightsForUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserRole} role 
         * @param {Array<UserRight>} userRight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserRightsForRole(role: UserRole, userRight: Array<UserRight>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserRightsForRole(role, userRight, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {Array<UserRight>} userRight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserRightsForUser(userId: string, userRight: Array<UserRight>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserRightsForUser(userId, userRight, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProvisionDTO} provisionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProvision(provisionDTO: ProvisionDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProvision(provisionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {ProvisionDTO} provisionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProvisionForUser(userId: string, provisionDTO: ProvisionDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProvisionForUser(userId, provisionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateUser(userDTO: UserDTO, options?: any): AxiosPromise<UserDTO> {
            return localVarFp.createOrUpdateUser(userDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mail 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doesMailExist(mail: string, userId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.doesMailExist(mail, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvision(userId?: string, options?: any): AxiosPromise<ProvisionDTO> {
            return localVarFp.getProvision(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRightsForRole(role: UserRole, options?: any): AxiosPromise<Array<UserRight>> {
            return localVarFp.getUserRightsForRole(role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRightsForUser(userId: string, options?: any): AxiosPromise<Array<UserRight>> {
            return localVarFp.getUserRightsForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<UserGetDTO>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProvisionForUser(userId: string, options?: any): AxiosPromise<ProvisionDTO> {
            return localVarFp.resetProvisionForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserRightsForRole(role: UserRole, options?: any): AxiosPromise<Array<UserRight>> {
            return localVarFp.resetUserRightsForRole(role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserRightsForUser(userId: string, options?: any): AxiosPromise<Array<UserRight>> {
            return localVarFp.resetUserRightsForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserRole} role 
         * @param {Array<UserRight>} userRight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserRightsForRole(role: UserRole, userRight: Array<UserRight>, options?: any): AxiosPromise<void> {
            return localVarFp.setUserRightsForRole(role, userRight, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {Array<UserRight>} userRight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserRightsForUser(userId: string, userRight: Array<UserRight>, options?: any): AxiosPromise<void> {
            return localVarFp.setUserRightsForUser(userId, userRight, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProvisionDTO} provisionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvision(provisionDTO: ProvisionDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateProvision(provisionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {ProvisionDTO} provisionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvisionForUser(userId: string, provisionDTO: ProvisionDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateProvisionForUser(userId, provisionDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @param {UserDTO} userDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public createOrUpdateUser(userDTO: UserDTO, options?: any) {
        return UserControllerApiFp(this.configuration).createOrUpdateUser(userDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteUser(id: string, options?: any) {
        return UserControllerApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mail 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public doesMailExist(mail: string, userId?: string, options?: any) {
        return UserControllerApiFp(this.configuration).doesMailExist(mail, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getProvision(userId?: string, options?: any) {
        return UserControllerApiFp(this.configuration).getProvision(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserRole} role 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserRightsForRole(role: UserRole, options?: any) {
        return UserControllerApiFp(this.configuration).getUserRightsForRole(role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserRightsForUser(userId: string, options?: any) {
        return UserControllerApiFp(this.configuration).getUserRightsForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUsers(options?: any) {
        return UserControllerApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public resetProvisionForUser(userId: string, options?: any) {
        return UserControllerApiFp(this.configuration).resetProvisionForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserRole} role 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public resetUserRightsForRole(role: UserRole, options?: any) {
        return UserControllerApiFp(this.configuration).resetUserRightsForRole(role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public resetUserRightsForUser(userId: string, options?: any) {
        return UserControllerApiFp(this.configuration).resetUserRightsForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserRole} role 
     * @param {Array<UserRight>} userRight 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public setUserRightsForRole(role: UserRole, userRight: Array<UserRight>, options?: any) {
        return UserControllerApiFp(this.configuration).setUserRightsForRole(role, userRight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {Array<UserRight>} userRight 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public setUserRightsForUser(userId: string, userRight: Array<UserRight>, options?: any) {
        return UserControllerApiFp(this.configuration).setUserRightsForUser(userId, userRight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProvisionDTO} provisionDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateProvision(provisionDTO: ProvisionDTO, options?: any) {
        return UserControllerApiFp(this.configuration).updateProvision(provisionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {ProvisionDTO} provisionDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateProvisionForUser(userId: string, provisionDTO: ProvisionDTO, options?: any) {
        return UserControllerApiFp(this.configuration).updateProvisionForUser(userId, provisionDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


