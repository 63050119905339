
import { ExportFileType } from "./../api";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DropdownPair } from "./../interfaces";

export class PrintSelectorParams {
  type?: ExportFileType;
  id?: string;
}

@Component
export default class PrintSelector extends Vue {
  @Prop()
  buttonClass?: string;

  @Prop()
  hideXls?: boolean;

  @Prop()
  id?: string;

  @Prop()
  xSmall?: boolean;

  get printOptions(): Array<DropdownPair<ExportFileType>> {
    const toReturn: Array<DropdownPair<ExportFileType>> = [];
    toReturn.push({
      key: ExportFileType.Pdf,
      label: this.$t("exportFileTypeEnum.PDF").toString(),
    });
    if (!this.hideXls) {
      toReturn.push({
        key: ExportFileType.Xls,
        label: this.$t("exportFileTypeEnum.XLS").toString(),
      });
    }
    toReturn.push({
      key: ExportFileType.Doc,
      label: this.$t("exportFileTypeEnum.DOC").toString(),
    });
    return toReturn;
  }

  print(type: ExportFileType) {
    this.$emit("print", { type, id: this.id });
  }
}
