import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import deValidations from "vee-validate/dist/locale/de.json";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const messages: LocaleMessages = {
    de: {
      ...require("./locales/de.json"),
      ...{ validations: deValidations.messages },
    },
  };
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "de",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "de",
  messages: loadLocaleMessages(),
});
