import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// @ts-ignore
import de from "vuetify/lib/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: "#27292A",
      },
      light: {
        primary: "#709ED4",
        secondary: "#656565",
        accent: "#0B0C0C",
      },
    },
  },
  lang: {
    locales: { de },
    current: "de",
  },
});
