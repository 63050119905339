
import Component, { mixins } from "vue-class-component";
import Navigation from "./../components/Navigation.vue";
import OrderDetail, { InitialOrderValues } from "./../components/OrderDetail.vue";
import DeleteConfirmation from "./../components/DeleteConfirmation.vue";
import ResetFiltersButton from "./../components/ResetFiltersButton.vue";
import PrintSelector, { PrintSelectorParams } from "./../components/PrintSelector.vue";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { Action, Getter } from "vuex-class";
import { exportApi, orderApi, RootActions, RootGetters } from "./../store";
import {
  CarpenterDTO,
  ChartDuration,
  DefaultValuesDTO,
  FileRedirectDTO,
  OrderOverviewDTO,
  OrderState,
  OrderTemplateDTO,
  OrderType,
  SupplierGetDTO,
  UserRight,
} from "./../api";
import { DropdownPair } from "./../interfaces";
import { Prop, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import { LocationMixin } from "./../mixins/location";
import draggable from "vuedraggable";
import { UserMixin } from "./../mixins/user";
import { openFileInNewTab } from "./../helpers";

dayjs.extend(isBetween);

@Component({
  components: {
    Navigation,
    OrderDetail,
    DeleteConfirmation,
    ResetFiltersButton,
    PrintSelector,
    draggable,
  },
})
export default class Orders extends mixins(LocationMixin, UserMixin) {
  @Getter(RootGetters.getTableFooterProps)
  dataTableFooterProps: any;
  @Getter(RootGetters.getCarpenters)
  carpenterValues!: CarpenterDTO[];
  @Getter(RootGetters.getOrders)
  orders!: OrderOverviewDTO[];
  @Getter(RootGetters.getExhibitionOrders)
  exhibitionOrders!: OrderOverviewDTO[];
  @Getter(RootGetters.getSuppliers)
  suppliers!: SupplierGetDTO[];
  @Getter(RootGetters.getBasePath)
  basePath!: string;
  @Getter(RootGetters.getDefaultValues)
  defaultValues!: DefaultValuesDTO;

  @Action(RootActions.getOrders)
  getOrders!: () => void;
  @Action(RootActions.deleteOrder)
  deleteOrder!: (id: string) => void;

  @Prop()
  detail?: string;

  templates: OrderTemplateDTO[] = [];
  selectedTemplateId: string = "";
  showTemplateDialog: boolean = false;
  editTemplate: OrderTemplateDTO = { name: "", selectedFields: [], tabs: {} };
  currentTemplateTabs: string[] = [];
  templateFieldToAdd: string = "";

  editOrderValues: InitialOrderValues = {
    id: "",
    salesmanId: "",
    isExhibitionOrder: false,
  };
  deleteOrderId: string = "";
  loading: boolean = false;

  showDeleteDialog: boolean = false;
  showOrderDialog: boolean = false;
  showDateFilter: boolean = false;
  dates: string[] = [];
  filterSellDate: boolean = true;
  salesmenIdFilter: string[] = [];
  exhibitionSalesmenIdFilter: string[] = [];
  locationFilter: string[] = [];
  exhibitionLocationFilter: string[] = [];
  typeFilter: OrderType[] = [];
  stateFilter: OrderState[] = [];
  typeValues: Array<DropdownPair<OrderType>> = [
    {
      key: OrderType.Normal,
      label: this.$t("order.typeEnum.NORMAL").toString(),
    },
    {
      key: OrderType.Small,
      label: this.$t("order.typeEnum.SMALL").toString(),
    },
  ];
  orderDurationValues: Array<DropdownPair<ChartDuration>> = [
    {
      key: ChartDuration.Last30Days,
      label: this.$t("chartDurationEnum.LAST_30_DAYS").toString(),
    },
    {
      key: ChartDuration.Last3Months,
      label: this.$t("chartDurationEnum.LAST_3_MONTHS").toString(),
    },
    {
      key: ChartDuration.LastYear,
      label: this.$t("chartDurationEnum.LAST_YEAR").toString(),
    },
    {
      key: ChartDuration.ThisMonth,
      label: this.$t("chartDurationEnum.THIS_MONTH").toString(),
    },
    {
      key: ChartDuration.ThisYear,
      label: this.$t("chartDurationEnum.THIS_YEAR").toString(),
    },
  ];
  exhibitionFilter: string = "";
  exhibitionFilterValues: Array<DropdownPair<string>> = [
    { key: "", label: this.$t("order.exhibitionFilter.both").toString() },
    {
      key: "true",
      label: this.$t("order.exhibitionFilter.exhibition").toString(),
    },
    {
      key: "false",
      label: this.$t("order.exhibitionFilter.noExhibition").toString(),
    },
  ];
  exhibitionAssignmentFilter: string = "false";
  exhibitionAssignmentFilterValues: Array<DropdownPair<string>> = [
    {
      key: "",
      label: this.$t("order.exhibitionAssignmentFilter.both").toString(),
    },
    {
      key: "true",
      label: this.$t("order.exhibitionAssignmentFilter.assigned").toString(),
    },
    {
      key: "false",
      label: this.$t("order.exhibitionAssignmentFilter.unassigend").toString(),
    },
  ];
  calculationFilter: string = "";
  calculationFilterValues: Array<DropdownPair<string>> = [
    { key: "", label: this.$t("order.calculationFilter.both").toString() },
    {
      key: "true",
      label: this.$t("order.calculationFilter.fullyCalculated").toString(),
    },
    {
      key: "false",
      label: this.$t("order.calculationFilter.notFullyCalculated").toString(),
    },
  ];
  unpaidAmountFilter: string = "";
  unpaidAmountFilterValues: Array<DropdownPair<string>> = [
    { key: "", label: this.$t("order.unpaidAmountFilter.both").toString() },
    {
      key: "true",
      label: this.$t("order.unpaidAmountFilter.unpaidAmount").toString(),
    },
    {
      key: "false",
      label: this.$t("order.unpaidAmountFilter.noUnpaidAmount").toString(),
    },
  ];
  cancelledFilter: string = "";
  cancelledFilterValues: Array<DropdownPair<string>> = [
    { key: "", label: this.$t("order.cancelledFilter.both").toString() },
    {
      key: "true",
      label: this.$t("order.cancelledFilter.cancelled").toString(),
    },
    {
      key: "false",
      label: this.$t("order.cancelledFilter.notCancelled").toString(),
    },
  ];
  searchText: string = "";

  sortBy: string = "";
  sortDesc: boolean = false;

  tab: number = 0;
  showFilter: boolean = false;

  mounted() {
    this.setInitialFilter();
  }

  async created() {
    this.loading = true;
    if (this.currentUser) {
      this.updateSalesmenFilter();
    }
    try {
      orderApi.getTemplates().then((response) => {
        this.templates = response.data;
        this.updateSelectedTemplateId();
      });
      await this.getOrders();
    } finally {
      this.loading = false;
    }
  }

  resetFilters() {
    this.dates = [];
    this.filterSellDate = true;
    this.salesmenIdFilter =
      this.isSalesman && !this.isAdmin ? [this.currentUser.user!.id!] : [];
    this.exhibitionSalesmenIdFilter = [];
    this.locationFilter = [];
    this.exhibitionLocationFilter = [];
    this.typeFilter = [];
    this.stateFilter = [];
    this.exhibitionFilter = "";
    this.exhibitionAssignmentFilter = "false";
    this.calculationFilter = "";
    this.unpaidAmountFilter = "";
    this.cancelledFilter = "";
    this.searchText = "";
  }

  getHeaderLabel(selectedField: string): string {
    return this.headerValues.find((header) => header.key === selectedField)?.label || "";
  }

  get isToDoView(): boolean {
    return this.detail === "todo";
  }

  get stateValues(): Array<DropdownPair<string>> {
    return [
      OrderState.Unassigned,
      OrderState.InProgress,
      OrderState.Sold,
      OrderState.InDelivery,
      OrderState.Delivered,
      OrderState.Complaint,
    ]
      .filter(
        (state: OrderState) => this.hasComplaintPackage || state !== OrderState.Complaint
      )
      .map((state: OrderState) => ({
        key: state.toString(),
        label: this.$t(`order.stateEnum.${state.toString()}`).toString(),
      }));
  }

  get templateValues(): Array<DropdownPair<string>> {
    return this.templatesWithDefault
      .filter((template) => template.tabs!.hasOwnProperty(this.currentTabKey))
      .map((template) => ({
        label: template.name!,
        key: template.id!,
      }));
  }

  get headerValues(): Array<DropdownPair<string>> {
    let toReturn: Array<DropdownPair<string>> = [];
    toReturn = [
      ...toReturn,
      { label: this.$t("order.state").toString(), key: "orderState" },
      { label: this.$t("order.type").toString(), key: "type" },
      { label: this.$t("order.orderNumber").toString(), key: "orderNumber" },
      { label: this.$t("salesman").toString(), key: "salesmanName" },
      {
        label: this.$t("clientName").toString(),
        key: "clientName",
      },
      {
        label: this.$t("email").toString(),
        key: "clientEmail",
      },
      {
        label: this.$t("location").toString(),
        key: "location",
      },
      { label: this.$t("order.sellDate").toString(), key: "sellDate" },
      { label: this.$t("order.deliveryDate").toString(), key: "deliveryDate" },
      { label: this.$t("order.deliveryWeek").toString(), key: "deliveryWeek" },
      { label: this.$t("order.assemblyDate").toString(), key: "assemblyDate" },
      {
        label: this.$t("order.secondAssemblyDate").toString(),
        key: "secondAssemblyDate",
      },
      { label: this.$t("exhibition").toString(), key: "exhibitionSale" },
      { label: this.$t("order.address").toString(), key: "address" },
      { label: this.$t("order.zipCode").toString(), key: "zipCode" },
      { label: this.$t("order.city").toString(), key: "city" },
      { label: this.$t("order.phone1").toString(), key: "clientPhone1" },
      { label: this.$t("order.phone2").toString(), key: "clientPhone2" },
      { label: this.$t("order.notes").toString(), key: "notes" },
    ];
    if (
      this.hasUserRight(UserRight.OwnOrderOverviewSellingSumGrossRead) ||
      this.hasUserRight(UserRight.OtherOrderOverviewSellingSumGrossRead)
    ) {
      toReturn.push({
        label: this.$t("order.sellingSumGross").toString(),
        key: "sellingSumGross",
      });
    }
    if (
      this.hasUserRight(UserRight.OwnOrderOverviewSellingSumRead) ||
      this.hasUserRight(UserRight.OtherOrderOverviewSellingSumRead)
    ) {
      toReturn.push({
        label: this.$t("order.sellingSum").toString(),
        key: "sellingSum",
      });
    }
    if (
      this.hasUserRight(UserRight.OwnOrderOverviewDeliverySumRead) ||
      this.hasUserRight(UserRight.OtherOrderOverviewDeliverySumRead)
    ) {
      toReturn.push({
        label: this.$t("order.deliverySum").toString(),
        key: "deliverySum",
      });
    }
    if (
      this.hasUserRight(UserRight.OwnOrderOverviewRemainingAmountRead) ||
      this.hasUserRight(UserRight.OtherOrderOverviewRemainingAmountRead)
    ) {
      toReturn.push({
        label: this.$t("order.remainingAmount").toString(),
        key: "remainingAmount",
      });
    }
    if (
      this.hasUserRight(UserRight.OwnOrderOverviewExpensesRead) ||
      this.hasUserRight(UserRight.OtherOrderOverviewExpensesRead)
    ) {
      toReturn.push({
        label: this.$t("order.expenses").toString(),
        key: "expenses",
      });
    }
    if (
      this.hasUserRight(UserRight.OwnOrderOverviewSalesmanProvisionRead) ||
      this.hasUserRight(UserRight.OtherOrderOverviewSalesmanProvisionRead)
    ) {
      toReturn.push({
        label: this.$t("order.salesmanProvision").toString(),
        key: "calculatedSalesmanProvision",
      });
    }
    toReturn.push({
      label: this.$t("order.cancelled").toString(),
      key: "cancelled",
    });
    if (this.hasComplaintPackage) {
      toReturn.push({
        label: this.$t("order.complaintState").toString(),
        key: "complaintState",
      });
    }
    if (this.hasExhibitionPackage) {
      if (
        this.hasUserRight(UserRight.OwnOrderOverviewProvisionExhibitonRead) ||
        this.hasUserRight(UserRight.OtherOrderOverviewProvisionExhibitonRead)
      ) {
        toReturn.push({
          label: this.$t("order.exhibitionProvision").toString(),
          key: "calculatedExhibitionProvision",
        });
      }
      if (
        this.hasUserRight(UserRight.OwnOrderOverviewExhibitonPriceRead) ||
        this.hasUserRight(UserRight.OtherOrderOverviewExhibitonPriceRead)
      ) {
        toReturn.push({
          label: this.$t("order.exhibitionPrice").toString(),
          key: "exhibitionPrice",
        });
      }
      toReturn = [
        ...toReturn,

        {
          label: this.$t("exhibitionSalesman").toString(),
          key: "exhibitionSalesmanName",
        },
        {
          label: this.$t("order.exhibitionSellDate").toString(),
          key: "exhibitionSellDate",
        },
        {
          label: this.$t("order.exhibitionScheduleDate").toString(),
          key: "exhibitionScheduleDate",
        },
      ];
    }
    if (
      this.hasUserRight(UserRight.OwnOrderOverviewPrePaymentsRead) ||
      this.hasUserRight(UserRight.OtherOrderOverviewPrePaymentsRead)
    ) {
      toReturn.push({
        label: this.$t("order.prePayments").toString(),
        key: "payments",
      });
    }
    toReturn.push({
      label: this.$t("order.unpaidAmount").toString(),
      key: "unpaidAmount",
    });
    if (
      this.hasUserRight(UserRight.OwnOrderOverviewExpensesRead) ||
      this.hasUserRight(UserRight.OwnOrderOverviewExpensesRead)
    ) {
      toReturn.push({
        label: this.$t("order.assemblyExpense").toString(),
        key: "assemblyExpense",
      });
    }
    toReturn.push({
      label: this.$t("order.missingFileTypes").toString(),
      key: "missingFileTypes",
    });
    if (
      this.hasUserRight(UserRight.OwnOrderSupplierDataRead) ||
      this.hasUserRight(UserRight.OtherOrderSupplierDataRead)
    ) {
      toReturn.push({
        label: this.$t("order.missingSupplierData").toString(),
        key: "missingSupplierData",
      });
    }
    toReturn.push({
      label: this.$t("carpenter").toString(),
      key: "carpenterName",
    });
    if (
      this.hasUserRight(UserRight.OwnOrderOverviewMarginRead) ||
      this.hasUserRight(UserRight.OtherOrderOverviewMarginRead)
    ) {
      toReturn.push({
        label: this.$t("order.margin").toString(),
        key: "margin",
      });
      if (
        this.hasUserRight(UserRight.OwnOrderOverviewTargetMarginRead) ||
        this.hasUserRight(UserRight.OtherOrderOverviewTargetMarginRead)
      ) {
        toReturn.push({
          label: this.$t("targetMargin").toString(),
          key: "targetMargin",
        });
        toReturn.push({
          label: this.$t("differenceTargetMargin").toString(),
          key: "differenceTargetMargin",
        });
        toReturn.push({
          label: this.$t("differenceTargetMarginPercentage").toString(),
          key: "differenceTargetMarginPercentage",
        });
      }
    }
    if (
      this.hasUserRight(UserRight.OwnOrderOverviewMarginSumRead) ||
      this.hasUserRight(UserRight.OtherOrderOverviewMarginSumRead)
    ) {
      toReturn.push({
        label: this.$t("order.marginSum").toString(),
        key: "marginSum",
      });
      if (
        this.hasUserRight(UserRight.OwnOrderOverviewTargetMarginSumRead) ||
        this.hasUserRight(UserRight.OtherOrderOverviewTargetMarginSumRead)
      ) {
        toReturn.push({
          label: this.$t("targetMarginSum").toString(),
          key: "targetMarginSum",
        });
        toReturn.push({
          label: this.$t("differenceTargetMarginSum").toString(),
          key: "differenceTargetMarginSum",
        });
        toReturn.push({
          label: this.$t("differenceTargetMarginSumPercentage").toString(),
          key: "differenceTargetMarginSumPercentage",
        });
      }
    }
    if (
      this.hasUserRight(UserRight.OwnOrderOverviewExpensesRead) ||
      this.hasUserRight(UserRight.OtherOrderOverviewExpensesRead)
    ) {
      toReturn.push({
        label: this.$t("order.percentageAssembly").toString(),
        key: "percentageAssembly",
      });
    }
    toReturn.push({
      label: this.$t("order.invoiceNumber").toString(),
      key: "invoiceNumber",
    });
    return toReturn;
  }

  get tabValues(): Array<DropdownPair<string>> {
    const tabs: string[] = [];
    if (this.hasTodoPackage && this.isToDoView) {
      tabs.push("todoEnterOrderData");
      tabs.push("todoRemainingAmount");
    } else {
      tabs.push("orders");
      if (this.hasExhibitionPackage) {
        tabs.push("exhibitionOrders");
      }
    }
    return tabs.map((tab) => ({
      key: tab,
      label: this.$t(tab).toString(),
    }));
  }

  get headers(): DataTableHeader[] {
    const nonSortable = [
      "location",
      "payments",
      "missingFileTypes",
      "missingSupplierData",
    ];
    const sumEntries: Array<DropdownPair<number>> = [
      { key: this.clientCount, label: "clientName" },
      { key: this.sellingSumGrossCount, label: "sellingSumGross" },
      { key: this.sellingSumCount, label: "sellingSum" },
      { key: this.deliverySumCount, label: "deliverySum" },
      {
        key: this.salesmanProvisionCount,
        label: "calculatedSalesmanProvision",
      },
      {
        key: this.exhibitionProvisionCount,
        label: "calculatedExhibitionProvision",
      },
      { key: this.expensesCount, label: "expenses" },
      { key: this.remainingAmountCount, label: "remainingAmount" },
      { key: this.exhibitionPriceCount, label: "exhibitionPrice" },
      { key: this.unpaidAmountCount, label: "unpaidAmount" },
      { key: this.assemblyExpenseCount, label: "assemblyExpense" },
      { key: this.marginCount, label: "margin" },
      { key: this.marginSumCount, label: "marginSum" },
      { key: this.targetMarginCount, label: "targetMargin" },
      {
        key: this.differenceTargetMarginCount,
        label: "differenceTargetMargin",
      },
      {
        key: this.differenceTargetMarginPercentageCount,
        label: "differenceTargetMarginPercentage",
      },
      { key: this.targetMarginSumCount, label: "targetMarginSum" },
      {
        key: this.differenceTargetMarginSumCount,
        label: "differenceTargetMarginSum",
      },
      {
        key: this.differenceTargetMarginSumPercentageCount,
        label: "differenceTargetMarginSumPercentage",
      },
    ];
    const selectedTemplate = this.templatesWithDefault.find(
      (template) => template.id === this.selectedTemplateId
    );
    return selectedTemplate
      ? [
          ...selectedTemplate.selectedFields!.map((fieldName) => {
            const fittingHeaderLabel = this.getHeaderLabel(fieldName);
            if (!fittingHeaderLabel) {
              return { text: "", value: "" };
            }
            let text = fittingHeaderLabel;
            const fittingSumEntry = sumEntries.find((entry) => entry.label === fieldName);
            if (fittingSumEntry && !this.$vuetify.breakpoint.xs) {
              const colorSum: boolean =
                fieldName.startsWith("margin") ||
                fieldName.startsWith("differenceTargetMargin");
              if (colorSum) {
                if (fieldName.startsWith("margin")) {
                  if (
                    (fieldName === "marginSum" &&
                      this.filteredOrders.find(
                        (entry) => entry.missingDataForMarginSum
                      )) ||
                    (fieldName === "margin" &&
                      this.filteredOrders.find((entry) => entry.missingDataForMargin))
                  ) {
                    text += "<span class='error--text'>";
                  } else {
                    text += "<span class='success--text'>";
                  }
                } else if (fittingSumEntry.key >= 0) {
                  text += "<span class='success--text'>";
                } else {
                  text += "<span class='error--text'>";
                }
              }
              text += "<br>";
              const addSign: boolean = fieldName.startsWith("differenceTargetMargin");
              if (addSign) {
                if (fittingSumEntry.key > 0) {
                  text += "+ ";
                } else if (fittingSumEntry.key < 0) {
                  text += "- ";
                }
                fittingSumEntry.key = Math.abs(fittingSumEntry.key);
              }
              if (fittingSumEntry.label.endsWith("Name")) {
                text += fittingSumEntry.key;
              } else if (fittingSumEntry.label.endsWith("Percentage")) {
                text += `${this.$options.filters!.formatCurrency(fittingSumEntry.key)} %`;
              } else {
                text += `€ ${this.$options.filters!.formatCurrency(fittingSumEntry.key)}`;
              }

              if (colorSum) {
                text += "</span>";
              }
            }
            return {
              text,
              value: fieldName,
              sortable: !nonSortable.includes(fieldName),
            };
          }),
          { text: "", align: "end", value: "actions", sortable: false },
        ]
      : [];
  }

  get toDoRemainingAmountCount(): number {
    return this.filteredToDoOrdersRemainingAmount.length
      ? this.filteredToDoOrdersRemainingAmount.filter(
          (item) => !item.doneToDoRemainingAmount
        ).length
      : 0;
  }

  get dateFilterValues(): Array<DropdownPair<boolean>> {
    return [
      {
        key: true,
        label: this.$t(
          `order.${this.isOnExhibitionTab ? "exhibitionSellDate" : "sellDate"}`
        ).toString(),
      },
      {
        key: false,
        label: this.$t(
          `order.${this.isOnExhibitionTab ? "exhibitionScheduleDate" : "deliveryDate"}`
        ).toString(),
      },
    ];
  }

  get currentTabKey(): string {
    return this.tabValues[this.tab]?.key;
  }

  get orderTableValues(): OrderOverviewDTO[] {
    switch (this.currentTabKey) {
      case "todoEnterOrderData":
        return this.filteredToDoOrdersEnterOrderData;
      case "todoRemainingAmount":
        return this.filteredToDoOrdersRemainingAmount;
      case "exhibitionOrders":
        return this.filteredExhibitionOrders;
      case "orders":
      default:
        return this.filteredOrders;
    }
  }

  get filteredOrders(): OrderOverviewDTO[] {
    let filteredOrders = this.orders;
    if (this.dates.length) {
      filteredOrders = filteredOrders.filter((order) => {
        if (this.filterSellDate) {
          if (!order.sellDate) {
            return false;
          }
          return dayjs(order.sellDate).isBetween(
            this.dates[0],
            this.dates[1],
            null,
            "[]"
          );
        }
        if (!order.deliveryDate) {
          return false;
        }
        return dayjs(order.deliveryDate).isBetween(
          this.dates[0],
          this.dates[1],
          null,
          "[]"
        );
      });
    }
    if (this.salesmenIdFilter.length) {
      filteredOrders = filteredOrders.filter((order) => {
        if (!order.salesmanId) {
          return false;
        }
        return this.salesmenIdFilter.includes(order.salesmanId);
      });
    }
    if (this.locationFilter.length) {
      filteredOrders = filteredOrders.filter((order) => {
        if (!order.location) {
          return false;
        }
        return this.locationFilter.includes(order.location);
      });
    }
    if (this.stateFilter.length) {
      filteredOrders = filteredOrders.filter((order) =>
        this.stateFilter.includes(order.orderState)
      );
    }
    if (this.typeFilter.length) {
      filteredOrders = filteredOrders.filter((order) =>
        this.typeFilter.includes(order.type)
      );
    }
    if (this.exhibitionFilter) {
      filteredOrders = filteredOrders.filter((order) =>
        this.exhibitionFilter === "true" ? order.exhibitionSale : !order.exhibitionSale
      );
    }
    if (this.calculationFilter) {
      // TODO: check for other
      filteredOrders = filteredOrders.filter((order) => {
        const hasCalculationData: boolean = this.hasUserRight(
          UserRight.OwnOrderMarginSumRead
        )
          ? !order.missingDataForMargin && !order.missingDataForMarginSum
          : !order.missingDataForMargin;
        return this.calculationFilter === "true"
          ? hasCalculationData
          : !hasCalculationData;
      });
    }
    if (this.unpaidAmountFilter) {
      const today = dayjs();
      filteredOrders = filteredOrders.filter((order) => {
        const hasUnpaidAmount: boolean = !!(
          order.unpaidAmount &&
          order.deliveryDate &&
          dayjs(order.deliveryDate).isBefore(today)
        );
        return this.unpaidAmountFilter === "true" ? hasUnpaidAmount : !hasUnpaidAmount;
      });
    }
    if (this.cancelledFilter) {
      filteredOrders = filteredOrders.filter((order) =>
        this.cancelledFilter === "true" ? order.cancelled : !order.cancelled
      );
    }
    return filteredOrders;
  }

  get filteredToDoOrdersEnterOrderData(): OrderOverviewDTO[] {
    return this.filteredOrders.filter((order) => order.toDoEnterOrderData);
  }

  get filteredToDoOrdersRemainingAmount(): OrderOverviewDTO[] {
    return this.filteredOrders.filter((order) => order.toDoRemainingAmount);
  }

  get filteredExhibitionOrders(): OrderOverviewDTO[] {
    let filteredOrders = this.exhibitionOrders;
    if (this.dates.length) {
      filteredOrders = filteredOrders.filter((order) => {
        if (this.filterSellDate) {
          if (!order.exhibitionSellDate) {
            return false;
          }
          return dayjs(order.exhibitionSellDate).isBetween(
            this.dates[0],
            this.dates[1],
            null,
            "[]"
          );
        }
        if (!order.exhibitionScheduleDate) {
          return false;
        }
        return dayjs(order.exhibitionScheduleDate).isBetween(
          this.dates[0],
          this.dates[1],
          null,
          "[]"
        );
      });
    }
    if (this.exhibitionSalesmenIdFilter.length) {
      filteredOrders = filteredOrders.filter((order) => {
        if (!order.exhibitionSalesmanId) {
          return false;
        }
        return this.exhibitionSalesmenIdFilter.includes(order.exhibitionSalesmanId);
      });
    }
    if (this.exhibitionLocationFilter.length) {
      filteredOrders = filteredOrders.filter((order) => {
        if (!order.location) {
          return false;
        }
        return this.exhibitionLocationFilter.includes(order.location);
      });
    }
    if (this.exhibitionAssignmentFilter) {
      filteredOrders = filteredOrders.filter((order) =>
        this.exhibitionAssignmentFilter === "true" ? order.salesmanId : !order.salesmanId
      );
    }
    if (this.cancelledFilter) {
      filteredOrders = filteredOrders.filter((order) =>
        this.cancelledFilter === "true" ? order.cancelled : !order.cancelled
      );
    }
    return filteredOrders;
  }

  get selectedDateSpan(): string {
    return this.dates
      .sort()
      .map((entry) => dayjs(entry).format("DD.MM.YYYY"))
      .join(" - ");
  }

  get isOnExhibitionTab(): boolean {
    return this.currentTabKey === "exhibitionOrders";
  }

  get templatesWithDefault(): OrderTemplateDTO[] {
    if (this.currentTabKey === "orders") {
      return [
        {
          id: "",
          name: this.$t("defaultTemplate").toString(),
          tabs: {
            orders: !this.templates.some((template) => template.tabs?.orders === true),
          },
          selectedFields: this.isAdmin
            ? [
                "orderState",
                "salesmanName",
                "clientName",
                "sellDate",
                "deliveryDate",
                ...(this.hasExhibitionPackage ? ["exhibitionSale"] : []),
                "sellingSumGross",
                "margin",
                "marginSum",
                "carpenterName",
              ]
            : [
                "orderState",
                "clientName",
                "sellDate",
                "deliveryDate",
                ...(this.hasExhibitionPackage ? ["exhibitionSale"] : []),
                "sellingSumGross",
                "carpenterName",
              ],
        },
        ...this.templates,
      ];
    } else if (this.currentTabKey === "exhibitionOrders") {
      return [
        {
          id: "",
          name: this.$t("defaultTemplate").toString(),
          tabs: {
            exhibitionOrders: !this.templates.some(
              (template) => template.tabs?.exhibitionOrders === true
            ),
          },
          selectedFields: [
            "exhibitionSalesmanName",
            "clientName",
            "exhibitionSellDate",
            "exhibitionScheduleDate",
            "exhibitionPrice",
            "payments",
          ],
        },
        ...this.templates,
      ];
    } else if (this.currentTabKey === "todoEnterOrderData") {
      return [
        {
          id: "",
          name: this.$t("defaultTemplate").toString(),
          tabs: {
            todoEnterOrderData: !this.templates.some(
              (template) => template.tabs?.todoEnterOrderData === true
            ),
          },
          selectedFields: [
            "orderState",
            "clientName",
            "sellDate",
            "deliveryDate",
            "missingSupplierData",
          ],
        },
        ...this.templates,
      ];
    } else if (this.currentTabKey === "todoRemainingAmount") {
      return [
        {
          id: "",
          name: this.$t("defaultTemplate").toString(),
          tabs: {
            todoRemainingAmount: !this.templates.some(
              (template) => template.tabs?.todoRemainingAmount === true
            ),
          },
          selectedFields: [
            "orderState",
            "clientName",
            "sellDate",
            "deliveryDate",
            "remainingAmount",
          ],
        },
        ...this.templates,
      ];
    }
    return [];
  }

  canDeleteOrder(order: OrderOverviewDTO): boolean {
    if (this.hasUserRight(UserRight.OtherOrderDelete)) {
      return true;
    }
    return (
      this.hasUserRight(UserRight.OwnOrderDelete) &&
      (order.salesmanId === this.currentUser.user!.id! ||
        order.exhibitionSalesmanId === this.currentUser!.user!.id!)
    );
  }

  orderTableRowClass(item: OrderOverviewDTO) {
    if (this.currentTabKey === "todoRemainingAmount" && item.doneToDoRemainingAmount) {
      return "text--disabled todo-done";
    } else if (
      this.currentTabKey === "todoEnterOrderData" &&
      item.doneToDoRemainingAmount
    ) {
      return "text--disabled todo-done";
    } else if (item.cancelled) {
      return "text--disabled red lighten-5";
    }
    return "";
  }

  // tslint:disable:variable-name
  editOrderDoubleClick(_event: any, element: any) {
    this.editOrder({
      id: element.item.id,
      isExhibitionOrder: this.isOnExhibitionTab,
      salesmanId: "",
    });
  }

  editOrder(initialOrderValues: InitialOrderValues) {
    this.editOrderValues = initialOrderValues;
    this.showOrderDialog = true;
  }

  deleteElement(id: string) {
    this.deleteOrderId = id;
    this.showDeleteDialog = true;
  }

  deleteSelectedTemplate() {
    this.deleteOrderId = "";
    this.showDeleteDialog = true;
  }

  updateOrderDatesOnDuration(selectedDuration: ChartDuration) {
    const dateSpan = this.dates;
    dateSpan[1] = dayjs().format("YYYY-MM-DD");
    switch (selectedDuration) {
      case ChartDuration.Last30Days:
        dateSpan[0] = dayjs().subtract(30, "days").format("YYYY-MM-DD");
        break;
      case ChartDuration.Last3Months:
        dateSpan[0] = dayjs().subtract(3, "months").format("YYYY-MM-DD");
        break;
      case ChartDuration.LastYear:
        dateSpan[0] = dayjs().subtract(1, "year").format("YYYY-MM-DD");
        break;
      case ChartDuration.ThisMonth:
        dateSpan[0] = dayjs().startOf("month").format("YYYY-MM-DD");
        break;
      case ChartDuration.ThisYear:
        dateSpan[0] = dayjs().startOf("year").format("YYYY-MM-DD");
        break;
    }
    this.$set(this.dates, 0, dateSpan[0]);
    this.$set(this.dates, 1, dateSpan[1]);
    this.showDateFilter = false;
  }

  updateOrderToDoRemainingAmount(id: string, done: boolean) {
    orderApi.updateOrderToDoRemainingAmount(id, done);
    const index: number = this.orders.findIndex((entry) => entry.id === id);
    this.orders[index].doneToDoRemainingAmount = done;
  }

  confirmDelete(doDelete: boolean) {
    this.showDeleteDialog = false;
    if (!doDelete) {
      return;
    }
    if (!this.deleteOrderId && this.selectedTemplateId) {
      orderApi.deleteTemplate(this.selectedTemplateId);
      this.templates.splice(
        this.templates.findIndex((template) => template.id === this.selectedTemplateId),
        1
      );
      this.updateSelectedTemplateId();
    }
    this.deleteOrder(this.deleteOrderId);
  }

  toggleExhibitionPaid(order: OrderOverviewDTO) {
    orderApi.setExhibitionOrderPaid(order.id!, order.paid || false);
  }

  get clientCount() {
    return this.orderTableValues
      .map((order) => (order.clientName ? 1 : 0))
      .reduce((a: number, b: number) => a + b, 0);
  }
  get sellingSumGrossCount() {
    return this.orderTableValues
      .map((order) => order.sellingSumGross || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get sellingSumCount() {
    return this.orderTableValues
      .map((order) => order.sellingSum || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get deliverySumCount() {
    return this.orderTableValues
      .map((order) => order.deliverySum || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get salesmanProvisionCount() {
    return this.orderTableValues
      .map((order) => order.calculatedSalesmanProvision || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get exhibitionProvisionCount() {
    return this.orderTableValues
      .map((order) => order.calculatedExhibitionProvision || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get expensesCount() {
    return this.orderTableValues
      .map((order) => order.expenses || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get remainingAmountCount() {
    return this.orderTableValues
      .map((order) => order.remainingAmount || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get exhibitionPriceCount() {
    return this.orderTableValues
      .map((order) => order.exhibitionPrice || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get marginCount() {
    return this.orderTableValues
      .map((order) => order.margin || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get marginSumCount() {
    return this.orderTableValues
      .map((order) => order.marginSum || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get unpaidAmountCount() {
    return this.orderTableValues
      .map((order) => order.unpaidAmount || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get assemblyExpenseCount() {
    return this.orderTableValues
      .map((order) => order.assemblyExpense || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get canPrintOverview(): boolean {
    return this.hasUserRight(UserRight.PrintOrderOverview);
  }
  get targetMarginCount() {
    return this.orderTableValues
      .map((order) => order.targetMargin || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get differenceTargetMarginCount() {
    return this.orderTableValues
      .map((order) => order.differenceTargetMargin || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get differenceTargetMarginPercentageCount() {
    return this.orderTableValues
      .map((order) => order.differenceTargetMarginPercentage || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get targetMarginSumCount() {
    return this.orderTableValues
      .map((order) => order.targetMarginSum || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get differenceTargetMarginSumCount() {
    return this.orderTableValues
      .map((order) => order.differenceTargetMarginSum || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  get differenceTargetMarginSumPercentageCount() {
    return this.orderTableValues
      .map((order) => order.differenceTargetMarginSumPercentage || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }

  @Watch("currentUser")
  updateSalesmenFilter() {
    this.salesmenIdFilter =
      this.isSalesman && !this.isAdmin ? [this.currentUser.user!.id!] : [];
  }

  showAddTemplateDialog() {
    this.editTemplate = { name: "", selectedFields: [], tabs: {} };
    this.currentTemplateTabs = [this.currentTabKey];
    this.templateFieldToAdd = "";
    this.showTemplateDialog = true;
  }

  showEditTemplateDialog() {
    this.editTemplate = JSON.parse(
      JSON.stringify(
        this.templates.find((template) => template.id === this.selectedTemplateId)
      )
    );
    this.currentTemplateTabs = Object.keys(this.editTemplate.tabs!);
    this.templateFieldToAdd = "";
    this.showTemplateDialog = true;
  }

  async saveTemplate() {
    const tabs: { [key: string]: boolean } = {};
    for (const tab of this.currentTemplateTabs) {
      tabs[tab] = tab === this.currentTabKey ? true : !!this.editTemplate.tabs![tab];
    }
    this.editTemplate.tabs = tabs;
    const templateId = (await orderApi.createOrUpdateTemplate(this.editTemplate)).data.id;
    await orderApi.selectTemplate({
      id: templateId,
      tab: this.currentTabKey,
    });
    this.templates = (await orderApi.getTemplates()).data;
    this.updateSelectedTemplateId();
    this.showTemplateDialog = false;
  }

  async persistSelectedTemplateId() {
    await orderApi.selectTemplate({
      id: this.selectedTemplateId,
      tab: this.currentTabKey,
    });
    this.templates = (await orderApi.getTemplates()).data;
  }

  @Watch("detail")
  switchOrderTabs() {
    this.tab = 0;
    this.updateSelectedTemplateId();
  }

  @Watch("$vuetify.breakpoint.mobile")
  setInitialFilter() {
    this.showFilter = !this.$vuetify.breakpoint.mobile;
  }

  updateSelectedTemplateId() {
    const selectedTemplate =
      this.templatesWithDefault.find((template) => template.tabs![this.currentTabKey]) ||
      this.templatesWithDefault.find((template) =>
        template.tabs!.hasOwnProperty(this.currentTabKey)
      );
    this.selectedTemplateId = selectedTemplate?.id || "";
  }

  updateTableOptions(options: DataOptions) {
    this.sortBy = options.sortBy[0];
    this.sortDesc = options.sortDesc[0];
  }

  getMissingSupplierName(order: OrderOverviewDTO, fileType: string) {
    const calculationId: string = fileType.substring(fileType.indexOf(":") + 1);
    const mapping = order.supplierMapping?.find(
      (entry) => entry.supplierCalculationId === calculationId
    );
    if (!mapping) {
      return "";
    }
    return this.suppliers.find((entry) => entry.id === mapping.supplierId)?.name || "";
  }

  async printOrders(params: PrintSelectorParams) {
    const selectedFields: string[] = this.templatesWithDefault.find(
      (template) => template.id === this.selectedTemplateId
    )!.selectedFields!;
    const headlineNames: string[] = selectedFields.map((fieldName) =>
      this.getHeaderLabel(fieldName)
    );
    let sortedOrders = this.orderTableValues;
    if (this.sortBy) {
      sortedOrders = sortedOrders.sort((a: any, b: any) => {
        if (!a[this.sortBy] || a[this.sortBy] < b[this.sortBy]) {
          return this.sortDesc ? 1 : -1;
        }
        if (!b[this.sortBy] || a[this.sortBy] > b[this.sortBy]) {
          return this.sortDesc ? -1 : 1;
        }
        return 0;
      });
    }
    const file: FileRedirectDTO = (
      await exportApi.getOrderFile(params.type!, {
        title: this.tabValues[this.tab].label,
        orders: sortedOrders,
        selectedFields,
        headlineNames,
      })
    ).data;
    openFileInNewTab(file, this.basePath);
  }
}
