<template>
  <v-text-field
    dense
    :hide-details="errors.length === 0"
    ref="input"
    v-currency="options"
    prefix="€"
    :label="label"
    :value="formattedValue"
    :readonly="readonly"
    :color="color"
    :error-messages="errors"
    @change="onChange"
    @input="onInput"
  >
    <template #prepend>
      <slot name="prepend" />
    </template>
    <template #append>
      <slot name="append" />
    </template>
    <template #append-outer>
      <slot name="append-outer" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "VCurrencyField",
  props: {
    value: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({ currency: null, locale: "de" }),
    },
    label: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formattedValue: null,
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    setValue(value) {
      this.$ci.setValue(this.$refs.input, value);
    },
    onInput(value) {
      this.$emit("input", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
    onChange(value) {
      this.$emit("change", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
  },
};
</script>
