
import Component, { mixins } from "vue-class-component";
import DeleteConfirmation from "./DeleteConfirmation.vue";
import OrderDocumentDialog from "./OrderDocumentDialog.vue";
import VCurrencyField from "./VCurrencyField.vue";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { Prop, Watch } from "vue-property-decorator";
import { DocumentDataDTO, OrderDTO, OrderPaymentType, PaymentDTO } from "./../api";
import { LocationMixin } from "./../mixins/location";
import { UserMixin } from "./../mixins/user";
dayjs.locale("de");

@Component({
  components: {
    DeleteConfirmation,
    VCurrencyField,
    OrderDocumentDialog,
  },
})
export default class OrderPayments extends mixins(LocationMixin, UserMixin) {
  showPaymentDate: boolean = false;
  showPaymentsDates: boolean[] = [];
  showPaymentDialog: boolean = false;
  showDeleteDialog: boolean = false;
  showDocumentDialog: boolean = false;
  selectedPaymentDate: boolean = false;
  paymentToEdit: PaymentDTO = { date: "", paymentType: OrderPaymentType.Bank };

  @Prop()
  order!: OrderDTO;
  @Prop()
  canEdit!: boolean;
  @Prop()
  payments!: PaymentDTO[];
  @Prop()
  allowPaidSetting!: boolean;
  @Prop()
  allowBillablePayments!: boolean;
  @Prop({ default: false })
  hideSubheader!: boolean;
  @Prop()
  finalPrice!: number;
  @Prop()
  noteRemainingPayment!: string;

  noteRemainingPaymentValue: string = "";

  deleteElement(element: PaymentDTO) {
    this.paymentToEdit = element;
    this.showDeleteDialog = true;
  }

  confirmDelete(doDelete: boolean) {
    this.showDeleteDialog = false;
    if (!doDelete) {
      return;
    }
    this.$emit("deletePayment", this.paymentToEdit);
  }

  togglePaymentType(payment: PaymentDTO) {
    payment.paymentType =
      payment.paymentType === OrderPaymentType.Cash
        ? OrderPaymentType.Bank
        : OrderPaymentType.Cash;
  }

  showNewPaymentDialog() {
    this.paymentToEdit = {
      date: this.paymentDateToUse.add(8, "days").format("YYYY-MM-DD"),
      paid: false,
      paymentType: OrderPaymentType.Bank,
    };
    this.selectedPaymentDate = false;
    this.showPaymentDialog = true;
  }

  addPayment() {
    this.showPaymentDialog = false;
    this.$emit("addPayment", JSON.parse(JSON.stringify(this.paymentToEdit)));
  }

  showEditBillablePaymentDialog(payment: PaymentDTO) {
    this.paymentToEdit = JSON.parse(JSON.stringify(payment));
    this.showDocumentDialog = true;
  }

  canEditPaymentData(payment: PaymentDTO): boolean {
    if (!this.canEdit) {
      return false;
    }
    if (this.allowPaidSetting) {
      return true;
    }
    return !payment.paid;
  }

  showNewBillablePaymentDialog() {
    this.paymentToEdit = {
      date: dayjs().format("YYYY-MM-DD"),
      paid: false,
      paymentType: OrderPaymentType.Bank,
      documentData: {
        salesmanId: this.order.salesmanId,
        locationId: this.order.location,
        date: dayjs().format("YYYY-MM-DD"),
        productGroups: [{ supplierId: "", products: [] }],
        settings: {},
        payments: [],
      },
    };
    this.showDocumentDialog = true;
  }

  updateDocumentData(
    confirmed: boolean,
    documentData: DocumentDataDTO,
    documentType: DocumentType,
    totalAmountGross: number,
    callback?: () => void
  ) {
    if (!confirmed) {
      this.showDocumentDialog = false;
      return;
    }
    const paymentIndex = this.order.payments!.findIndex(
      (entry: PaymentDTO) => entry.id === this.paymentToEdit.id
    );
    const paymentCopy: PaymentDTO = JSON.parse(JSON.stringify(this.paymentToEdit));
    if (paymentIndex >= 0) {
      this.$emit("updatePayment", paymentIndex, paymentCopy, callback);
    } else {
      this.$emit("addPayment", paymentCopy, callback);
    }
  }

  @Watch("payments")
  updateShowPaymentsArray() {
    this.showPaymentsDates = this.payments.map(() => false);
  }

  @Watch("noteRemainingPayment", { immediate: true })
  updateNoteRemainingPayment() {
    this.noteRemainingPaymentValue = this.noteRemainingPayment;
  }

  @Watch("noteRemainingPaymentValue")
  emitNoteChange() {
    this.$emit("changeNoteRemainingPayment", this.noteRemainingPaymentValue);
  }

  get paymentDateToUse(): dayjs.Dayjs {
    let dateToUse = dayjs();
    if (!this.payments?.length) {
      if (this.order.exhibitionSale && this.order.exhibitionSellDate) {
        dateToUse = dayjs(this.order.exhibitionSellDate);
      } else if (this.order.sellDate) {
        dateToUse = dayjs(this.order.sellDate);
      }
    }
    return dateToUse;
  }

  get remainingPaymentAmount() {
    return (
      this.finalPrice -
      this.payments.map((entry) => entry.price || 0).reduce((a, b) => a + b, 0)
    );
  }
}
