
import Component, { mixins } from "vue-class-component";
import Navigation from "../components/Navigation.vue";
import ResetFiltersButton from "../components/ResetFiltersButton.vue";
import PrintSelector, {
  PrintSelectorParams,
} from "../components/PrintSelector.vue";
import VCurrencyField from "../components/VCurrencyField.vue";
import {
  BilledOrderOverviewDTO,
  CarpenterDTO,
  FileRedirectDTO,
  OrderBillType,
  OrderPaymentType,
  PaymentDTO,
  SmallOrderDTO,
  UserRight,
} from "../api";
import DeleteConfirmation from "./../components/DeleteConfirmation.vue";
import { exportApi, orderApi, RootActions, RootGetters } from "../store";
import { Action, Getter } from "vuex-class";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import OrderDetail, {
  InitialOrderValues,
} from "./../components/OrderDetail.vue";
import { UserMixin } from "./../mixins/user";
import { LocationMixin } from "./../mixins/location";
import { ValidationProvider } from "vee-validate";
import { openFileInNewTab } from "./../helpers";
dayjs.locale("de");

@Component({
  components: {
    Navigation,
    ResetFiltersButton,
    PrintSelector,
    VCurrencyField,
    OrderDetail,
    DeleteConfirmation,
  },
})
export default class SalesStatistics extends mixins(UserMixin, LocationMixin) {
  $refs!: {
    validationObserver: InstanceType<typeof ValidationProvider>;
  };

  @Getter(RootGetters.getBasePath)
  basePath!: string;
  @Getter(RootGetters.getTableFooterProps)
  dataTableFooterProps: any;
  @Getter(RootGetters.getCarpenters)
  carpenterValues!: CarpenterDTO[];

  @Action(RootActions.lockOrders)
  lockOrders!: (date: string) => void;
  @Action(RootActions.unlockOrders)
  unlockOrders!: (date: string) => void;

  currentLocations: string[] = [];
  loading: boolean = true;
  billedOrders: BilledOrderOverviewDTO[] = [];
  showOrderDialog: boolean = false;
  editOrderValues: InitialOrderValues = {
    id: "",
    salesmanId: "",
    isExhibitionOrder: false,
  };

  showMonthPicker: boolean = false;
  selectedMonth: string = "";
  showSmallOrderDialog: boolean = false;
  showSmallOrderDate: boolean = false;
  editSmallOrder: SmallOrderDTO = {};
  showDeleteDialog: boolean = false;
  deleteType: string = "";
  priceToDelete: PaymentDTO = { date: "", paymentType: OrderPaymentType.Bank };
  showPaymentsDates: boolean[] = [];
  savingSmallOrder: boolean = false;
  smallOrderDeleteId: string = "";

  showLockDatePicker: boolean = false;
  orderLockDate: string = "";
  showLockDialog: boolean = false;
  isLockingOrders: boolean = true;

  finalPriceCount: number = 0;
  marginCount: number = 0;
  marginSumCount: number = 0;

  itemsPerPage: number = 25;

  created() {
    this.selectedMonth = dayjs().startOf("month").format("YYYY-MM-DD");
    this.updateStatistics();
  }

  resetFilters() {
    this.currentLocations = [];
  }

  get formattedMonth(): string {
    return dayjs(this.selectedMonth).format("MMMM YYYY");
  }

  get dateFrom(): string {
    if (this.isLockingOrders) {
      const invoiceableOrders = this.billedOrders.filter(
        (entry) => !entry.invoiceNumber
      );
      if (invoiceableOrders.length) {
        return dayjs(invoiceableOrders[0].date).format("YYYY-MM-DD");
      }
    }
    return dayjs(this.selectedMonth).startOf("month").format("YYYY-MM-DD");
  }

  get unlockedDateFrom(): string {
    const invoicedOrders = this.billedOrders.filter(
      (entry) => entry.invoiceNumber
    );
    if (invoicedOrders.length) {
      return dayjs(invoicedOrders[invoicedOrders.length - 1].date).format(
        "YYYY-MM-DD"
      );
    }
    return dayjs(this.selectedMonth).startOf("month").format("YYYY-MM-DD");
  }

  get dateTo(): string {
    return dayjs(this.selectedMonth).endOf("month").format("YYYY-MM-DD");
  }

  get canLockOrders(): boolean {
    return this.billedOrders.some((entry) => !entry.invoiceNumber);
  }

  get canUnlockOrders(): boolean {
    return this.billedOrders.some((entry) => entry.invoiceNumber);
  }

  get headers(): DataTableHeader[] {
    const missingMargin: boolean = this.billedOrders.some(
      (item) => item.missingDataForMargin
    );
    const missingMarginSum: boolean = this.billedOrders.some(
      (item) => item.missingDataForMarginSum
    );

    let headers: DataTableHeader[] = [
      {
        text: this.$t("order.invoiceNumber").toString(),
        value: "invoiceNumber",
      },
      { text: this.$t("date").toString(), value: "date" },
      { text: this.$t("order.billType").toString(), value: "billType" },
      { text: this.$t("salesman").toString(), value: "salesmanName" },
      { text: this.$t("clientName").toString(), value: "clientName" },
      { text: this.$t("carpenter").toString(), value: "carpenterName" },
      {
        text: this.$t("order.prePayments").toString(),
        value: "payments",
        sortable: false,
      },
      {
        text:
          this.$t("weekOverview.sum").toString() +
          (!this.$vuetify.breakpoint.xs
            ? `<br>€ ${this.$options.filters!.formatCurrency(
                this.finalPriceCount
              )}`
            : ""),
        value: "finalPrice",
      },
      { text: this.$t("exhibition").toString(), value: "exhibitionSale" },
    ];
    if (this.isAdmin) {
      if (this.$vuetify.breakpoint.xs) {
        headers = headers.concat(
          {
            text: this.$t("order.margin").toString(),
            value: "margin",
          },
          {
            text: this.$t("order.marginSum").toString(),
            value: "marginSum",
          }
        );
      } else {
        headers = headers.concat(
          {
            text: `${this.$t("order.margin")}<br><span class='${
              missingMargin ? "error" : "success"
            }--text'>€ ${this.$options.filters!.formatCurrency(
              this.marginCount
            )}</span>`,
            value: "margin",
          },
          {
            text: `${this.$t("order.marginSum")}<br><span class='${
              missingMarginSum ? "error" : "success"
            }--text'>€ ${this.$options.filters!.formatCurrency(
              this.marginSumCount
            )}</span>`,
            value: "marginSum",
          }
        );
      }
    }
    headers.push({
      text: "",
      align: "end",
      value: "actions",
      sortable: false,
    });
    return headers;
  }

  get editSum(): number {
    return this.editSmallOrder.payments != null
      ? this.editSmallOrder.payments
          .filter((entry) => entry.paid)
          .map((entry) => +(entry.price || 0))
          .reduce((a, b) => a + b, 0)
      : 0;
  }

  getRemainingPaymentForItem(item: BilledOrderOverviewDTO) {
    const paymentSum =
      item.payments != null
        ? item.payments
            .map((entry) => +(entry.price || 0))
            .reduce((a, b) => a + b, 0)
        : 0;
    return (item.finalPrice || 0) - paymentSum;
  }

  // tslint:disable:variable-name
  editOrderDoubleClick(_event: any, element: any) {
    this.editOrder(element.item);
  }

  editOrder(order: BilledOrderOverviewDTO) {
    if (order.billType === OrderBillType.SmallOrder) {
      this.editSmallOrder = JSON.parse(JSON.stringify(order));
      this.showSmallOrderDialog = true;
      this.$refs.validationObserver.reset();
    } else {
      this.editOrderValues = {
        id: order.orderId || order.id!,
        salesmanId: "",
        isExhibitionOrder: false,
      };
      this.showOrderDialog = true;
    }
  }

  updateOrderCounts(orders: BilledOrderOverviewDTO[]) {
    this.finalPriceCount = orders
      .map((order) => order.finalPrice || 0)
      .reduce((a: number, b: number) => a + b, 0);
    this.marginCount = orders
      .map((order) => order.margin || 0)
      .reduce((a: number, b: number) => a + b, 0);
    this.marginSumCount = orders
      .map((order) => order.marginSum || 0)
      .reduce((a: number, b: number) => a + b, 0);
  }

  addSmallOrder() {
    const minimumDate = dayjs(this.unlockedDateFrom);
    const now = dayjs();
    this.editSmallOrder = {
      date: (now.month() === minimumDate.month() &&
      now.year() === minimumDate.year() &&
      minimumDate.isBefore(now)
        ? now
        : minimumDate
      ).format("YYYY-MM-DD"),
      payments: [],
    };
    this.showSmallOrderDialog = true;
    this.$refs.validationObserver.reset();
  }

  updateSmallOrderLocation() {
    if (this.editSmallOrder.location) {
      return;
    }
    const fittingSalesman = this.users.find(
      (entry) => entry.id === this.editSmallOrder.salesmanId
    );
    if (!fittingSalesman || !fittingSalesman.location) {
      return;
    }
    this.editSmallOrder.location = fittingSalesman.location as any;
  }

  deletePrice(price: PaymentDTO) {
    this.priceToDelete = price;
    this.deleteType = "editPayment";
    this.showDeleteDialog = true;
  }

  async confirmDelete(doDelete: boolean) {
    this.showDeleteDialog = false;
    if (!doDelete) {
      return;
    }
    if (this.deleteType === "editPayment") {
      this.editSmallOrder.payments!.splice(
        this.editSmallOrder.payments!.findIndex(
          (entry) => entry === this.priceToDelete
        ),
        1
      );
    } else if (this.deleteType === "smallOrder") {
      await orderApi.deleteSmallOrder(this.smallOrderDeleteId);
      this.updateStatistics();
    }
  }

  addPayment() {
    if (this.editSmallOrder.payments == null) {
      this.editSmallOrder.payments = [];
    }
    this.editSmallOrder.payments.push({
      date: dayjs().format("YYYY-MM-DD"),
      price: 0,
      paid: false,
      paymentType: OrderPaymentType.Bank,
    });
  }

  async saveSmallOrder() {
    try {
      this.savingSmallOrder = true;
      if (!(await this.$refs.validationObserver.validate())) {
        return;
      }
      await orderApi.createOrUpdateSmallOrder(this.editSmallOrder);
      this.showSmallOrderDialog = false;
      this.updateStatistics();
    } finally {
      this.savingSmallOrder = false;
    }
  }

  deleteSmallOrder(id: string) {
    this.smallOrderDeleteId = id;
    this.deleteType = "smallOrder";
    this.showDeleteDialog = true;
  }

  updateTableOptions(options: any) {
    this.itemsPerPage = options.itemsPerPage;
  }

  get canEdit(): boolean {
    return this.hasUserRight(UserRight.SalesStatisticsWrite);
  }

  async lockOrUnlockOrdersAndReload() {
    this.showLockDialog = false;
    try {
      this.loading = true;
      if (this.isLockingOrders) {
        await this.lockOrders(this.orderLockDate);
      } else {
        await this.unlockOrders(this.orderLockDate);
      }
    } finally {
      this.loading = false;
    }
    this.updateStatistics();
  }

  async prepareSalesFile(params: PrintSelectorParams) {
    const file: FileRedirectDTO = (
      await exportApi.getBilledOrderFile(params.type!, {
        title: this.$t("salesStatistics.salesStatistics").toString(),
        billedOrders: this.billedOrders,
        headlineNames: this.headers
          .map((entry) => {
            const lineBreakIndex = entry.text.indexOf("<br>");
            return lineBreakIndex >= 0
              ? entry.text.substr(0, lineBreakIndex)
              : entry.text;
          })
          .filter((entry) => entry.length),
      })
    ).data;
    openFileInNewTab(file, this.basePath);
  }

  @Watch("editSmallOrder.payments")
  updateShowPaymentsArray() {
    this.showPaymentsDates =
      this.editSmallOrder.payments != null
        ? this.editSmallOrder.payments.map(() => false)
        : [];
  }

  @Watch("isLockingOrders")
  updateInitialOrderLockDate() {
    this.orderLockDate = this.isLockingOrders ? this.dateTo : this.dateFrom;
  }

  @Watch("currentLocations")
  @Watch("selectedMonth")
  @Watch("isAdmin")
  async updateStatistics() {
    this.showOrderDialog = false;
    try {
      this.loading = true;
      this.billedOrders = (
        await orderApi.getBilledOrders(
          dayjs(this.selectedMonth).startOf("month").format("YYYY-MM-DD"),
          this.dateTo,
          this.currentLocations
        )
      ).data;
      this.isLockingOrders = true;
      this.updateInitialOrderLockDate();
    } finally {
      this.loading = false;
    }
  }
}
