
import Component, { mixins } from "vue-class-component";
import Navigation from "../components/Navigation.vue";
import ResetFiltersButton from "../components/ResetFiltersButton.vue";
import VCurrencyField from "../components/VCurrencyField.vue";
import {
  FileRedirectDTO,
  FullPaymentPutDTO,
  FuturePaymentDTO,
  OpenPaymentDTO,
  OrderPaymentType,
  SalesmanSaleDTO,
  UserRight,
  WeekOverviewDTO,
} from "../api";
import { exportApi, orderApi, RootGetters } from "../store";
import { Getter } from "vuex-class";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { Watch } from "vue-property-decorator";
import OrderDetail, {
  InitialOrderValues,
} from "./../components/OrderDetail.vue";
import { LocationMixin } from "./../mixins/location";
import PrintSelector, {
  PrintSelectorParams,
} from "./../components/PrintSelector.vue";
import { UserMixin } from "./../mixins/user";
import { openFileInNewTab } from "./../helpers";
dayjs.locale("de");

@Component({
  components: {
    Navigation,
    ResetFiltersButton,
    VCurrencyField,
    OrderDetail,
    PrintSelector,
  },
})
export default class WeekOverview extends mixins(UserMixin, LocationMixin) {
  @Getter(RootGetters.getBasePath)
  basePath!: string;

  currentLocations: string[] = [];
  salesmenIdFilter: string[] = [];

  loading: boolean = true;
  fullPayment: FullPaymentPutDTO = {
    paymentType: OrderPaymentType.Bank,
  };
  currentPayment?: FuturePaymentDTO;
  weekOverview: WeekOverviewDTO = {};
  firstBankBalance: number = 0;
  secondBankBalance: number = 0;

  showPaymentDialog: boolean = false;
  showPaymentDate: boolean = false;
  selectedPayment: OpenPaymentDTO | null = null;

  editOrderValues: InitialOrderValues = {
    id: "",
    salesmanId: "",
    isExhibitionOrder: false,
  };
  showOrderDialog: boolean = false;

  created() {
    this.updateWeekOverview();
  }

  resetFilters() {
    this.currentLocations = [];
    this.salesmenIdFilter = [];
  }

  getSalesSum(location: string) {
    return this.groupedSales
      .get(location)!
      .map((entry) => entry.amount || 0)
      .reduce((a, b) => a + b, 0);
  }

  getOrderSum(location: string, type: string) {
    return (type === "sales" ? this.groupedSales : this.groupedDeliveries)
      .get(location)!
      .map((entry) => entry.orderCount || 0)
      .reduce((a, b) => a + b, 0);
  }

  getExhibitionSum(location: string, type: string) {
    return (type === "sales" ? this.groupedSales : this.groupedDeliveries)
      .get(location)!
      .map((entry) => entry.exhibitionCount || 0)
      .reduce((a, b) => a + b, 0);
  }

  getDeliverySum(location: string) {
    return this.groupedDeliveries
      .get(location)!
      .map((entry) => entry.amount || 0)
      .reduce((a, b) => a + b, 0);
  }

  saveBankBalances() {
    exportApi.updateBankBalances([
      this.firstBankBalance,
      this.secondBankBalance,
    ]);
    this.weekOverview.bankBalances = [
      this.firstBankBalance,
      this.secondBankBalance,
    ];
  }

  showOpenPaymentDialog(payment: OpenPaymentDTO) {
    if (!this.canEdit) {
      return;
    }
    this.selectedPayment = payment;
    this.fullPayment = {
      amount: payment.price,
      date: payment.date,
      paymentType: payment.paymentType || OrderPaymentType.Bank,
    };
    this.showPaymentDialog = true;
  }

  addPayment(payment: FuturePaymentDTO) {
    if (!this.canEdit) {
      return;
    }
    this.currentPayment = payment;
    this.fullPayment = {
      date: dayjs().format("YYYY-MM-DD"),
      paymentType: OrderPaymentType.Bank,
      amount: payment.remainingAmount,
    };
    this.selectedPayment = null;
    this.showPaymentDialog = true;
  }

  // tslint:disable:variable-name
  editOrderDoubleClick(element: FuturePaymentDTO, isExhibitionOrder: boolean) {
    this.editOrderValues = {
      id: element.orderId!,
      isExhibitionOrder,
      salesmanId: "",
    };
    this.showOrderDialog = true;
  }

  async equalizeRemainingAmount() {
    this.showPaymentDialog = false;
    if (this.selectedPayment != null) {
      orderApi.setOrderPaymentPaid(
        this.selectedPayment.orderId!,
        this.selectedPayment.id!,
        this.fullPayment
      );
      let index: number = this.weekOverview.openPayments!.findIndex(
        (entry) => entry === this.selectedPayment
      );
      if (index >= 0) {
        this.weekOverview.openPayments?.splice(index, 1);
      } else {
        index = this.weekOverview.openExhibitionPayments!.findIndex(
          (entry) => entry === this.selectedPayment
        );
        this.weekOverview.openExhibitionPayments?.splice(index, 1);
      }
      this.selectedPayment = null;
      return;
    }
    await orderApi.payRemainingAmount(
      this.currentPayment!.orderId!,
      this.fullPayment
    );
    if (this.fullPayment.amount === this.currentPayment!.remainingAmount) {
      for (
        let i = this.weekOverview.unpaidPastOrders!.length - 1;
        i >= 0;
        --i
      ) {
        if (
          this.weekOverview.unpaidPastOrders![i].orderId ===
          this.currentPayment!.orderId
        ) {
          this.weekOverview.unpaidPastOrders!.splice(i, 1);
        }
      }
      for (let i = this.weekOverview.futurePayments!.length - 1; i >= 0; --i) {
        if (
          this.weekOverview.futurePayments![i].orderId ===
          this.currentPayment!.orderId
        ) {
          this.weekOverview.futurePayments![i].paidAt = this.fullPayment.date;
          this.weekOverview.futurePayments![i].paymentType =
            this.fullPayment.paymentType;
          this.weekOverview.futurePayments![i].remainingAmount = 0;
          this.weekOverview.futurePayments![i].paidAmount =
            this.fullPayment.amount;
        }
      }
    } else {
      this.updateWeekOverview();
    }
  }

  async prepareWeekFile(params: PrintSelectorParams) {
    const file: FileRedirectDTO = (
      await exportApi.getWeekOverviewFile(
        params.type!,
        this.startDate.format("YYYY-MM-DD"),
        this.currentLocations
      )
    ).data;
    openFileInNewTab(file, this.basePath);
  }

  get differentBankBalances() {
    if (!this.weekOverview.bankBalances) {
      return true;
    }
    if (this.weekOverview.bankBalances.length < 2) {
      return true;
    }
    if (this.firstBankBalance !== this.weekOverview.bankBalances[0]) {
      return true;
    }
    if (this.secondBankBalance !== this.weekOverview.bankBalances[1]) {
      return true;
    }
    return false;
  }

  get selectedLocationsText(): string {
    return this.currentLocations
      .map((entry) => this.getLocationName(entry))
      .join(", ");
  }

  get startDate(): dayjs.Dayjs {
    return dayjs().startOf("week");
  }

  get endDate(): dayjs.Dayjs {
    return dayjs().endOf("week");
  }

  get currentDate(): dayjs.Dayjs {
    return dayjs();
  }

  get monthName(): string {
    return this.startDate.get("month") === this.endDate.get("month")
      ? this.startDate.format("MMMM")
      : `${this.startDate.format("MMMM")}, ${this.endDate.format("MMMM")}`;
  }

  get filteredSalesmanSales(): SalesmanSaleDTO[] {
    return (
      this.weekOverview.salesmanSales?.filter(
        (sale) =>
          this.salesmenIdFilter.length === 0 ||
          this.salesmenIdFilter.includes(sale.salesmanId!)
      ) || []
    );
  }

  get filteredSalesmanDeliveries(): SalesmanSaleDTO[] {
    return (
      this.weekOverview.salesmanDeliveries?.filter(
        (sale) =>
          this.salesmenIdFilter.length === 0 ||
          this.salesmenIdFilter.includes(sale.salesmanId!)
      ) || []
    );
  }

  get filteredOpenPayments(): OpenPaymentDTO[] {
    return (
      this.weekOverview.openPayments?.filter(
        (payment) =>
          this.salesmenIdFilter.length === 0 ||
          this.salesmenIdFilter.includes(payment.salesmanId!)
      ) || []
    );
  }

  get filteredOpenExhibitionPayments(): OpenPaymentDTO[] {
    return (
      this.weekOverview.openExhibitionPayments?.filter(
        (payment) =>
          this.salesmenIdFilter.length === 0 ||
          this.salesmenIdFilter.includes(payment.salesmanId!)
      ) || []
    );
  }

  get filteredFuturePayments(): FuturePaymentDTO[] {
    return (
      this.weekOverview.futurePayments?.filter(
        (payment) =>
          this.salesmenIdFilter.length === 0 ||
          this.salesmenIdFilter.includes(payment.salesmanId!)
      ) || []
    );
  }

  get filteredUnpaidPastOrders(): FuturePaymentDTO[] {
    return (
      this.weekOverview.unpaidPastOrders?.filter(
        (payment) =>
          this.salesmenIdFilter.length === 0 ||
          this.salesmenIdFilter.includes(payment.salesmanId!)
      ) || []
    );
  }

  get openPaymentsSum(): number {
    return (
      this.filteredOpenPayments
        .map((entry) => entry.price || 0)
        .reduce((a, b) => a + b, 0) +
      this.filteredOpenExhibitionPayments
        .map((entry) => entry.price || 0)
        .reduce((a, b) => a + b, 0)
    );
  }

  get futurePaymentsSum(): number {
    return this.filteredFuturePayments
      .map((entry) => entry.remainingAmount || 0)
      .reduce((a, b) => a + b, 0);
  }

  get unpaidPaymentsSum(): number {
    return this.filteredUnpaidPastOrders
      .map((entry) => entry.remainingAmount || 0)
      .reduce((a, b) => a + b, 0);
  }

  get groupedSales(): Map<string, SalesmanSaleDTO[]> {
    const toReturn: Map<string, SalesmanSaleDTO[]> = new Map();
    this.filteredSalesmanSales.forEach((sale) => {
      if (toReturn.has(sale.location!)) {
        toReturn.get(sale.location!)!.push(sale);
      } else {
        toReturn.set(sale.location!, [sale]);
      }
    });
    return toReturn;
  }

  get groupedDeliveries(): Map<string, SalesmanSaleDTO[]> {
    const toReturn: Map<string, SalesmanSaleDTO[]> = new Map();
    this.filteredSalesmanDeliveries.forEach((sale) => {
      if (toReturn.has(sale.location!)) {
        toReturn.get(sale.location!)!.push(sale);
      } else {
        toReturn.set(sale.location!, [sale]);
      }
    });
    return toReturn;
  }

  get saleSum(): number {
    return this.filteredSalesmanSales
      .map((entry) => entry.amount || 0)
      .reduce((a, b) => a + b, 0);
  }

  get saleOrderSum(): number {
    return this.filteredSalesmanSales
      .map((entry) => entry.orderCount || 0)
      .reduce((a, b) => a + b, 0);
  }

  get saleExhibitionSum(): number {
    return this.filteredSalesmanSales
      .map((entry) => entry.exhibitionCount || 0)
      .reduce((a, b) => a + b, 0);
  }

  get deliverySum(): number {
    return this.filteredSalesmanDeliveries
      .map((entry) => entry.amount || 0)
      .reduce((a, b) => a + b, 0);
  }

  get deliveryOrderSum(): number {
    return this.filteredSalesmanDeliveries
      .map((entry) => entry.orderCount || 0)
      .reduce((a, b) => a + b, 0);
  }

  get deliveryExhibitionSum(): number {
    return this.filteredSalesmanDeliveries
      .map((entry) => entry.exhibitionCount || 0)
      .reduce((a, b) => a + b, 0);
  }

  get canEdit(): boolean {
    return this.hasUserRight(UserRight.WeekOverviewWrite);
  }

  get showBankBalances(): boolean {
    return this.currentUser.userGroupName === "dan";
  }

  @Watch("currentLocations")
  async updateWeekOverview() {
    try {
      this.loading = true;
      this.weekOverview = (
        await exportApi.getWeekOverview(
          this.startDate.format("YYYY-MM-DD"),
          this.currentLocations
        )
      ).data;

      if (this.weekOverview.bankBalances!.length > 1) {
        this.firstBankBalance = this.weekOverview.bankBalances![0];
        this.secondBankBalance = this.weekOverview.bankBalances![1];
      }
    } finally {
      this.loading = false;
    }
  }
}
