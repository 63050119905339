
import {
  ClientData,
  ClientSalutation,
  DiscountSelection,
  DocumentDataDTO,
  DocumentType,
  FileRedirectDTO,
  OrderDTO,
  PaymentDTO,
  ProductGroup,
  ProductLine,
  SupplierDTO,
} from "../api";
import { LocationMixin } from "../mixins/location";
import { UserMixin } from "../mixins/user";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { openFileInNewTab, scrollToFirstValidationError } from "../helpers";
import VCurrencyField from "./VCurrencyField.vue";
import { DropdownPair } from "@/interfaces";
import { Action, Getter } from "vuex-class";
import { exportApi, RootGetters, RootActions } from "@/store";
import DeleteConfirmation from "./DeleteConfirmation.vue";
import CloseConfirmation from "./CloseConfirmation.vue";
import { ObjectID } from "bson";
import dayjs from "dayjs";
import { DocumentDataSettingDTO } from "../api";

@Component({
  name: "OrderDocumentDialog",
  components: {
    VCurrencyField,
    DeleteConfirmation,
    CloseConfirmation,
    OrderPayments: () => import("./OrderPayments.vue"),
  },
})
export default class OrderDocumentDialog extends mixins(
  LocationMixin,
  UserMixin
) {
  $refs!: {
    validationObserver: InstanceType<typeof ValidationProvider>;
  };
  @Prop({ default: false })
  dialog!: boolean;
  @Prop({ default: false })
  isPayment!: boolean;
  @Prop()
  paymentToEdit?: PaymentDTO;
  @Prop()
  orderDocumentData!: { [key: string]: DocumentDataDTO };
  @Prop()
  order!: OrderDTO;

  @Getter(RootGetters.getBasePath)
  basePath!: string;
  @Getter(RootGetters.getSuppliers)
  supplierValues!: SupplierDTO[];
  @Getter(RootGetters.getDefaultDocumentDataSettings)
  defaultDocumentDataSettings!: { [key: string]: DocumentDataSettingDTO };

  @Action(RootActions.createOrUpdateDefaultDocumentDataSetting)
  createOrUpdateDefaultDocumentDataSetting!: (payload: {
    type: DocumentType;
    setting: DocumentDataSettingDTO;
  }) => void;

  documentData: DocumentDataDTO = {
    settings: {},
    payments: [],
    date: "",
  };

  salutationValues: Array<DropdownPair<string>> = Object.keys(
    ClientSalutation
  ).map((entry: string) => ({
    key: (ClientSalutation as any)[entry],
    label: this.$t(
      `salutationEnum.${(ClientSalutation as any)[entry]}`
    ).toString(),
  }));

  tab = 0;
  showDates: boolean[] = [false, false, false, false];
  supplierToAdd: string = "";
  showDeleteDialog: boolean = false;
  elementToDelete?: ProductGroup;
  initialDocumentData: string = "";
  expansionPanelStates: number[] = [0];

  showCloseDialog: boolean = false;
  showTakeDataDialog: boolean = false;
  documentTypeToCopyTo: string = "";

  addProduct(productGroupIndex: number) {
    this.documentData.productGroups![productGroupIndex].products!.push({});
  }

  getProductSum(productGroupIndex: number, productIndex: number): number {
    const product: ProductLine =
      this.documentData.productGroups![productGroupIndex].products![
        productIndex
      ];
    return (product.amount || 0) * (product.pricePerUnit || 0);
  }

  removeProduct(productGroupIndex: number, productIndex: number) {
    this.documentData.productGroups![productGroupIndex].products!.splice(
      productIndex,
      1
    );
  }

  getSupplierName(productGroup: ProductGroup): string {
    const fittingSupplier = this.supplierValues.find(
      (entry) => entry.id === productGroup.supplierId
    );
    if (!fittingSupplier) {
      return "";
    }
    return fittingSupplier.name;
  }

  addCurrentSupplier() {
    if (!this.supplierToAdd) {
      return;
    } else if (this.supplierToAdd === "from-order") {
      this.order.suppliers.forEach((supplierEntry) => {
        if (
          !this.documentData.productGroups?.find(
            (entry) => entry.supplierId === supplierEntry.supplierId
          )
        ) {
          this.documentData.productGroups?.push({
            supplierId: supplierEntry.supplierId,
            products: [],
          });
        }
      });
    } else {
      this.documentData.productGroups?.push({
        supplierId: this.supplierToAdd,
        products: [],
      });
    }
    this.$nextTick(() => (this.supplierToAdd = ""));
  }

  async updateDocumentData(
    confirmed: boolean,
    print?: boolean
  ): Promise<boolean> {
    if (confirmed && !(await this.$refs.validationObserver.validate())) {
      this.$nextTick(() => scrollToFirstValidationError());
      return false;
    }
    if (confirmed && this.paymentToEdit) {
      this.paymentToEdit.price = this.totalAmountGross;
      this.paymentToEdit.date = this.documentData.date;
    }
    if (this.documentData.settings.usePaymentsForOrder) {
      this.documentData.payments.forEach((payment) => {
        if (!payment.id) {
          payment.id = new ObjectID().toString();
        }
      });
    }
    this.initialDocumentData = JSON.stringify(this.documentData);
    this.$emit(
      "updateDocumentData",
      confirmed,
      this.documentData,
      this.currentDocumentType,
      this.totalAmountGross,
      () => {
        if (print) {
          this.printDocument();
        }
      }
    );
    return true;
  }

  confirmTakeData(confirm: boolean) {
    if (confirm) {
      const copiedDocumentData: DocumentDataDTO = JSON.parse(
        JSON.stringify(this.documentData)
      );
      if (!this.orderDocumentData[this.documentTypeToCopyTo]) {
        this.orderDocumentData[this.documentTypeToCopyTo] = {
          settings: {
            ...this.defaultDocumentDataSettings[this.documentTypeToCopyTo],
          },
          payments: [],
          date: dayjs().format("YYYY-MM-DD"),
          locationId: this.order.location,
          salesmanId: this.order.salesmanId,
        };
      }
      this.$set(
        this.orderDocumentData[this.documentTypeToCopyTo],
        "clientData",
        copiedDocumentData.clientData
      );
      this.$set(
        this.orderDocumentData[this.documentTypeToCopyTo],
        "productGroups",
        copiedDocumentData.productGroups
      );
      this.$set(
        this.orderDocumentData[this.documentTypeToCopyTo],
        "payments",
        copiedDocumentData.payments
      );
      this.tab = this.tabValues.findIndex(
        (element) => element.key.toString() === this.documentTypeToCopyTo
      );
      this.updateDocumentData(true);
    }
    this.showTakeDataDialog = false;
  }

  showDeleteProductGroupDialog(productGroup: ProductGroup) {
    this.elementToDelete = productGroup;
    this.showDeleteDialog = true;
  }

  confirmDelete(doDelete: boolean) {
    this.showDeleteDialog = false;
    if (!doDelete || !this.elementToDelete) {
      return;
    }
    this.documentData.productGroups!.splice(
      this.documentData.productGroups!.findIndex(
        (entry) => entry === this.elementToDelete
      ),
      1
    );
  }

  getDiscountValue(productGroup: ProductGroup): number {
    if (!productGroup.products?.length || !productGroup.hasDiscountData) {
      return 0;
    }
    if (productGroup.discountSelection === DiscountSelection.Amount) {
      return productGroup.discountAmount || 0;
    }
    const productSum = productGroup.products
      .map((entry) => (entry.amount || 0) * (entry.pricePerUnit || 0))
      .reduce((a, b) => a + b, 0);
    if (productGroup.discountSelection === DiscountSelection.Percentage) {
      return (productSum * (productGroup.discountPercentage || 0)) / 100;
    } else if (
      productGroup.discountSelection === DiscountSelection.TotalPrice
    ) {
      return productSum - (productGroup.discountTotalPrice || 0);
    }
    return 0;
  }

  getTotalPrice(productGroup: ProductGroup) {
    if (!productGroup.products?.length) {
      return 0;
    }
    const productSum = productGroup.products
      .map((entry) => (entry.amount || 0) * (entry.pricePerUnit || 0))
      .reduce((a, b) => a + b, 0);
    return productSum - this.getDiscountValue(productGroup);
  }

  get tabValues(): Array<DropdownPair<DocumentType>> {
    if (this.isPayment)
      return [
        {
          key: DocumentType.PrepaymentInvoice,
          label: this.$t(
            "order.documentTypeEnum.PREPAYMENT_INVOICE"
          ).toString(),
        },
      ];
    return [
      {
        key: DocumentType.Offer,
        label: this.$t("order.documentTypeEnum.OFFER").toString(),
      },
      {
        key: DocumentType.PurchaseContract,
        label: this.$t("order.documentTypeEnum.PURCHASE_CONTRACT").toString(),
      },
      {
        key: DocumentType.Invoice,
        label: this.$t("order.documentTypeEnum.INVOICE").toString(),
      },
      {
        key: DocumentType.SupplierConfirmation,
        label: this.$t(
          "order.documentTypeEnum.SUPPLIER_CONFIRMATION"
        ).toString(),
      },
    ];
  }

  get otherTabValues(): Array<DropdownPair<string>> {
    return this.tabValues
      .filter((element) => element.key !== this.currentDocumentType)
      .map((entry) => ({ key: entry.key.toString(), label: entry.label }));
  }

  get supplierDropDown(): SupplierDTO[] {
    return [
      { id: "from-order", name: this.$t("order.getFromOrder").toString() },
      ...this.supplierValues,
    ];
  }

  get vatValue(): number {
    const location = this.locations.find(
      (entry) => entry.id === this.documentData.locationId
    );
    return location?.vat || 20;
  }

  get totalAmount(): number {
    if (!this.documentData.productGroups) {
      return 0;
    }
    return this.documentData.productGroups
      .map((entry) => this.getTotalPrice(entry))
      .reduce((a, b) => a + b, 0);
  }

  get totalDiscountValue(): number {
    if (!this.documentData.hasDiscountData) {
      return 0;
    }
    if (this.documentData.discountSelection === DiscountSelection.Amount) {
      return this.documentData.discountAmount || 0;
    } else if (
      this.documentData.discountSelection === DiscountSelection.Percentage
    ) {
      return (
        (this.totalAmountNet * (this.documentData.discountPercentage || 0)) /
        100
      );
    } else if (
      this.documentData.discountSelection === DiscountSelection.TotalPrice
    ) {
      if (this.documentData.enterNetPrices) {
        return (
          this.totalAmountNet - (this.documentData.discountTotalPrice || 0)
        );
      }
      return (
        this.totalAmountNet -
        (this.documentData.discountTotalPrice || 0) / (1 + this.vatValue / 100)
      );
    }
    return 0;
  }

  get totalAmountGross(): number {
    return (
      (this.totalAmountNet - this.totalDiscountValue) *
      (1 + this.vatValue / 100)
    );
  }

  get totalAmountNet(): number {
    return this.documentData.enterNetPrices
      ? this.totalAmount
      : this.totalAmount / (1 + this.vatValue / 100);
  }

  get discountSelectionValues(): Array<DropdownPair<DiscountSelection>> {
    return [
      {
        key: DiscountSelection.Percentage,
        label: this.$t(
          `order.discountSelectionPercentage${
            this.documentData.enterNetPrices ? "Net" : "Gross"
          }`
        ).toString(),
      },
      {
        key: DiscountSelection.Amount,
        label: this.$t(
          `order.discountSelectionAmount${
            this.documentData.enterNetPrices ? "Net" : "Gross"
          }`
        ).toString(),
      },
      {
        key: DiscountSelection.TotalPrice,
        label: this.$t(
          `order.discountSelectionTotalPrice${
            this.documentData.enterNetPrices ? "Net" : "Gross"
          }`
        ).toString(),
      },
    ];
  }

  get totalDiscountSelectionValues(): Array<DropdownPair<DiscountSelection>> {
    return [
      {
        key: DiscountSelection.Percentage,
        label: this.$t("order.discountSelectionPercentageNet").toString(),
      },
      {
        key: DiscountSelection.Amount,
        label: this.$t("order.discountSelectionAmountNet").toString(),
      },
      {
        key: DiscountSelection.TotalPrice,
        label: this.$t(
          `order.discountSelectionTotalPrice${
            this.documentData.enterNetPrices ? "Net" : "Gross"
          }`
        ).toString(),
      },
    ];
  }

  get showDiscountPrintOptionHint(): boolean {
    if (!this.documentData.productGroups) {
      return false;
    }
    if (
      !this.documentData.productGroups.find((entry) => entry.hasDiscountData)
    ) {
      return false;
    }
    return (
      !(this.documentData.settings.showSubtotalPerSupplier || false) &&
      (this.documentData.settings.showDiscounts || false)
    );
  }

  updateShowDiscounts() {
    if (this.showDiscountPrintOptionHint) {
      this.documentData.settings.showSubtotalPerSupplier = true;
    }
  }

  addPayment(payment: PaymentDTO) {
    this.documentData.payments.push(payment);
  }

  deletePayment(payment: PaymentDTO) {
    this.documentData.payments!.splice(
      this.documentData.payments!.findIndex((entry) => entry === payment),
      1
    );
  }

  updatePayment(index: number, payment: PaymentDTO) {
    this.$set(this.documentData.payments!, index, payment);
  }

  updateNoteRemainingPayment(note: string) {
    this.documentData.noteRemainingPayment = note;
  }

  async printDocument() {
    if (!this.isPayment) {
      const file: FileRedirectDTO = (
        await exportApi.printOrderDocumentFile(
          this.currentDocumentType,
          this.order.id!
        )
      ).data;
      openFileInNewTab(file, this.basePath);
    } else {
      const file: FileRedirectDTO = (
        await exportApi.printOrderPaymentDocumentFile(
          this.order.id!,
          this.paymentToEdit!.id!
        )
      ).data;
      openFileInNewTab(file, this.basePath);
    }
  }

  async close(confirmClose: boolean = true) {
    this.showCloseDialog = false;
    if (!confirmClose) {
      return;
    }
    this.documentData = JSON.parse(this.initialDocumentData);
    this.updateDocumentData(true);
    await this.$nextTick();
    this.$emit("updateDocumentData", false);
  }

  async saveAndClose() {
    this.showCloseDialog = false;
    this.updateDocumentData(true);
    await this.$nextTick();
    this.$emit("updateDocumentData", false);
  }

  get currentDocumentType(): DocumentType {
    return this.tabValues[this.tab].key;
  }

  get hasChanges(): boolean {
    return JSON.stringify(this.documentData) !== this.initialDocumentData;
  }

  get hasDefaultSettings(): boolean {
    return (
      JSON.stringify(
        this.defaultDocumentDataSettings[this.currentDocumentType.toString()]
      ) === JSON.stringify(this.documentData.settings)
    );
  }

  revertSettings() {
    this.documentData.settings = {
      ...this.defaultDocumentDataSettings[this.currentDocumentType.toString()],
    };
  }

  saveSettings() {
    this.createOrUpdateDefaultDocumentDataSetting({
      type: this.currentDocumentType,
      setting: this.documentData.settings,
    });
  }

  @Watch("dialog")
  resetObserver() {
    if (this.$refs.validationObserver) {
      this.$refs.validationObserver.reset();
    }
    this.updateDocumentDataFromTab();
  }

  @Watch("paymentToEdit")
  initPaymentDocumentData() {
    if (!this.paymentToEdit || !this.isPayment) {
      return;
    }
    this.documentData = this.paymentToEdit.documentData!;
    this.initialDocumentData = JSON.stringify(this.documentData);
  }

  @Watch("tab")
  updateDocumentDataFromTab() {
    if (this.isPayment) {
      return;
    }
    this.documentTypeToCopyTo = "";
    this.documentData = this.orderDocumentData[
      this.currentDocumentType.toString()
    ] || {
      settings: {
        ...this.defaultDocumentDataSettings[
          this.currentDocumentType.toString()
        ],
      },
      payments: [],
      date: dayjs().format("YYYY-MM-DD"),
      locationId: this.order.location,
      salesmanId: this.order.salesmanId,
    };
    this.order.payments.forEach((payment) => {
      const paymentIndex = this.documentData.payments.findIndex(
        (entry) => entry.id === payment.id
      );
      if (paymentIndex >= 0) {
        this.updatePayment(paymentIndex, payment);
      }
    });
    for (let i = this.documentData.payments.length - 1; i >= 0; --i) {
      const payment = this.documentData.payments[i];
      if (!payment.id) {
        continue;
      }
      if (!this.order.payments.find((entry) => entry.id === payment.id)) {
        this.documentData.payments.splice(i, 1);
      }
    }
    this.initialDocumentData = JSON.stringify(this.documentData);
  }

  @Watch("documentData.hasCustomClientData")
  async updateExpansionPanelStates() {
    if (
      this.documentData.hasCustomClientData &&
      !this.documentData.clientData
    ) {
      this.documentData.clientData = {};
    }
    await this.$nextTick();
    this.expansionPanelStates = this.documentData.hasCustomClientData
      ? [0, 1]
      : [0];
  }
}
