
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { authenticationApi } from "./../store";

@Component
export default class Login extends Vue {
  @Prop()
  register?: boolean;

  loading: boolean = false;
  password: string = "";
  passwordConfirm: string = "";
  show: boolean = false;
  showConfirm: boolean = false;
  passwordConfirmError: boolean = false;
  error: boolean = false;
  didRequest: boolean = false;

  async resetPassword() {
    if (!this.isPasswordValid) {
      return;
    }
    if (this.password !== this.passwordConfirm) {
      this.passwordConfirmError = true;
      return;
    }
    this.passwordConfirmError = false;
    this.loading = true;
    try {
      await authenticationApi.setPassword({
        userId: this.$route.query.userId.toString(),
        resetToken: this.$route.query.resetToken.toString(),
        password: this.password,
      });
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
      this.didRequest = true;
    }
  }

  get registerText(): string {
    return this.$t(
      this.register ? "resetPassword.registerHint" : "resetPassword.hint",
      { mail: this.$route.query.email }
    ).toString();
  }

  get hasEightCharacters(): boolean {
    return this.password.length >= 8;
  }

  get hasUppercase(): boolean {
    return /[A-Z]/.test(this.password);
  }

  get hasSpecialCharacter(): boolean {
    return /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.password);
  }

  get isPasswordValid(): boolean {
    return (
      this.hasEightCharacters && this.hasUppercase && this.hasSpecialCharacter
    );
  }
}
