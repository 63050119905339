
import Component, { mixins } from "vue-class-component";
import CloseConfirmation from "./CloseConfirmation.vue";
import OrderFiles from "./OrderFiles.vue";
import VCurrencyField from "./VCurrencyField.vue";
import dayjs from "dayjs";
import "dayjs/locale/de";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isBetween from "dayjs/plugin/isBetween";
import { Prop, Watch } from "vue-property-decorator";
import {
  CarpenterDTO,
  ComplaintDTO,
  ComplaintOverviewDTO,
  ComplaintState,
  LocationDTO,
  OrderConnection,
  OrderDTO,
  OrderOverviewDTO,
  UserRight,
} from "./../api";
import { Action, Getter } from "vuex-class";
import { complaintApi, RootActions, RootGetters } from "./../store";
import { DropdownPair } from "./../interfaces";
import { ObjectID } from "bson";
import { UserMixin } from "./../mixins/user";
import { ValidationProvider } from "vee-validate";
import { ComplaintMixin } from "./../mixins/complaint";
import { scrollToFirstValidationError } from "./../helpers";
dayjs.locale("de");
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

export class InitialComplaintValues {
  id: string;
  orderId: string;
  constructor(id: string, orderId: string) {
    this.id = id;
    this.orderId = orderId;
  }
}

@Component({
  components: { CloseConfirmation, OrderFiles, VCurrencyField },
})
export default class Orders extends mixins(UserMixin, ComplaintMixin) {
  $refs!: {
    complaintFiles: HTMLFormElement;
    validationObserver: InstanceType<typeof ValidationProvider>;
  };

  @Prop()
  dialog!: boolean;
  @Prop()
  initialComplaint!: InitialComplaintValues;
  @Prop()
  order?: OrderDTO;
  @Prop()
  orderRemainingAmount?: number;

  @Getter(RootGetters.getCarpenters)
  carpenterValues!: CarpenterDTO[];
  @Getter(RootGetters.getOrders)
  orders!: OrderOverviewDTO[];
  @Getter(RootGetters.getComplaintLocations)
  locations!: LocationDTO[];

  @Action(RootActions.getOrders)
  getOrders!: () => void;
  @Action(RootActions.createOrUpdateComplaint)
  createOrUpdateComplaint!: (payload: ComplaintDTO) => ComplaintOverviewDTO;

  complaint: ComplaintDTO = {};
  initialComplaintJSON: string = "";
  loadingComplaint: boolean = false;
  savingComplaint: boolean = false;
  showCreatedAtDate: boolean = false;
  showComplaintDate: boolean = false;
  showAvailabilityDate: boolean = false;
  showFixedAtDate: boolean = false;
  repairTimeInHours: number = 0;
  repairTimeInMinutes: number = 0;
  showCloseDialog: boolean = false;
  showUploadDialog: boolean = false;
  hasFileChanges: boolean = false;

  connectionValues: Array<DropdownPair<string>> = Object.keys(
    OrderConnection
  ).map((entry: string) => ({
    key: (OrderConnection as any)[entry],
    label: this.$t(
      `connectionsEnum.${(OrderConnection as any)[entry]}`
    ).toString(),
  }));

  imposedValues: Array<DropdownPair<string>> = [
    { key: "SELF", label: this.$t("complaint.imposedEnum.SELF").toString() },
    {
      key: "SUPPLIER",
      label: this.$t("complaint.imposedEnum.SUPPLIER").toString(),
    },
    {
      key: "CLIENT",
      label: this.$t("complaint.imposedEnum.CLIENT").toString(),
    },
  ];

  beforeMount() {
    window.addEventListener("beforeunload", this.saveBeforeClose);
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.saveBeforeClose);
  }

  saveBeforeClose() {
    if (this.complaint.orderId && this.didComplaintValuesChange) {
      this.saveComplaint();
    }
  }

  islockedDoneState(complaint: ComplaintDTO): boolean {
    return complaint.date != null && dayjs(complaint.date).isBefore(dayjs());
  }

  get dialogTitle(): string {
    return this.$t(
      `complaint.${this.complaint.id ? "edit" : "new"}`
    ).toString();
  }

  get orderValues(): Array<DropdownPair<string>> {
    return this.orders
      .filter((entry) => entry.clientName && entry.sellDate)
      .map((entry: OrderOverviewDTO) => ({
        key: entry.id!,
        label: `${entry.clientName} (${this.$options.filters!.formatDate(
          entry.sellDate
        )})`,
      }));
  }

  get locationValues(): Array<DropdownPair<string>> {
    return this.locations
      .filter((entry) => {
        if (!entry.id) {
          return true;
        }
        return entry.enabled;
      })
      .map((entry) => {
        if (entry.id) {
          return {
            key: entry.id,
            label: entry.name!,
          };
        }
        return {
          key: entry.name!,
          label: this.$t(`locationsEnum.${entry.name!}`).toString(),
        };
      });
  }

  get currentOrder(): OrderOverviewDTO {
    return this.orders.find((entry) => entry.id === this.complaint.orderId)!;
  }

  get reclamationColor(): string {
    return this.complaint.importance === 3
      ? "red"
      : this.complaint.importance === 2
      ? "orange"
      : "black";
  }

  get deliveryWeekString(): string {
    return this.complaint.availability
      ? this.$t("complaint.calendarWeek", {
          value: `${dayjs(this.complaint.availability).week()} (${dayjs(
            this.complaint.availability
          ).format("YYYY")})`,
        }).toString()
      : "";
  }

  get didComplaintValuesChange(): boolean {
    return (
      JSON.stringify(this.complaint) !== this.initialComplaintJSON ||
      this.complaint.repairTimeInMinutes !==
        this.calculatedRepairTimeInMinutes ||
      this.hasFileChanges
    );
  }

  get calculatedRepairTimeInMinutes(): number {
    return this.repairTimeInHours * 60 + this.repairTimeInMinutes;
  }

  get canEditComplaint(): boolean {
    if (this.hasUserRight(UserRight.OtherComplaintWrite)) {
      return true;
    }
    return (
      this.hasUserRight(UserRight.OwnComplaintWrite) &&
      this.complaint.salesmanId === this.currentUser.user!.id!
    );
  }

  get lastModifiedText(): string {
    const fittingUser = this.users.find(
      (entry) => entry.id === this.complaint.lastModifiedBy
    );
    return this.$t("lastEditBy", {
      lastModifiedDate: dayjs(this.complaint.lastModifiedDate).format(
        "DD.MM.YYYY HH:mm"
      ),
      lastModifiedBy: fittingUser?.lastName,
    }).toString();
  }

  async saveComplaintAndClose() {
    this.showCloseDialog = false;
    await this.saveComplaint();
    this.close();
  }

  async saveComplaint() {
    this.savingComplaint = true;
    try {
      if (!(await this.$refs.validationObserver.validate())) {
        this.$nextTick(() => scrollToFirstValidationError());
        return;
      }
      this.complaint.repairTimeInMinutes = this.calculatedRepairTimeInMinutes;

      if (!this.complaint.id) {
        this.complaint.id = new ObjectID().toString();
      }
      const createdComplaint: ComplaintOverviewDTO =
        await this.createOrUpdateComplaint(this.complaint);
      this.complaint.lastModifiedBy = createdComplaint.lastModifiedBy;
      this.complaint.lastModifiedDate = createdComplaint.lastModifiedDate;
      this.initialComplaintJSON = JSON.stringify(this.complaint);
      await this.$refs.complaintFiles.executeFileQueue(this.currentOrder.id);
    } finally {
      this.savingComplaint = false;
    }
  }

  updateState() {
    if (this.complaint.state === ComplaintState.Done) {
      this.complaint.fixedAt = dayjs().format("YYYY-MM-DD");
    }
  }

  clearComplaintDate() {
    this.complaint.date = undefined;
    if (this.complaint.state === ComplaintState.Assigned) {
      this.complaint.state = ComplaintState.InProgress;
    }
  }

  changeComplaintDate() {
    this.showComplaintDate = false;
    if (this.complaint.date && this.complaint.state !== ComplaintState.Done) {
      this.complaint.state = ComplaintState.Assigned;
    }
  }

  close(confirmClose: boolean = true) {
    this.showCloseDialog = false;
    if (!confirmClose) {
      return;
    }
    this.$emit("close");
  }

  updateFileChanges(hasChanges: boolean) {
    this.hasFileChanges = hasChanges;
  }

  @Watch("dialog")
  resetObserver() {
    this.$refs.validationObserver.reset();
  }

  @Watch("initialComplaint", { immediate: true })
  async updateInitialComplaint() {
    this.loadingComplaint = true;
    try {
      this.repairTimeInHours = 0;
      this.repairTimeInMinutes = 0;
      if (!this.orders.length) {
        await this.getOrders();
      }
      if (this.initialComplaint.id) {
        this.complaint = (
          await complaintApi.getComplaint(this.initialComplaint.id)
        ).data;
        if (this.complaint.repairTimeInMinutes) {
          this.repairTimeInHours = Math.floor(
            this.complaint.repairTimeInMinutes / 60
          );
          this.repairTimeInMinutes =
            this.complaint.repairTimeInMinutes - this.repairTimeInHours * 60;
        }
      } else {
        this.complaint = {
          createdAt: dayjs().format("YYYY-MM-DD"),
          orderId: this.initialComplaint.orderId,
          state: ComplaintState.New,
          remainingAmount: this.orderRemainingAmount,
        };
      }
      this.initialComplaintJSON = JSON.stringify(this.complaint);
    } finally {
      this.loadingComplaint = false;
    }
  }
}
