import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Home from "../views/Home.vue";
import TenantOverview from "../views/super-admin/TenantOverview.vue";
import Settings from "../views/Settings.vue";
import Orders from "../views/Orders.vue";
import PictureApp from "../views/PictureApp.vue";
import Complaints from "../views/Complaints.vue";
import WeekOverview from "../views/WeekOverview.vue";
import SalesStatistics from "../views/SalesStatistics.vue";
import { UNAUTHENTICATED_ROUTES } from "./../main";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: ResetPassword,
    props: { register: true },
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/tenants",
    name: "Tenants",
    component: TenantOverview,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/orders/:detail",
    name: "Orders",
    component: Orders,
    props: true,
  },
  {
    path: "/assemblies",
    name: "Assemblies",
    component: PictureApp,
  },
  {
    path: "/complaints",
    name: "Complaints",
    component: Complaints,
  },
  {
    path: "/week-overview",
    name: "Week Overview",
    component: WeekOverview,
  },
  {
    path: "/sales-statistics",
    name: "Sales Statistics",
    component: SalesStatistics,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    !localStorage.getItem("token") &&
    !UNAUTHENTICATED_ROUTES.includes(to.name || "")
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
