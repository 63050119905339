
import Vue from "vue";
import Component from "vue-class-component";
import DeleteConfirmation from "./DeleteConfirmation.vue";
import { Prop, Watch } from "vue-property-decorator";
import {
  ComplaintDTO,
  DocumentType,
  ExportFileType,
  FileDTO,
  FileRedirectDTO,
  FileType,
  OrderDTO,
  SupplierCalculationDTO,
  SupplierDTO,
} from "./../api";
import { exportApi, fileApi, RootGetters } from "./../store";
import { Getter } from "vuex-class";
import { ObjectID } from "bson";
import dayjs from "dayjs";
import { DropdownPair } from "./../interfaces";
import { openFileInNewTab } from "./../helpers";

export class FileQueue {
  addAction?: FileAddAction;
  deleteAction?: FileDeleteAction;
}

export class FileAddAction {
  file: File | null = null;
  fileType: FileType = FileType.Misc;
  additionalId?: string;
}

export class FileDeleteAction {
  fileName: string = "";
  fileType: FileType = FileType.Misc;
  additionalId?: string;
}

interface FileSlotRow {
  type: FileType;
  slots: FileSlot[];
}

interface FileSlot {
  type?: FileType;
  custom?: boolean;
  complaintId?: string;
  suffix?: string;
  additionalId?: string;
  hidden?: boolean;
  documentType?: DocumentType;
}

@Component({
  components: {
    DeleteConfirmation,
  },
})
export default class OrderFiles extends Vue {
  $refs!: {
    uploader: HTMLElement;
    multiUploader: HTMLElement;
  };

  @Prop()
  order!: OrderDTO;
  @Prop()
  complaint?: ComplaintDTO;
  @Prop()
  supplierCalculation?: SupplierCalculationDTO;
  @Getter(RootGetters.getBasePath)
  basePath!: string;
  @Getter(RootGetters.getSuppliers)
  suppliers!: SupplierDTO[];
  @Prop()
  disabled?: boolean;

  uploadFileType: FileType = FileType.Misc;
  uploadFileId?: string;
  uploadFile: File | null = null;

  show: boolean = false;
  addAdditionalFile: boolean = false;
  dragging: any = {};
  uploading: any = {};
  showDeleteDialog: boolean = false;
  fileFilter: number[] = [0, 1, 2, 3, 4, 5, 6];
  files: FileDTO[] = [];
  editFile: FileDTO = {};
  queue: FileQueue[] = [];

  showReplaceDialog: boolean = false;
  replaceMessage: string = "";

  printOptions: Array<DropdownPair<ExportFileType>> = [
    {
      key: ExportFileType.Pdf,
      label: this.$t("exportFileTypeEnum.PDF").toString(),
    },
    {
      key: ExportFileType.Doc,
      label: this.$t("exportFileTypeEnum.DOC").toString(),
    },
  ];

  mounted() {
    this.setInitialVisibility();
  }

  get fileSlotRows(): FileSlotRow[] {
    const addAdditionalFilesToSlotRows = (slots: FileSlotRow[]) => {
      slots.forEach((slotRow) => {
        const additionalFiles = this.files.filter((file) => file.type === slotRow.type);
        if (additionalFiles.length) {
          slotRow.slots = slotRow.slots.concat(
            additionalFiles
              .filter((file) => {
                if (this.supplierCalculation?.id) {
                  return file.additionalId?.startsWith(this.supplierCalculation.id);
                }
                return !this.supplierIds.includes(file.additionalId);
              })
              .map((file) => ({
                type: slotRow.type,
                additionalId: file.additionalId,
                suffix: file.name,
              }))
          );
        }
      });
    };
    const slotRows: FileSlotRow[] = [];
    const getHiddenProperty = (fileType: any, additionalId: string = "") =>
      this.order?.hiddenFileTypes
        ? this.order.hiddenFileTypes.findIndex((type) =>
            additionalId ? type === `${fileType}:${additionalId}` : type === fileType
          ) >= 0
        : false;
    if (this.complaint) {
      const complaintSlots: FileSlot[] = [];
      const suffix: string = this.complaint.date
        ? `(${dayjs(this.complaint.date).format("DD.MM.YYYY")})`
        : "";
      if (this.order?.location && this.complaint.id) {
        complaintSlots.push({
          custom: true,
          complaintId: this.complaint.id,
          suffix,
        });
      }
      complaintSlots.push({
        type: FileType.ComplaintReport,
        additionalId: this.complaint.id,
        suffix,
        hidden: getHiddenProperty(FileType.ComplaintReport),
      });
      slotRows.push({ type: FileType.Complaint, slots: complaintSlots });
      addAdditionalFilesToSlotRows(slotRows);
      return slotRows;
    }
    if (this.supplierCalculation) {
      slotRows.push({
        type: FileType.DeliveryNote,
        slots: [
          {
            type: FileType.DeliveryNoteManufacturer,
            additionalId: this.supplierCalculation.id,
            hidden: getHiddenProperty(FileType.DeliveryNoteManufacturer),
          },
        ],
      });
      addAdditionalFilesToSlotRows(slotRows);
      return slotRows;
    }

    let contractSlots: FileSlot[] = [];
    if (this.order.exhibitionSale) {
      contractSlots.push({
        type: FileType.ContractExhibitionPurchase,
        hidden: getHiddenProperty(FileType.ContractExhibitionPurchase),
      });
    }
    if (this.order.orderPrice) {
      contractSlots.push({
        type: FileType.ContractPurchase,
        hidden: getHiddenProperty(FileType.ContractPurchase),
      });
    }
    if (this.order.id && this.order.location && this.order.payments) {
      contractSlots = contractSlots.concat(
        this.order.payments
          .filter((entry) => entry.id && entry.documentData)
          .map((entry) => ({
            custom: true,
            type: FileType.Contract,
            documentType: DocumentType.PrepaymentInvoice,
            additionalId: entry.id,
            suffix: entry.documentData?.invoiceNumber,
          }))
      );
    }
    if (this.order.orderPrice) {
      contractSlots.push({
        type: FileType.ContractInvoice,
        hidden: getHiddenProperty(FileType.ContractInvoice),
      });
    }
    if (this.order.changes?.length) {
      this.order.changes.forEach((change, index) => {
        contractSlots.push({
          type: FileType.ContractChange,
          additionalId: change.id,
          suffix: (index + 1).toString(),
        });
      });
    }
    if (contractSlots.length) {
      slotRows.push({ type: FileType.Contract, slots: contractSlots });
    }
    slotRows.push({
      type: FileType.Plans,
      slots: [
        {
          type: FileType.PlanInstallation,
          hidden: getHiddenProperty(FileType.PlanInstallation),
        },
        {
          type: FileType.PlanSigned,
          hidden: getHiddenProperty(FileType.PlanSigned),
        },
        {
          type: FileType.PlanMeasurement,
          hidden: getHiddenProperty(FileType.PlanMeasurement),
        },
      ],
    });
    if (this.order.suppliers?.length) {
      slotRows.push({
        type: FileType.SupplierConfirmation,
        slots: this.order.suppliers.map((supplier) => {
          const fittingSupplier: SupplierDTO | undefined = this.suppliers.find(
            (entry) => entry.id === supplier.supplierId
          );
          return {
            type: FileType.SupplierConfirmation,
            supplierId: supplier.supplierId,
            additionalId: supplier.id,
            suffix: fittingSupplier?.name,
            hidden: getHiddenProperty(FileType.SupplierConfirmation, supplier.id),
          };
        }),
      });
      slotRows.push({
        type: FileType.SupplierOrder,
        slots: this.order.suppliers.map((supplier) => {
          const fittingSupplier: SupplierDTO | undefined = this.suppliers.find(
            (entry) => entry.id === supplier.supplierId
          );
          return {
            type: FileType.SupplierOrder,
            supplierId: supplier.supplierId,
            additionalId: supplier.id,
            suffix: fittingSupplier?.name,
            hidden: getHiddenProperty(FileType.SupplierOrder, supplier.id),
          };
        }),
      });
    }
    if (this.order.complaints?.length) {
      const complaintSlots: FileSlot[] = [];
      this.order.complaints.forEach((complaint) => {
        const suffix: string = complaint.date
          ? `(${dayjs(complaint.date).format("DD.MM.YYYY")})`
          : "";
        if (this.order.location) {
          complaintSlots.push({
            custom: true,
            complaintId: complaint.id,
            suffix,
          });
        }
        complaintSlots.push({
          type: FileType.ComplaintReport,
          additionalId: complaint.id,
          suffix,
          hidden: getHiddenProperty(FileType.ComplaintReport, complaint.id),
        });
      });
      slotRows.push({ type: FileType.Complaint, slots: complaintSlots });
    }
    slotRows.push({
      type: FileType.Misc,
      slots: [
        {
          type: FileType.MiscDeliveryNote,
          hidden: getHiddenProperty(FileType.MiscDeliveryNote),
        },
        {
          type: FileType.MiscAssemblyOrder,
          hidden: getHiddenProperty(FileType.MiscAssemblyOrder),
        },
        {
          type: FileType.MiscAssemblyReport,
          hidden: getHiddenProperty(FileType.MiscAssemblyReport),
        },
      ],
    });
    addAdditionalFilesToSlotRows(slotRows);
    return slotRows;
  }

  get supplierIds(): (string | undefined)[] {
    return this.order.suppliers ? this.order.suppliers.map((entry) => entry.id) : [];
  }

  getFileSlotName(fileSlot: FileSlot, fileSlotRow: FileSlotRow) {
    const namePieces: string[] = [];
    if (fileSlot.type !== fileSlotRow.type) {
      namePieces.push(this.$t(`fileTypeEnum.${fileSlot.type}`).toString());
    }
    if (fileSlot.suffix) {
      namePieces.push(fileSlot.suffix);
    }
    return namePieces.join(" ");
  }

  isDragging(type?: FileType, additionalId?: string): boolean {
    return this.dragging[`${type}${additionalId ? `-${additionalId}` : ""}`];
  }

  onDragEnter(type?: FileType, additionalId?: string) {
    this.dragging[`${type}${additionalId ? `-${additionalId}` : ""}`] = true;
    this.$forceUpdate();
  }

  onDragLeave(type?: FileType, additionalId?: string) {
    this.dragging[`${type}${additionalId ? `-${additionalId}` : ""}`] = false;
    this.$forceUpdate();
  }

  getFile(type?: FileType, additionalId?: string): FileDTO | undefined {
    return this.files.find(
      (file) =>
        file.type === type && (!additionalId || file.additionalId === additionalId)
    );
  }

  async openFile(type?: FileType, additionalId?: string) {
    const file: FileDTO = this.getFile(type!, additionalId)!;
    if (!file.id) {
      const filesInQueue: FileQueue[] = this.queue.filter(
        (entry) =>
          entry.addAction?.fileType === type &&
          entry.addAction?.additionalId === additionalId
      );
      if (filesInQueue.length) {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(
          filesInQueue[filesInQueue.length - 1].addAction!.file!
        );
        a.setAttribute(
          "download",
          filesInQueue[filesInQueue.length - 1].addAction!.file!.name
        );
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      return;
    }
    const fileRedirect: FileRedirectDTO = (
      await fileApi.getFileRedirect(file.id, file.type!, file.name!, file.additionalId)
    ).data;
    openFileInNewTab(fileRedirect);
  }

  deleteFile(type?: FileType, additionalId?: string) {
    if (this.disabled) {
      return;
    }
    this.editFile = this.getFile(type, additionalId)!;
    this.showDeleteDialog = true;
  }

  addDropFile(
    e: any,
    type?: FileType,
    additionalId?: string,
    addAdditionalFile?: boolean
  ) {
    if (this.disabled) {
      return;
    }
    const filesToUpload = addAdditionalFile ? e.dataTransfer.files.length : 1;
    for (let i = 0; i < filesToUpload; ++i) {
      this.uploadFileId = additionalId;
      if (addAdditionalFile) {
        this.generateAdditionalFileId();
      }
      this.uploadFileType = type!;
      this.uploadFile = e.dataTransfer.files[i];
      this.onDragLeave(type, additionalId);

      // only show replacement dialog when more than one file to upload
      if (filesToUpload === 1) {
        const existingFile = this.getFile(type, this.uploadFileId);
        if (existingFile) {
          this.replaceMessage = this.$t("files.replaceMessage", {
            existingFileName: existingFile.name,
            newFileName: this.uploadFile?.name,
          }).toString();
          this.showReplaceDialog = true;
        }
      }
      if (!this.showReplaceDialog) {
        this.confirmUpload();
      }
    }
  }

  confirmDelete(doDelete: boolean) {
    this.showDeleteDialog = false;
    if (!doDelete) {
      return;
    }
    this.files.splice(
      this.files.findIndex(
        (file) =>
          file.type === this.editFile.type &&
          file.name === this.editFile.name &&
          (!this.editFile.additionalId ||
            this.editFile.additionalId === file.additionalId)
      ),
      1
    );
    this.queue.push({
      deleteAction: {
        fileName: this.editFile.name!,
        fileType: this.editFile.type!,
        additionalId: this.editFile.additionalId || "",
      },
    });
  }

  uploadFileClick(
    type?: FileType,
    additionalId?: string,
    multiple?: boolean,
    addAdditionalFile?: boolean
  ) {
    if (this.disabled) {
      return;
    }
    this.addAdditionalFile = addAdditionalFile || false;
    this.uploadFileId = additionalId;
    this.uploadFileType = type!;
    if (multiple) {
      (this.$refs.multiUploader as any).value = null;
      this.$refs.multiUploader.click();
    } else {
      (this.$refs.uploader as any).value = null;
      this.$refs.uploader.click();
    }
  }

  generateAdditionalFileId() {
    this.uploadFileId = new ObjectID().toString();
    if (this.supplierCalculation) {
      this.uploadFileId = this.supplierCalculation.id + ":" + this.uploadFileId;
    }
  }

  onFileChanged(e: any) {
    if (this.disabled) {
      return;
    }
    for (const file of e.target.files) {
      const resetUploadFileId: boolean = this.uploadFileId?.length === 0 || true;
      if (this.addAdditionalFile) {
        this.generateAdditionalFileId();
      }
      this.uploadFile = file;
      this.confirmUpload();
      if (resetUploadFileId) {
        this.uploadFileId = "";
      }
    }
    (this.$refs.uploader as any).value = null;
  }

  async confirmUpload() {
    this.showReplaceDialog = false;
    let newFile: FileDTO = {};
    this.queue.push({
      addAction: {
        file: this.uploadFile,
        fileType: this.uploadFileType,
        additionalId: this.uploadFileId,
      },
    });
    newFile = {
      name: this.uploadFile?.name,
      additionalId: this.uploadFileId,
      type: this.uploadFileType,
    };
    if (
      !this.files.find(
        (entry) =>
          entry.type === newFile.type &&
          (!newFile.additionalId || newFile.additionalId === entry.additionalId)
      )
    ) {
      this.files.push(newFile);
    }
    this.uploadFile = null;
  }

  async executeFileQueue(orderId: string) {
    for (const queueAction of this.queue) {
      if (queueAction.deleteAction) {
        const deleteAction: FileDeleteAction = queueAction.deleteAction;
        if (deleteAction.additionalId) {
          await fileApi.deleteFileWithAdditionalId(
            orderId,
            deleteAction.fileType,
            deleteAction.additionalId,
            deleteAction.fileName
          );
        } else {
          await fileApi.deleteFile(orderId, deleteAction.fileType, deleteAction.fileName);
        }
      } else if (queueAction.addAction) {
        const addAction: FileAddAction = queueAction.addAction;
        this.uploading[`${addAction.fileType}-${addAction.additionalId}`] = true;
        try {
          await fileApi.uploadFile(
            addAction.file,
            orderId,
            addAction.fileType,
            addAction.additionalId
          );
        } finally {
          this.uploading[`${addAction.fileType}-${addAction.additionalId}`] = false;
        }
      }
    }
    this.queue = [];
  }

  clearQueue() {
    this.queue = [];
    this.files = [];
  }

  async preparePrePaymentFile(orderId: string, paymentId: string) {
    const file: FileRedirectDTO = (
      await exportApi.printOrderPaymentDocumentFile(orderId, paymentId)
    ).data;
    openFileInNewTab(file, this.basePath);
  }

  async prepareComplaintFile(comlaintId?: string) {
    const file: FileRedirectDTO = (await exportApi.getComplaintFile(comlaintId!)).data;
    openFileInNewTab(file, this.basePath);
  }

  getKeyForSlot(fileSlot: FileSlot): string {
    return fileSlot.additionalId
      ? `${fileSlot.type}:${fileSlot.additionalId}`
      : fileSlot.type!;
  }

  canHide(fileSlot: FileSlot, fileSlotRow: FileSlotRow): boolean {
    if (fileSlot.type !== fileSlotRow.type) {
      return true;
    }
    if (
      fileSlot.type === FileType.SupplierOrder ||
      fileSlot.type === FileType.SupplierConfirmation
    ) {
      return this.supplierIds.includes(fileSlot.additionalId);
    }
    return false;
  }

  toogleHidden(fileSlot: FileSlot, hidden: boolean) {
    this.$emit("toggleHidden", {
      key: this.getKeyForSlot(fileSlot),
      hidden,
    });
  }

  @Watch("order", { immediate: true })
  async loadOrderFiles() {
    this.queue = [];
    this.files = this.order?.id ? (await fileApi.getFiles(this.order.id)).data : [];
  }

  @Watch("files", { immediate: true })
  @Watch("order.hiddenFileTypes")
  @Watch("order.suppliers")
  updateMissingFileTypes() {
    const missingFileTypes: string[] = [];
    this.fileSlotRows.forEach((fileSlotRow) =>
      fileSlotRow.slots.forEach((fileSlot: FileSlot) => {
        if (fileSlot.custom) {
          return;
        }
        const key = this.getKeyForSlot(fileSlot);
        if (
          !this.getFile(fileSlot.type, fileSlot.additionalId) &&
          !missingFileTypes.find((type) => type === key) &&
          !this.order?.hiddenFileTypes?.find((type) => type === key)
        ) {
          missingFileTypes.push(key);
        }
      })
    );
    this.$emit("updateMissingFileTypes", missingFileTypes);
  }

  @Watch("queue")
  updateQueue() {
    this.$emit("updateHasChanges", this.queue.length > 0);
  }

  @Watch("$vuetify.breakpoint.mobile")
  setInitialVisibility() {
    this.show = !this.$vuetify.breakpoint.mobile;
  }
}
