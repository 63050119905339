
import Component, { mixins } from "vue-class-component";
import VueApexCharts from "vue-apexcharts";
import Navigation from "../components/Navigation.vue";
import ResetFiltersButton from "../components/ResetFiltersButton.vue";
import { ApexOptions } from "apexcharts";
import de from "apexcharts/dist/locales/de.json";
import {
  ChartAreaValuesGrouping,
  ChartBarValuesGrouping,
  ChartDuration,
  DashboardPlotDataDTO,
  OrderType,
} from "../api";
import { LocationMixin } from "../mixins/location";
import { exportApi, orderApi } from "../store";
import { DropdownPair } from "../interfaces";
import { Watch } from "vue-property-decorator";
import dayjs, { Dayjs } from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import "dayjs/locale/de";
import { UserMixin } from "./../mixins/user";
dayjs.locale("de");
dayjs.extend(weekOfYear);

@Component({ components: { Navigation, VueApexCharts, ResetFiltersButton } })
export default class Dashboard extends mixins(LocationMixin, UserMixin) {
  chartDuration: ChartDuration = ChartDuration.ThisMonth;
  currentLocations: string[] = [];
  salesmenIdFilter: string[] = [];
  compare: boolean = false;
  currentTypes: OrderType[] = [OrderType.Normal];
  typeValues: Array<DropdownPair<OrderType>> = [
    {
      key: OrderType.Normal,
      label: this.$t("order.typeEnum.NORMAL").toString(),
    },
    {
      key: OrderType.Small,
      label: this.$t("order.typeEnum.SMALL").toString(),
    },
  ];
  chartDurationValues: Array<DropdownPair<ChartDuration>> = [
    {
      key: ChartDuration.Last30Days,
      label: this.$t("chartDurationEnum.LAST_30_DAYS").toString(),
    },
    {
      key: ChartDuration.Last3Months,
      label: this.$t("chartDurationEnum.LAST_3_MONTHS").toString(),
    },
    {
      key: ChartDuration.LastYear,
      label: this.$t("chartDurationEnum.LAST_YEAR").toString(),
    },
    {
      key: ChartDuration.ThisMonth,
      label: this.$t("chartDurationEnum.THIS_MONTH").toString(),
    },
    {
      key: ChartDuration.ThisYear,
      label: this.$t("chartDurationEnum.THIS_YEAR").toString(),
    },
  ];

  plotData: DashboardPlotDataDTO = {};
  minimumDate: Dayjs = dayjs();
  compareYear = dayjs().year() - 1;
  maxmimumMonth: string = dayjs().subtract(1, "month").format("YYYY-MM-DD");
  compareMonth: string = dayjs().subtract(1, "month").format("YYYY-MM-DD");
  showMonthPicker: boolean = false;

  get comparePossible(): boolean {
    if (
      this.chartDuration === ChartDuration.Last30Days ||
      this.chartDuration === ChartDuration.Last3Months
    ) {
      return false;
    }
    if (
      this.chartDuration === ChartDuration.LastYear ||
      this.chartDuration === ChartDuration.ThisYear
    ) {
      return (
        this.yearCompareValues.find((entry) => entry.key === this.compareYear) != null
      );
    }
    if (this.chartDuration === ChartDuration.ThisMonth) {
      const now: Dayjs = dayjs();
      return (
        this.minimumDate.year() !== now.year() || this.minimumDate.month() !== now.month()
      );
    }
    return true;
  }

  get yearCompareValues(): Array<DropdownPair<number>> {
    let maximumYear = dayjs().year() - 1;
    if (this.chartDuration === ChartDuration.LastYear) {
      maximumYear -= 1;
    }
    const toReturn: Array<DropdownPair<number>> = [];
    const minimumYear = this.minimumDate.year();
    for (let year = minimumYear; year <= maximumYear; ++year) {
      toReturn.push({ key: year, label: "" + year });
    }
    return toReturn;
  }

  get sellingSumChartOptions(): ApexOptions {
    return {
      title: {
        text: this.$t("chart.sellingSumTitle").toString(),
        style: {
          fontFamily: "Montserrat",
          color: "#0B0C0C",
          fontSize: "20px",
          fontWeight: "bold",
        },
      },
      chart: {
        id: "selling-sum-chart",
        locales: [de],
        defaultLocale: "de",
      },
      dataLabels: {
        formatter: (value: number) => `€ ${this.$options.filters!.formatCurrency(value)}`,
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: (value: string) => {
            const date = dayjs(value);
            return this.plotData.areaValuesGrouping === ChartAreaValuesGrouping.Week
              ? `KW ${date.week()} ${date.year()}`
              : date.format("MMM YYYY");
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value: number) =>
            `€ ${this.$options.filters!.formatCurrency(value || 0)}`,
        },
      },
      stroke: {
        dashArray: [0, 0, 10, 10],
      },
    };
  }
  get clientSumChartOptions(): ApexOptions {
    return {
      title: {
        text: this.$t("chart.clientSumTitle").toString(),
        style: {
          fontFamily: "Montserrat",
          color: "#0B0C0C",
          fontSize: "20px",
          fontWeight: "bold",
        },
      },
      chart: {
        id: "client-sum-chart",
        locales: [de],
        defaultLocale: "de",
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: (value: string) => {
            const date = dayjs(value);
            return this.plotData.areaValuesGrouping === ChartAreaValuesGrouping.Week
              ? `KW ${date.week()} ${date.year()}`
              : date.format("MMM YYYY");
          },
        },
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: (value: number) => "" + Math.floor(value || 0),
        },
      },
      stroke: {
        dashArray: [0, 0, 10, 10],
      },
    };
  }
  complaintChartOptions: ApexOptions = {
    title: {
      text: this.$t("chart.complaintSumTitle").toString(),
      style: {
        fontFamily: "Montserrat",
        color: "#0B0C0C",
        fontSize: "20px",
        fontWeight: "bold",
      },
    },
    chart: {
      id: "complaint-chart",
      locales: [de],
      defaultLocale: "de",
      stacked: true,
    },
    plotOptions: {
      bar: {
        columnWidth: "8%",
        borderRadius: 5,
      },
    },
    colors: ["#dc6149", "#c59ec9", "#e1c230", "#709dd3"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          if (seriesIndex < series.length - 1) {
            return "" + value;
          }
          let existingValues = 0;
          for (let i = 0; i < seriesIndex; ++i) {
            existingValues += series[i][dataPointIndex];
          }
          return "" + (value + existingValues);
        },
      },
    },
  };
  marginChartOptions: ApexOptions = {
    title: {
      text: this.$t("chart.marginTitle").toString(),
      style: {
        fontFamily: "Montserrat",
        color: "#0B0C0C",
        fontSize: "20px",
        fontWeight: "bold",
      },
    },
    chart: {
      id: "margin-chart",
      locales: [de],
      defaultLocale: "de",
      stacked: true,
    },
    plotOptions: {
      bar: {
        columnWidth: "8%",
        borderRadius: 5,
      },
    },
    colors: ["#ffff00", "#6cb873", "#709dd3"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          if (seriesIndex === 0) {
            return `€ ${this.$options.filters!.formatCurrency(value)}`;
          }
          let finalValue = +value;
          let index = seriesIndex;
          while (index--) {
            finalValue += series[index][dataPointIndex];
          }
          return `€ ${this.$options.filters!.formatCurrency(finalValue)}`;
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value: number) => `€ ${this.$options.filters!.formatCurrency(value)}`,
      },
    },
  };

  get sellingSumSeries(): ApexAxisChartSeries {
    return [
      {
        name: this.$t("chart.deliveries").toString(),
        color: "#6cba71",
        data:
          this.plotData.deliveryPlotData?.map((data) => ({
            x: data.date,
            y: data.sellingSum,
          })) || [],
      },
      {
        name: this.$t("chart.sales").toString(),
        color: "#709fd6",
        data:
          this.plotData.sellPlotData?.map((data) => ({
            x: data.date,
            y: data.sellingSum,
          })) || [],
      },
      ...(this.comparePossible && this.compare
        ? [
            {
              name: `${this.$t("chart.deliveries")} ${this.compareDuration}`,
              color: "#6cba71",
              data:
                this.plotData.compareDeliveryPlotData?.map((data) => ({
                  x: this.getShiftedCompareDate(data.date!),
                  y: data.sellingSum,
                })) || [],
            },
            {
              name: `${this.$t("chart.sales")} ${this.compareDuration}`,
              color: "#709fd6",
              data:
                this.plotData.compareSellPlotData?.map((data) => ({
                  x: this.getShiftedCompareDate(data.date!),
                  y: data.sellingSum,
                })) || [],
            },
          ]
        : []),
    ];
  }

  get clientSumSeries(): ApexAxisChartSeries {
    return [
      {
        name: this.$t("chart.deliveries").toString(),
        color: "#e2c330",
        data:
          this.plotData.deliveryPlotData?.map((data) => ({
            x: data.date,
            y: data.orderCount,
          })) || [],
      },
      {
        name: this.$t("chart.sales").toString(),
        color: "#dc6149",
        data:
          this.plotData.sellPlotData?.map((data) => ({
            x: data.date,
            y: data.orderCount,
          })) || [],
      },
      ...(this.comparePossible && this.compare
        ? [
            {
              name: `${this.$t("chart.deliveries")} ${this.compareDuration}`,
              color: "#e2c330",
              data:
                this.plotData.compareDeliveryPlotData?.map((data) => ({
                  x: this.getShiftedCompareDate(data.date!),
                  y: data.orderCount,
                })) || [],
            },
            {
              name: `${this.$t("chart.sales")} ${this.compareDuration}`,
              color: "#dc6149",
              data:
                this.plotData.compareSellPlotData?.map((data) => ({
                  x: this.getShiftedCompareDate(data.date!),
                  y: data.orderCount,
                })) || [],
            },
          ]
        : []),
    ];
  }

  getShiftedCompareDate(date: string) {
    if (this.chartDuration === ChartDuration.ThisMonth) {
      const convertedDate = dayjs(date);
      const monthDifference = dayjs()
        .startOf("month")
        .diff(dayjs(this.compareMonth).startOf("month"), "month");
      return convertedDate.add(monthDifference, "month").format("YYYY-MM-DD");
    } else if (this.chartDuration === ChartDuration.ThisYear) {
      const convertedDate = dayjs(date);
      const yearDifference = dayjs().year() - this.compareYear;
      return convertedDate.add(yearDifference, "year").format("YYYY-MM-DD");
    }
    return date;
  }

  get complaintSumSeries(): ApexAxisChartSeries {
    return [
      {
        name: this.$t("complaint.imposedEnum.SELF").toString(),
        data:
          this.plotData.complaintPlotData?.map((entry) => ({
            x: this.getFormattedBarKey(entry.date!),
            y: entry.selfImposedCount,
          })) || [],
      },
      {
        name: this.$t("complaint.imposedEnum.CLIENT").toString(),
        data:
          this.plotData.complaintPlotData?.map((entry) => ({
            x: this.getFormattedBarKey(entry.date!),
            y: entry.clientImposedCount,
          })) || [],
      },
      {
        name: this.$t("complaint.imposedEnum.SUPPLIER").toString(),
        data:
          this.plotData.complaintPlotData?.map((entry) => ({
            x: this.getFormattedBarKey(entry.date!),
            y: entry.supplierImposedCount,
          })) || [],
      },
      {
        name: this.$t("chart.deliveredKitchens").toString(),
        data:
          this.plotData.complaintPlotData?.map((entry) => ({
            x: this.getFormattedBarKey(entry.date!),
            y:
              entry.orderCount! -
              entry.supplierImposedCount! -
              entry.clientImposedCount! -
              entry.selfImposedCount!,
          })) || [],
      },
    ];
  }
  get marginSumSeries(): ApexAxisChartSeries {
    return [
      {
        name: this.$t("chart.marginSumDelivered").toString(),
        data:
          this.plotData.marginPlotData?.map((entry) => ({
            x: this.getFormattedBarKey(entry.date!),
            y: this.getRoundedValue(entry.marginSum!),
          })) || [],
      },
      {
        name: this.$t("chart.marginDelivered").toString(),
        data:
          this.plotData.marginPlotData?.map((entry) => ({
            x: this.getFormattedBarKey(entry.date!),
            y: this.getRoundedValue(entry.margin! - entry.marginSum!),
          })) || [],
      },
      {
        name: this.$t("chart.sellingSumDelivered").toString(),
        data:
          this.plotData.marginPlotData?.map((entry) => ({
            x: this.getFormattedBarKey(entry.date!),
            y: this.getRoundedValue(entry.sellingSum! - entry.margin!),
          })) || [],
      },
    ];
  }

  get totalSellingSum(): number {
    return (
      this.plotData.sellPlotData
        ?.map((entry) => entry.sellingSum || 0)
        .reduce((a, b) => a + b, 0) || 0
    );
  }

  get totalCompareSellingSum(): number {
    return (
      this.plotData.compareSellPlotData
        ?.map((entry) => entry.sellingSum || 0)
        .reduce((a, b) => a + b, 0) || 0
    );
  }

  get totalDeliverySum(): number {
    return (
      this.plotData.deliveryPlotData
        ?.map((entry) => entry.sellingSum || 0)
        .reduce((a, b) => a + b, 0) || 0
    );
  }

  get totalCompareDeliverySum(): number {
    return (
      this.plotData.compareDeliveryPlotData
        ?.map((entry) => entry.sellingSum || 0)
        .reduce((a, b) => a + b, 0) || 0
    );
  }

  get totalClientsSold(): number {
    return (
      this.plotData.sellPlotData
        ?.map((entry) => entry.orderCount || 0)
        .reduce((a, b) => a + b, 0) || 0
    );
  }

  get totalCompareClientsSold(): number {
    return (
      this.plotData.compareSellPlotData
        ?.map((entry) => entry.orderCount || 0)
        .reduce((a, b) => a + b, 0) || 0
    );
  }

  get totalClientsDelivered(): number {
    return (
      this.plotData.deliveryPlotData
        ?.map((entry) => entry.orderCount || 0)
        .reduce((a, b) => a + b, 0) || 0
    );
  }

  get totalCompareClientsDelivered(): number {
    return (
      this.plotData.compareDeliveryPlotData
        ?.map((entry) => entry.orderCount || 0)
        .reduce((a, b) => a + b, 0) || 0
    );
  }

  get formattedMonthValue(): string {
    return dayjs(this.compareMonth).format("MMMM YYYY");
  }

  get compareDuration(): string {
    if (this.chartDuration === ChartDuration.ThisMonth) {
      return this.formattedMonthValue;
    }
    return "" + this.compareYear;
  }

  getRoundedValue(value: number): number {
    return Math.round(value * 100) / 100;
  }

  getFormattedBarKey(date: string): string {
    switch (this.plotData.barValuesGrouping) {
      case ChartBarValuesGrouping.Year:
        return dayjs(date).format("YYYY");
      case ChartBarValuesGrouping.Month:
        return dayjs(date).format("MMM YYYY");
      default:
        return dayjs(date).format("DD.MMMM.YYYY");
    }
  }

  async created() {
    this.updateSalesmenFilter();
    this.updatePlotData();
    this.minimumDate = dayjs((await orderApi.getMinimumDate()).data);
  }

  resetFilters() {
    this.currentTypes = [OrderType.Normal];
    this.currentLocations = [];
    this.salesmenIdFilter =
      this.isSalesman && !this.isAdmin ? [this.currentUser.user!.id!] : [];
    this.chartDuration = ChartDuration.ThisMonth;
  }

  @Watch("currentUser")
  updateSalesmenFilter() {
    this.salesmenIdFilter =
      this.isSalesman && !this.isAdmin ? [this.currentUser.user!.id!] : [];
  }

  @Watch("currentTypes")
  @Watch("currentLocations")
  @Watch("compareYear")
  @Watch("compareMonth")
  @Watch("compare")
  async updatePlotData() {
    let compareYear: number | undefined;
    let compareMonth: string | undefined;
    if (this.comparePossible && this.compare) {
      if (this.chartDuration === ChartDuration.ThisMonth) {
        compareMonth =
          this.compareMonth.length < 10 ? this.compareMonth + "-01" : this.compareMonth;
      } else {
        compareYear = this.compareYear;
      }
    }
    this.plotData = (
      await exportApi.getPlotData(
        this.chartDuration,
        this.currentTypes,
        this.currentLocations,
        this.salesmenIdFilter,
        compareYear,
        compareMonth
      )
    ).data;
  }
}
