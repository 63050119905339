
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import router from "./../router";
import { UserMixin } from "./../mixins/user";
import { Action } from "vuex-class";
import { RootActions } from "./../store";
import { UserRight } from "./../api";

@Component
export default class Navigation extends mixins(UserMixin) {
  @Action(RootActions.resetCurrentUser)
  resetCurrentUser!: () => void;

  @Prop()
  title!: string;

  drawer: boolean = !this.$vuetify.breakpoint.mobile;

  get menuItems() {
    if (this.isCarpenter) {
      return [
        {
          route: "/",
          icon: "mdi-camera",
          text: this.$t("app.menu.carpenter"),
        },
      ];
    }
    const items = [
      {
        route: "/",
        icon: "mdi-home-outline",
        text: this.$t("home"),
      },
    ];
    if (this.isSuperAdmin) {
      items.push({
        route: "/tenants",
        icon: "mdi-account-group-outline",
        text: this.$t("tenants"),
      });
    }
    if (this.hasUserRight(UserRight.BaseDataRead)) {
      items.push({
        route: "/settings",
        icon: "mdi-account-cog",
        text: this.$t("settings"),
      });
    }
    if (this.hasUserRight(UserRight.OwnOrderRead)) {
      items.push({
        route: "/orders/all",
        icon: "mdi-clipboard-text-outline",
        text: this.$t("orderOverview"),
      });
    }
    if (this.hasTodoPackage) {
      items.push({
        route: "/orders/todo",
        icon: "mdi-checkbox-marked-circle-outline",
        text: this.$t("todo"),
      });
    }
    if (this.hasCarpenterAppPackage) {
      items.push({
        route: "/assemblies",
        icon: "mdi-camera",
        text: this.$t(
          `app.menu.${this.isCarpenter ? "carpenter" : "salesman"}`
        ),
      });
    }
    if (
      this.hasSalesStatisticsPackage &&
      this.hasUserRight(UserRight.SalesStatisticsRead)
    ) {
      items.push({
        route: "/sales-statistics",
        icon: "mdi-format-align-justify",
        text: this.$t("salesStatistics.salesStatistics"),
      });
    }
    if (this.hasUserRight(UserRight.WeekOverviewRead)) {
      items.push({
        route: "/week-overview",
        icon: "mdi-chart-bar",
        text: this.$t("weekOverview.weekOverview"),
      });
    }
    if (
      this.hasComplaintPackage &&
      this.hasUserRight(UserRight.OwnComplaintRead)
    ) {
      items.push({
        route: "/complaints",
        icon: "mdi-lifebuoy mdi-rotate-45",
        text: this.$t("complaints"),
      });
    }
    return items;
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    this.resetCurrentUser();
    router.push("/login");
  }
}
