import Vue from "vue";
import Component from "vue-class-component";
import { ComplaintDTO } from "../api";
import { DropdownPair } from "../interfaces";

@Component
export class ComplaintMixin extends Vue {
  getStateValuesForComplaint(complaint?: ComplaintDTO): Array<DropdownPair<string>> {
    const toReturn: Array<DropdownPair<string>> = [{ key: "NEW", label: this.$t("complaint.stateEnum.NEW").toString() },
    {
      key: "IN_PROGRESS",
      label: this.$t("complaint.stateEnum.IN_PROGRESS").toString(),
    },]
    if (complaint?.date) {
      toReturn.push({
        key: "ASSIGNED",
        label: this.$t("complaint.stateEnum.ASSIGNED").toString(),
      });
    }
    toReturn.push({ key: "DONE", label: this.$t("complaint.stateEnum.DONE").toString() })
    return toReturn;
  }
}
