
import { LocationDTO, OrderDTO, SupplierCalculationDTO, SupplierDTO } from "../api";
import { UserMixin } from "../mixins/user";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import OrderFiles from "./OrderFiles.vue";
import dayjs from "dayjs";
import { Getter } from "vuex-class";
import { RootGetters } from "@/store";
import { DropdownPair } from "@/interfaces";

@Component({
  name: "OrderSupplierStockDialog",
  components: { OrderFiles },
})
export default class OrderSupplierStockDialog extends mixins(UserMixin) {
  $refs!: {
    supplierFiles: HTMLFormElement;
  };

  @Prop({ required: true })
  order!: OrderDTO;
  @Prop({ default: true })
  canEditOrder!: boolean;
  @Prop({ default: false })
  dialog!: boolean;
  @Prop({ required: true })
  data!: SupplierCalculationDTO;

  @Getter(RootGetters.getComplaintLocations)
  locations!: LocationDTO[];
  @Getter(RootGetters.getSuppliers)
  suppliers!: SupplierDTO[];

  showConfirmedDeliveryDate: boolean = false;
  showDeliveryDate: boolean = false;
  calculationToEdit: SupplierCalculationDTO = {};

  toggleDelivered() {
    if (this.calculationToEdit.deliveryDate) {
      this.calculationToEdit.deliveryDate = undefined;
    } else {
      this.calculationToEdit.deliveryDate = dayjs().format("YYYY-MM-DD");
    }
  }

  async save() {
    await this.$refs.supplierFiles.executeFileQueue(this.order.id);
    this.$emit("saveStock", this.calculationToEdit);
  }

  cancel() {
    this.$emit("saveStock");
  }

  @Watch("dialog")
  resetValues() {
    this.calculationToEdit = { ...this.data };
    if (this.dialog) {
      this.$refs.supplierFiles.loadOrderFiles();
    }
  }

  get supplierName(): string {
    return (
      this.suppliers.find((entry) => entry.id === this.calculationToEdit.supplierId)
        ?.name || ""
    );
  }

  get confirmedDeliveryWeekString(): string {
    return this.calculationToEdit.confirmedDeliveryDate
      ? this.$t("complaint.calendarWeek", {
          value: `${dayjs(this.calculationToEdit.confirmedDeliveryDate).week()} (${dayjs(
            this.calculationToEdit.confirmedDeliveryDate
          ).format("YYYY")})`,
        }).toString()
      : "";
  }

  get locationValues(): Array<DropdownPair<string>> {
    return this.locations
      .filter((entry) => {
        if (!entry.id) {
          return true;
        }
        return entry.enabled;
      })
      .map((entry) => {
        if (entry.id) {
          return {
            key: entry.id,
            label: entry.name!,
          };
        }
        return {
          key: entry.name!,
          label: this.$t(`locationsEnum.${entry.name!}`).toString(),
        };
      });
  }
}
