
import {
  AdditionalPackageDTO,
  AdditionalPackageType,
  BasePackageDTO,
  BasePackageType,
  PackageOverviewDTO,
  TenantAccountingPeriod,
  TenantDTO,
} from "./../../api";
import { RootGetters, tenantApi } from "./../../store";
import Vue from "vue";
import Component from "vue-class-component";
import VCurrencyField from "./../../components/VCurrencyField.vue";
import { Getter } from "vuex-class";
import { DropdownPair } from "./../../interfaces";
import Navigation from "./../../components/Navigation.vue";

@Component({ components: { VCurrencyField, Navigation } })
export default class TenantOverview extends Vue {
  @Getter(RootGetters.getTableFooterProps)
  dataTableFooterProps: any;

  loading: boolean = false;
  showBasePackageEditDialog: boolean = false;
  showAdditionalPackageEditDialog: boolean = false;
  basePackageToEdit: BasePackageDTO = {
    maximumLocationCount: 1,
    maximumUserCount: 1,
    priceForAdditionalLocation: {},
    priceForAdditionalUser: {},
    price: {},
    type: BasePackageType.S,
  };
  additionalPackageToEdit: AdditionalPackageDTO = {
    type: AdditionalPackageType.Complaint,
    price: {},
  };
  packages: PackageOverviewDTO = { additionalPackages: [], basePackages: [] };
  tenants: TenantDTO[] = [];
  showTenantEditDialog: boolean = false;
  emptyTenant: TenantDTO = {
    accountingPeriod: TenantAccountingPeriod.Monthly,
    additionalLocationCount: 0,
    additionalPackageTypes: [],
    additionalUserCount: 0,
    address: "",
    basePackageType: BasePackageType.S,
    city: "",
    companyName: "",
    email: "",
    emailInvoice: "",
    enabled: false,
    lastInvoiceDate: "",
    licenseValidUntil: "",
    vat: "",
    zipCode: "",
  };
  tenantToEdit: TenantDTO = { ...this.emptyTenant };
  showLastInvoiceDate: boolean = false;
  showLicenseValidUntil: boolean = false;
  accountingPeriodValues: Array<DropdownPair<string>> = Object.keys(
    TenantAccountingPeriod
  ).map((entry: string) => ({
    key: (TenantAccountingPeriod as any)[entry],
    label: this.$t(
      `tenant.accountingPeriodEnum.${(TenantAccountingPeriod as any)[entry]}`
    ).toString(),
  }));
  basePackageTypeValues: Array<DropdownPair<string>> = Object.keys(BasePackageType).map(
    (entry: string) => ({
      key: (BasePackageType as any)[entry],
      label: this.$t(`basePackageTypeEnum.${(BasePackageType as any)[entry]}`).toString(),
    })
  );
  additionalPackageTypeValues: Array<DropdownPair<string>> = Object.keys(
    AdditionalPackageType
  ).map((entry: string) => ({
    key: (AdditionalPackageType as any)[entry],
    label: this.$t(
      `additionalPackageTypeEnum.${(AdditionalPackageType as any)[entry]}`
    ).toString(),
  }));

  basePackageHeaders = [
    { text: this.$t("name"), value: "type", sortable: false },
    {
      text: this.$t("tenant.price"),
      value: "price",
      sortable: false,
    },
    {
      text: this.$t("package.maximumUserCount"),
      value: "maximumUserCount",
      sortable: false,
    },
    {
      text: this.$t("package.maximumLocationCount"),
      value: "maximumLocationCount",
      sortable: false,
    },
    {
      text: this.$t("package.priceForAdditionalLocation"),
      value: "priceForAdditionalLocation",
      sortable: false,
    },
    {
      text: this.$t("package.priceForAdditionalUser"),
      value: "priceForAdditionalUser",
      sortable: false,
    },
    { text: "", align: "right", value: "actions", sortable: false },
  ];

  additionalPackageHeaders = [
    { text: this.$t("name"), value: "type", sortable: false },
    {
      text: this.$t("tenant.price"),
      value: "price",
      sortable: false,
    },
    { text: "", align: "right", value: "actions", sortable: false },
  ];

  tenantHeaders = [
    { text: this.$t("tenant.companyName"), value: "companyName" },
    { text: this.$t("createdAt"), value: "createdAt" },
    { text: this.$t("tenant.accountingPeriod"), value: "accountingPeriod" },
    { text: this.$t("tenant.lastInvoiceDate"), value: "lastInvoiceDate" },
    { text: this.$t("tenant.licenseValidUntil"), value: "licenseValidUntil" },
    {
      text: this.$t("tenant.price"),
      value: "price",
      sortable: false,
    },
    { text: "", align: "right", value: "actions", sortable: false },
  ];

  async mounted() {
    try {
      this.loading = true;
      this.packages = (await tenantApi.getPackages()).data;
      this.tenants = (await tenantApi.getTenants()).data;
    } finally {
      this.loading = false;
    }
  }

  // tslint:disable:variable-name
  editBasePackageDoubleClick(_event: any, element: any) {
    this.editBasePackage(element.item);
  }

  editBasePackage(basePackage: BasePackageDTO) {
    this.basePackageToEdit = JSON.parse(JSON.stringify(basePackage));
    this.showBasePackageEditDialog = true;
  }

  // tslint:disable:variable-name
  editAdditionalPackageDoubleClick(_event: any, element: any) {
    this.editAdditionalPackage(element.item);
  }

  editAdditionalPackage(additionalPackage: AdditionalPackageDTO) {
    this.additionalPackageToEdit = JSON.parse(JSON.stringify(additionalPackage));
    this.showAdditionalPackageEditDialog = true;
  }

  // tslint:disable:variable-name
  editTenantDoubleClick(_event: any, element: any) {
    this.editTenant(element.item);
  }

  editTenant(tenant: TenantDTO) {
    this.tenantToEdit = JSON.parse(JSON.stringify(tenant));
    this.showTenantEditDialog = true;
  }

  getTenantPrice(tenant: TenantDTO) {
    let price = 0;
    if (tenant.basePackageType) {
      const basePackage = this.packages.basePackages!.find(
        (element) => element.type === (tenant.basePackageType as any)
      )!;
      price += basePackage.price[tenant.accountingPeriod!];
      if (tenant.additionalLocationCount) {
        price +=
          tenant.additionalLocationCount *
          basePackage.priceForAdditionalLocation[tenant.accountingPeriod!];
      }
      if (tenant.additionalUserCount) {
        price +=
          tenant.additionalUserCount *
          basePackage.priceForAdditionalUser[tenant.accountingPeriod!];
      }
    }
    if (tenant.additionalPackageTypes?.length) {
      price += tenant.additionalPackageTypes
        .map(
          (type) =>
            this.packages.additionalPackages!.find(
              (element) => element.type === (type as any)
            )!.price[tenant.accountingPeriod!]
        )
        .reduce((a, b) => a + b, 0);
    }
    return price;
  }

  tenantRowClass(item: TenantDTO) {
    return item.enabled ? "" : "text--disabled";
  }

  async confirmBasePackageEdit() {
    this.showBasePackageEditDialog = false;
    try {
      this.loading = true;
      await tenantApi.updateBasePackage(this.basePackageToEdit);
      this.packages = (await tenantApi.getPackages()).data;
    } finally {
      this.loading = false;
    }
  }

  async confirmAdditionalPackageEdit() {
    this.showAdditionalPackageEditDialog = false;
    try {
      this.loading = true;
      await tenantApi.updateAdditionalPackage(this.additionalPackageToEdit);
      this.packages = (await tenantApi.getPackages()).data;
    } finally {
      this.loading = false;
    }
  }

  async confirmTenantEdit() {
    this.showTenantEditDialog = false;
    try {
      this.loading = true;
      await tenantApi.createOrUpdateTenant({
        ...this.emptyTenant,
        ...this.tenantToEdit,
      });
      this.tenants = (await tenantApi.getTenants()).data;
    } finally {
      this.loading = false;
    }
  }

  get validBasePackageValues() {
    if (
      !this.basePackageToEdit.maximumUserCount ||
      !this.basePackageToEdit.maximumLocationCount
    ) {
      return false;
    }
    if (!this.basePackageToEdit.price.YEARLY || !this.basePackageToEdit.price.MONTHLY) {
      return false;
    }
    if (
      !this.basePackageToEdit.priceForAdditionalLocation.YEARLY ||
      !this.basePackageToEdit.priceForAdditionalLocation.MONTHLY
    ) {
      return false;
    }
    if (
      !this.basePackageToEdit.priceForAdditionalUser.YEARLY ||
      !this.basePackageToEdit.priceForAdditionalUser.MONTHLY
    ) {
      return false;
    }
    return true;
  }
}
