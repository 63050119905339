
import {
  ExhibitionProvisionValue,
  ExportFileType,
  ProvisionDTO,
  SalesProvisionValue,
  UserRight,
  UserRole,
} from "../api";
import Component, { mixins } from "vue-class-component";
import { userApi } from "./../store";
import { Prop, Watch } from "vue-property-decorator";
import { UserMixin } from "./../mixins/user";
import { DropdownPair } from "./../interfaces";
import { scrollToFirstValidationError } from "./../helpers";
import { ValidationProvider } from "vee-validate";

export class PrintSelectorParams {
  type?: ExportFileType;
  id?: string;
}

interface RightGrouping {
  groupName: string;
  rights: RightGroupingRight[];
}

interface RightGroupingRight {
  type: string;
  actions?: string[];
  child?: string;
  subrights?: SubrightGrouping[];
  enableDisableCheckbox?: boolean;
}

interface SubrightGrouping {
  groupName: string;
  groupRight?: UserRight;
  rights: RightGroupingRight[];
}

@Component
export default class RightManagement extends mixins(UserMixin) {
  $refs!: {
    validationObserver: InstanceType<typeof ValidationProvider>;
  };

  resetDialog: boolean = false;
  resetProvisionDialog: boolean = false;
  loading: boolean = false;
  initialUserRights: UserRight[] = [];
  userRights: UserRight[] = [];
  initialProvision: ProvisionDTO = {
    salesProvision: { percentage: 6, value: SalesProvisionValue.Margin },
    exhibitionProvision: {
      exhibitionPercentage: 10,
      exhibitionValue: ExhibitionProvisionValue.Price,
      salesPercentage: 1.75,
      salesValue: SalesProvisionValue.SellingSum,
    },
  };
  provision: ProvisionDTO = {
    salesProvision: { percentage: 6, value: SalesProvisionValue.Margin },
    exhibitionProvision: {
      exhibitionPercentage: 10,
      exhibitionValue: ExhibitionProvisionValue.Price,
      salesPercentage: 1.75,
      salesValue: SalesProvisionValue.SellingSum,
    },
  };

  @Prop()
  userRole?: UserRole;
  @Prop()
  userId?: string;

  salesProvisionValueValues: Array<DropdownPair<SalesProvisionValue>> = [
    {
      key: SalesProvisionValue.SellingSum,
      label: this.$t("order.sellingSum").toString(),
    },
    {
      key: SalesProvisionValue.SellingSumGross,
      label: this.$t("order.sellingSumGross").toString(),
    },
    {
      key: SalesProvisionValue.Margin,
      label: this.$t("order.margin").toString(),
    },
  ];

  exhibitionProvisionValueValues: Array<DropdownPair<ExhibitionProvisionValue>> = [
    {
      key: ExhibitionProvisionValue.Price,
      label: this.$t("exhibitionProvisionEnum.EXHIBITON_PRICE").toString(),
    },
    {
      key: ExhibitionProvisionValue.PriceGross,
      label: this.$t("exhibitionProvisionEnum.EXHIBITON_PRICE_GROSS").toString(),
    },
  ];

  async resetUserRights() {
    this.loading = true;
    try {
      if (this.userRole) {
        this.initialUserRights = (
          await userApi.resetUserRightsForRole(this.userRole)
        ).data;
      } else if (this.userId) {
        this.initialUserRights = (await userApi.resetUserRightsForUser(this.userId)).data;
      }
      this.userRights = [...this.initialUserRights];
    } finally {
      this.loading = false;
    }
    this.resetDialog = false;
  }

  async resetProvision() {
    this.loading = true;
    try {
      this.initialProvision = (await userApi.resetProvisionForUser(this.userId!)).data;
      this.provision = JSON.parse(JSON.stringify(this.initialProvision));
    } finally {
      this.loading = false;
    }
    this.resetProvisionDialog = false;
  }

  saveUserRights() {
    if (this.userRole) {
      userApi.setUserRightsForRole(this.userRole, this.userRights);
    } else if (this.userId) {
      userApi.setUserRightsForUser(this.userId, this.userRights);
    }
    this.initialUserRights = [...this.userRights];
  }

  @Watch("userRole", { immediate: true })
  @Watch("userId", { immediate: true })
  async updateUserRights() {
    this.loading = true;
    try {
      if (this.userRole) {
        this.initialUserRights = (await userApi.getUserRightsForRole(this.userRole)).data;
      } else if (this.userId) {
        this.initialUserRights = (await userApi.getUserRightsForUser(this.userId)).data;
      }
      this.userRights = [...this.initialUserRights];
      if (this.userRole === UserRole.Salesman || this.userId) {
        this.initialProvision = (await userApi.getProvision(this.userId)).data;
        this.provision = JSON.parse(JSON.stringify(this.initialProvision));
      }
    } finally {
      this.loading = false;
    }
  }

  async saveProvision() {
    if (!(await this.$refs.validationObserver.validate())) {
      this.$nextTick(() => scrollToFirstValidationError());
      return;
    }
    if (this.userId) {
      userApi.updateProvisionForUser(this.userId, this.provision);
    } else {
      userApi.updateProvision(this.provision);
    }
    this.initialProvision = JSON.parse(JSON.stringify(this.provision));
  }

  get hasChanges() {
    return (
      JSON.stringify(this.initialUserRights.sort()) !==
      JSON.stringify(this.userRights.sort())
    );
  }

  get hasProvisionChanges() {
    return JSON.stringify(this.initialProvision) !== JSON.stringify(this.provision);
  }

  get rightGroupings(): RightGrouping[] {
    let toolRights = this.hasComplaintPackage
      ? [
          {
            type: "OWN_COMPLAINT",
            actions: ["READ", "WRITE", "DELETE"],
            child: "OTHER_COMPLAINT",
          },
          {
            type: "OTHER_COMPLAINT",
            actions: ["READ", "WRITE", "DELETE"],
          },
        ]
      : [];
    if (this.hasSalesStatisticsPackage) {
      toolRights.push({
        type: "SALES_STATISTICS",
        actions: ["READ", "WRITE"],
      });
    }
    toolRights = [
      ...toolRights,
      {
        type: "WEEK_OVERVIEW",
        actions: ["READ", "WRITE"],
      },
      {
        type: "BASE_DATA",
        actions: ["READ", "WRITE", "DELETE"],
      },
    ];

    return [
      {
        groupName: this.$t("orders").toString(),
        rights: [
          {
            type: "OWN_ORDER",
            actions: ["READ", "WRITE", "DELETE"],
            child: "OTHER_ORDER",
            subrights: this.userRights.includes(UserRight.OwnOrderRead)
              ? [
                  {
                    groupName: this.$t("calculation").toString(),
                    groupRight: UserRight.OwnOrderCalculationRead,
                    rights: this.userRights.includes(UserRight.OwnOrderCalculationRead)
                      ? [
                          {
                            type: "OWN_ORDER_SUPPLIER_DATA",
                            actions: this.userRights.includes(UserRight.OwnOrderWrite)
                              ? ["READ", "WRITE"]
                              : ["READ"],
                          },
                          {
                            type: "OWN_ORDER_MARGIN",
                            actions: ["READ"],
                          },
                          {
                            type: "OWN_ORDER_EXPENSES",
                            actions: this.userRights.includes(UserRight.OwnOrderWrite)
                              ? ["READ", "WRITE"]
                              : ["READ"],
                          },
                          {
                            type: "OWN_ORDER_MARGIN_SUM",
                            actions: ["READ"],
                          },
                          {
                            type: "OWN_ORDER_TARGET_MARGIN",
                            actions: ["READ"],
                          },
                        ]
                      : [],
                  },
                  {
                    groupName: this.$t("order.priceAndPaymentHeadline").toString(),
                    groupRight: UserRight.OwnOrderPriceAndPaymentRead,
                    rights: this.userRights.includes(
                      UserRight.OwnOrderPriceAndPaymentRead
                    )
                      ? [
                          ...(this.hasExhibitionPackage
                            ? [
                                {
                                  type: "OWN_ORDER_EXHIBITION_PRICE",
                                  actions: this.userRights.includes(
                                    UserRight.OwnOrderWrite
                                  )
                                    ? ["READ", "WRITE"]
                                    : ["READ"],
                                },
                              ]
                            : []),
                          {
                            type: "OWN_ORDER_ORDER_PRICE",
                            actions: this.userRights.includes(UserRight.OwnOrderWrite)
                              ? ["READ", "WRITE"]
                              : ["READ"],
                          },
                          {
                            type: "OWN_ORDER_CHANGES",
                            actions: this.userRights.includes(UserRight.OwnOrderWrite)
                              ? ["READ", "WRITE"]
                              : ["READ"],
                          },
                          {
                            type: "OWN_ORDER_PRE_PAYMENTS",
                            actions: this.userRights.includes(UserRight.OwnOrderWrite)
                              ? ["READ", "WRITE"]
                              : ["READ"],
                          },
                          {
                            type: "OWN_ORDER_REMAINING_AMOUNT",
                            actions: ["READ"],
                          },
                        ]
                      : [],
                  },
                  {
                    groupName: this.$t("orderOverview").toString(),
                    rights: [
                      {
                        type: "OWN_ORDER_OVERVIEW_MARGIN",
                        actions: ["READ"],
                      },
                      {
                        type: "OWN_ORDER_OVERVIEW_TARGET_MARGIN",
                        actions: ["READ"],
                      },
                      {
                        type: "OWN_ORDER_OVERVIEW_MARGIN_SUM",
                        actions: ["READ"],
                      },
                      {
                        type: "OWN_ORDER_OVERVIEW_TARGET_MARGIN_SUM",
                        actions: ["READ"],
                      },
                      {
                        type: "OWN_ORDER_OVERVIEW_PRE_PAYMENTS",
                        actions: ["READ"],
                      },
                      {
                        type: "OWN_ORDER_OVERVIEW_REMAINING_AMOUNT",
                        actions: ["READ"],
                      },
                      {
                        type: "OWN_ORDER_OVERVIEW_SELLING_SUM",
                        actions: ["READ"],
                      },
                      {
                        type: "OWN_ORDER_OVERVIEW_SELLING_SUM_GROSS",
                        actions: ["READ"],
                      },
                      {
                        type: "OWN_ORDER_OVERVIEW_DELIVERY_SUM",
                        actions: ["READ"],
                      },
                      {
                        type: "OWN_ORDER_OVERVIEW_EXPENSES",
                        actions: ["READ"],
                      },
                      {
                        type: "OWN_ORDER_OVERVIEW_SALESMAN_PROVISION",
                        actions: ["READ"],
                      },
                      ...(this.hasExhibitionPackage
                        ? [
                            {
                              type: "OWN_ORDER_OVERVIEW_PROVISION_EXHIBITON",
                              actions: ["READ"],
                            },
                            {
                              type: "OWN_ORDER_OVERVIEW_EXHIBITON_PRICE",
                              actions: ["READ"],
                            },
                          ]
                        : []),
                    ],
                  },
                ]
              : [],
          },
          {
            type: "OTHER_ORDER",
            actions: ["READ", "WRITE", "DELETE"],
            subrights: this.userRights.includes(UserRight.OtherOrderRead)
              ? [
                  {
                    groupName: "Allgemeines",
                    rights: [
                      {
                        type: "OTHER_ORDER_CHANGE_SALESMAN",
                        enableDisableCheckbox: true,
                      },
                    ],
                  },
                  {
                    groupName: this.$t("calculation").toString(),
                    groupRight: UserRight.OtherOrderCalculationRead,
                    rights: this.userRights.includes(UserRight.OtherOrderCalculationRead)
                      ? [
                          {
                            type: "OTHER_ORDER_SUPPLIER_DATA",
                            actions: this.userRights.includes(UserRight.OtherOrderWrite)
                              ? ["READ", "WRITE"]
                              : ["READ"],
                          },
                          {
                            type: "OTHER_ORDER_MARGIN",
                            actions: ["READ"],
                          },
                          {
                            type: "OTHER_ORDER_EXPENSES",
                            actions: this.userRights.includes(UserRight.OtherOrderWrite)
                              ? ["READ", "WRITE"]
                              : ["READ"],
                          },
                          {
                            type: "OTHER_ORDER_MARGIN_SUM",
                            actions: ["READ"],
                          },
                          {
                            type: "OTHER_ORDER_TARGET_MARGIN",
                            actions: ["READ"],
                          },
                        ]
                      : [],
                  },
                  {
                    groupName: this.$t("order.priceAndPaymentHeadline").toString(),
                    groupRight: UserRight.OtherOrderPriceAndPaymentRead,
                    rights: this.userRights.includes(
                      UserRight.OtherOrderPriceAndPaymentRead
                    )
                      ? [
                          ...(this.hasExhibitionPackage
                            ? [
                                {
                                  type: "OTHER_ORDER_EXHIBITION_PRICE",
                                  actions: this.userRights.includes(
                                    UserRight.OtherOrderWrite
                                  )
                                    ? ["READ", "WRITE"]
                                    : ["READ"],
                                },
                              ]
                            : []),
                          {
                            type: "OTHER_ORDER_ORDER_PRICE",
                            actions: this.userRights.includes(UserRight.OtherOrderWrite)
                              ? ["READ", "WRITE"]
                              : ["READ"],
                          },
                          {
                            type: "OTHER_ORDER_CHANGES",
                            actions: this.userRights.includes(UserRight.OtherOrderWrite)
                              ? ["READ", "WRITE"]
                              : ["READ"],
                          },
                          {
                            type: "OTHER_ORDER_PRE_PAYMENTS",
                            actions: this.userRights.includes(UserRight.OtherOrderWrite)
                              ? ["READ", "WRITE"]
                              : ["READ"],
                          },
                          {
                            type: "OTHER_ORDER_REMAINING_AMOUNT",
                            actions: ["READ"],
                          },
                        ]
                      : [],
                  },
                  {
                    groupName: this.$t("orderOverview").toString(),
                    rights: [
                      {
                        type: "OTHER_ORDER_OVERVIEW_MARGIN",
                        actions: ["READ"],
                      },
                      {
                        type: "OTHER_ORDER_OVERVIEW_TARGET_MARGIN",
                        actions: ["READ"],
                      },
                      {
                        type: "OTHER_ORDER_OVERVIEW_MARGIN_SUM",
                        actions: ["READ"],
                      },
                      {
                        type: "OTHER_ORDER_OVERVIEW_TARGET_MARGIN_SUM",
                        actions: ["READ"],
                      },
                      {
                        type: "OTHER_ORDER_OVERVIEW_PRE_PAYMENTS",
                        actions: ["READ"],
                      },
                      {
                        type: "OTHER_ORDER_OVERVIEW_REMAINING_AMOUNT",
                        actions: ["READ"],
                      },
                      {
                        type: "OTHER_ORDER_OVERVIEW_SELLING_SUM",
                        actions: ["READ"],
                      },
                      {
                        type: "OTHER_ORDER_OVERVIEW_SELLING_SUM_GROSS",
                        actions: ["READ"],
                      },
                      {
                        type: "OTHER_ORDER_OVERVIEW_DELIVERY_SUM",
                        actions: ["READ"],
                      },
                      {
                        type: "OTHER_ORDER_OVERVIEW_EXPENSES",
                        actions: ["READ"],
                      },
                      {
                        type: "OTHER_ORDER_OVERVIEW_SALESMAN_PROVISION",
                        actions: ["READ"],
                      },
                      ...(this.hasExhibitionPackage
                        ? [
                            {
                              type: "OTHER_ORDER_OVERVIEW_PROVISION_EXHIBITON",
                              actions: ["READ"],
                            },
                            {
                              type: "OTHER_ORDER_OVERVIEW_EXHIBITON_PRICE",
                              actions: ["READ"],
                            },
                          ]
                        : []),
                    ],
                  },
                ]
              : [],
          },
        ],
      },
      {
        groupName: this.$t("printSelection").toString(),
        rights: [
          {
            type: "PRINT_ORDER_OVERVIEW",
            enableDisableCheckbox: true,
          },
          {
            type: "PRINT_ORDER_DETAIL",
            enableDisableCheckbox: true,
          },
        ],
      },
      {
        groupName: this.$t("tools").toString(),
        rights: toolRights,
      },
    ];
  }

  toggleSingleUserRight(right?: UserRight) {
    if (!right) {
      return;
    }
    const userRightIndex = this.userRights.indexOf(right);
    if (userRightIndex >= 0) {
      this.userRights.splice(userRightIndex, 1);
    } else {
      this.userRights.push(right);
    }
  }

  toggleSingleUserRightString(right: string) {
    this.toggleSingleUserRight(right as UserRight);
  }

  toggleUserRight(
    right: string,
    rightGroupingIndex: number,
    rightIndex: number,
    actionIndex: number,
    onlyDeselect: boolean = false
  ) {
    const roleIndex = this.userRights.indexOf(right as UserRight);
    if (roleIndex < 0 && !onlyDeselect) {
      this.userRights.push(right as UserRight);
    } else {
      this.userRights.splice(roleIndex, 1);
      const currentRight = this.rightGroupings[rightGroupingIndex].rights[rightIndex];
      if (currentRight.child) {
        const childRightIndex = this.rightGroupings[rightGroupingIndex].rights.findIndex(
          (element) => element.type === currentRight.child
        );
        const newRight = this.rightGroupings[rightGroupingIndex].rights[childRightIndex];
        const newAction = newRight.actions![actionIndex];
        this.toggleUserRight(
          `${newRight.type}_${newAction}` as UserRight,
          rightGroupingIndex,
          childRightIndex,
          actionIndex,
          true
        );
      }
      for (
        let nextAction = actionIndex + 1;
        nextAction < currentRight.actions!.length;
        ++nextAction
      ) {
        const nextRight: UserRight = `${currentRight.type}_${
          currentRight.actions![nextAction]
        }` as UserRight;
        const nextRightIndex = this.userRights.indexOf(nextRight);
        if (nextRightIndex >= 0) {
          this.userRights.splice(nextRightIndex, 1);
        }
      }
    }
  }

  toggleUserSubright(
    right: string,
    rightGroupingIndex: number,
    rightIndex: number,
    subrightGroupingIndex: number,
    subrightIndex: number,
    actionIndex: number
  ) {
    const roleIndex = this.userRights.indexOf(right as UserRight);
    if (roleIndex < 0) {
      this.userRights.push(right as UserRight);
    } else {
      this.userRights.splice(roleIndex, 1);
      const currentRight = this.rightGroupings[rightGroupingIndex].rights[rightIndex]
        .subrights![subrightGroupingIndex].rights[subrightIndex];
      for (
        let nextAction = actionIndex + 1;
        nextAction < currentRight.actions!.length;
        ++nextAction
      ) {
        const nextRight: UserRight = `${currentRight.type}_${
          currentRight.actions![nextAction]
        }` as UserRight;
        const nextRightIndex = this.userRights.indexOf(nextRight);
        if (nextRightIndex >= 0) {
          this.userRights.splice(nextRightIndex, 1);
        }
      }
    }
  }

  getColorForAction(action: string): string {
    return action === "READ" ? "accent" : action === "WRITE" ? "primary" : "error";
  }

  showAction(type: string, action: string): boolean {
    if (action === "READ") {
      return true;
    }
    const previousAction: string = action === "WRITE" ? "READ" : "WRITE";
    return this.userRights.includes(`${type}_${previousAction}` as UserRight);
  }

  hasUserRightString(userRight: string): boolean {
    return this.userRights.includes(userRight as UserRight);
  }
}
