
import {
  DefaultValuesDTO,
  ExhibitionProvisionValue,
  SalesProvisionValue,
  TargetCompareValue,
} from "../api";
import VCurrencyField from "./VCurrencyField.vue";
import Component, { mixins } from "vue-class-component";
import { RootActions, RootGetters } from "../store";
import { UserMixin } from "../mixins/user";
import { DropdownPair } from "../interfaces";
import { Action, Getter } from "vuex-class";
import { ValidationProvider } from "vee-validate";
import { scrollToFirstValidationError } from "./../helpers";
import { Watch } from "vue-property-decorator";

@Component({ components: { VCurrencyField } })
export default class RightManagement extends mixins(UserMixin) {
  $refs!: {
    validationObserver: InstanceType<typeof ValidationProvider>;
  };

  @Getter(RootGetters.getDefaultValues)
  defaultValues!: DefaultValuesDTO;

  @Action(RootActions.updateDefaultValues)
  updateDefaultValues!: (payload: DefaultValuesDTO) => void;

  initialDefaultValues: string = "";
  showListGroupProvision: boolean = true;
  showListGroupCalculation: boolean = true;

  salesProvisionValueValues: Array<DropdownPair<SalesProvisionValue>> = [
    {
      key: SalesProvisionValue.SellingSum,
      label: this.$t("order.sellingSum").toString(),
    },
    {
      key: SalesProvisionValue.SellingSumGross,
      label: this.$t("order.sellingSumGross").toString(),
    },
    {
      key: SalesProvisionValue.Margin,
      label: this.$t("order.margin").toString(),
    },
  ];

  exhibitionProvisionValueValues: Array<DropdownPair<ExhibitionProvisionValue>> = [
    {
      key: ExhibitionProvisionValue.Price,
      label: this.$t("exhibitionProvisionEnum.EXHIBITON_PRICE").toString(),
    },
    {
      key: ExhibitionProvisionValue.PriceGross,
      label: this.$t("exhibitionProvisionEnum.EXHIBITON_PRICE_GROSS").toString(),
    },
  ];

  targetCompareValueValues: Array<DropdownPair<TargetCompareValue>> = [
    {
      key: TargetCompareValue.Sum,
      label: this.$t("order.sellingSum").toString(),
    },
    {
      key: TargetCompareValue.SumGross,
      label: this.$t("order.sellingSumGross").toString(),
    },
  ];

  created() {
    this.initialDefaultValues = JSON.stringify(this.defaultValues);
  }

  async saveDefaultValues() {
    if (!(await this.$refs.validationObserver.validate())) {
      this.$nextTick(() => scrollToFirstValidationError());
      return;
    }
    this.updateDefaultValues(this.defaultValues);
    this.initialDefaultValues = JSON.stringify(this.defaultValues);
  }

  get hasChanges() {
    return this.initialDefaultValues !== JSON.stringify(this.defaultValues);
  }
}
