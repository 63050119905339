
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({ name: "DeleteConfirmation", components: {} })
export default class DeleteConfirmation extends Vue {
  @Prop({ default: false })
  dialog!: boolean;
  @Prop()
  message?: string;

  confirmDelete(doDelete: boolean) {
    this.$emit("confirmDelete", doDelete);
  }
}
