
import Component, { mixins } from "vue-class-component";
import Dashboard from "./Dashboard.vue";
import PictureApp from "./PictureApp.vue";
import { UserMixin } from "../mixins/user";

@Component({
  components: {
    Dashboard,
    PictureApp,
  },
})
export default class Home extends mixins(UserMixin) {}
