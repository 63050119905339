import {
  AuthenticationControllerApi,
  BaseDataControllerApi,
  BaseDataDTO,
  CarpenterDTO,
  CarpenterGetDTO,
  Configuration,
  OrderControllerApi,
  OrderDTO,
  OrdersGetDTO,
  OrderOverviewDTO,
  SupplierDTO,
  SupplierGetDTO,
  UserDTO,
  UserGetDTO,
  ComplaintControllerApi,
  ComplaintOverviewDTO,
  ComplaintDTO,
  FileControllerApi,
  ExportControllerApi,
  GlobalValueControllerApi,
  TenantControllerApi,
  GlobalValueKey,
  LocationDTO,
  ConstraintDTO,
  UserControllerApi,
  CurrentUserDTO,
  ExhibitionSalesmanDTO,
  DefaultValuesDTO,
  LeadSourceDTO,
  DocumentDataSettingDTO,
  DocumentType,
} from "./../api";
import Vue from "vue";
import Vuex from "vuex";
import { SortableEvent } from "sortablejs";

Vue.use(Vuex);

export const authenticationApi: AuthenticationControllerApi =
  new AuthenticationControllerApi(
    new Configuration({
      basePath: process.env.VUE_APP_BASE_PATH || window.location.origin,
    })
  );

export const userApi: UserControllerApi = new UserControllerApi(
  new Configuration({
    basePath: process.env.VUE_APP_BASE_PATH || window.location.origin,
  })
);

export const baseApi: BaseDataControllerApi = new BaseDataControllerApi(
  new Configuration({
    basePath: process.env.VUE_APP_BASE_PATH || window.location.origin,
  })
);

export const orderApi: OrderControllerApi = new OrderControllerApi(
  new Configuration({
    basePath: process.env.VUE_APP_BASE_PATH || window.location.origin,
  })
);

export const complaintApi: ComplaintControllerApi = new ComplaintControllerApi(
  new Configuration({
    basePath: process.env.VUE_APP_BASE_PATH || window.location.origin,
  })
);

export const fileApi: FileControllerApi = new FileControllerApi(
  new Configuration({
    basePath: process.env.VUE_APP_BASE_PATH || window.location.origin,
  })
);

export const exportApi: ExportControllerApi = new ExportControllerApi(
  new Configuration({
    basePath: process.env.VUE_APP_BASE_PATH || window.location.origin,
  })
);

export const globalValueApi: GlobalValueControllerApi =
  new GlobalValueControllerApi(
    new Configuration({
      basePath: process.env.VUE_APP_BASE_PATH || window.location.origin,
    })
  );

export const tenantApi: TenantControllerApi = new TenantControllerApi(
  new Configuration({
    basePath: process.env.VUE_APP_BASE_PATH || window.location.origin,
  })
);

interface ArrayUpdatePayload {
  index: number;
  item: any;
}

const set = (property: string) => (storeValue: any, payload: any) =>
  Vue.set(storeValue, property, payload);
const addToArray = (property: string) => (storeValue: any, payload: any) =>
  storeValue[property].push(payload);
const updateFromArray =
  (property: string) => (storeValue: any, payload: ArrayUpdatePayload) =>
    Vue.set(storeValue[property], payload.index, payload.item);
const removeFromArray =
  (property: string) => (storeValue: any, index: number) =>
    index >= 0 ? storeValue[property].splice(index, 1) : null;
export interface RootState {
  currentUser?: CurrentUserDTO;
  locations?: LocationDTO[];
  constraint?: ConstraintDTO;
  carpenters?: CarpenterGetDTO[];
  suppliers?: SupplierGetDTO[];
  exhibitionSalesmen?: ExhibitionSalesmanDTO[];
  leadSources?: LeadSourceDTO[];
  orders?: OrderOverviewDTO[];
  exhibitionOrders?: OrderOverviewDTO[];
  complaints?: ComplaintOverviewDTO[];
  users?: UserGetDTO[];
  lastOrderLockDate?: string;
  defaultValues?: DefaultValuesDTO;
  defaultDocumentDataSettings?: { [key: string]: DocumentDataSettingDTO; };
}

export enum RootMutations {
  setCurrentUser = "setCurrentUser",
  setLocations = "setLocations",
  setConstraint = "setConstraint",
  setCarpenters = "setCarpenters",
  setSuppliers = "setSuppliers",
  setExhibitionSalesmen = "setExhibitionSalesmen",
  setLeadSources = "setLeadSources",
  setOrders = "setOrders",
  setExhibitionOrders = "setExhibitionOrders",
  setComplaints = "setComplaints",
  setUsers = "setUsers",
  setLastOrderLockDate = "setLastOrderLockDate",
  setDefaultValues = "setDefaultValues",
  setDefaultDocumentDataSetting = "setDefaultDocumentDataSetting",
  addLocation = "addLocation",
  addCarpenter = "addCarpenter",
  addSupplier = "addSupplier",
  addExhibitionSalesman = "addExhibitionSalesman",
  addLeadSource = "addLeadSource",
  addOrder = "addOrder",
  addExhibitionOrder = "addExhibitionOrder",
  addComplaint = "addComplaint",
  addUser = "addUser",
  updateLocation = "updateLocation",
  updateCarpenter = "updateCarpenter",
  updateSupplier = "updateSupplier",
  updateExhibitionSalesman = "updateExhibitionSalesman",
  updateLeadSource = "updateLeadSource",
  updateOrder = "updateOrder",
  updateComplaint = "updateComplaint",
  updateExhibitionOrder = "updateExhibitionOrder",
  updateUser = "updateUser",
  removeCarpenter = "removeCarpenter",
  removeSupplier = "removeSupplier",
  removeExhibitionSalesman = "removeExhibitionSalesman",
  removeLeadSource = "removeLeadSource",
  removeOrder = "removeOrder",
  removeExhibitionOrder = "removeExhibitionOrder",
  removeComplaint = "removeComplaint",
  removeUser = "removeUser",
}

export enum RootActions {
  getInitialData = "getInitialData",
  getCurrentUser = "getCurrentUser",
  resetCurrentUser = "resetCurrentUser",
  getBaseData = "getBaseData",
  getOrders = "getOrders",
  getComplaints = "getComplaints",
  getUsers = "getUsers",
  getLastOrderLockDate = "getLastOrderLockDate",
  getDefaultValues = "getDefaultValues",
  getDefaultDocumentDataSettings = "getDefaultDocumentDataSettings",
  updateDefaultValues = "updateDefaultValues",
  createOrUpdateLocation = "createOrUpdateLocation",
  reorderLocations = "reorderLocations",
  createOrUpdateCarpenter = "createOrUpdateCarpenter",
  reorderCarpenters = "reorderCarpenters",
  createOrUpdateSupplier = "createOrUpdateSupplier",
  reorderSuppliers = "reorderSuppliers",
  createOrUpdateExhibitionSalesman = "createOrUpdateExhibitionSalesman",
  reorderExhibitionSalesmen = "reorderExhibitionSalesmen",
  createOrUpdateLeadSource = "createOrUpdateLeadSource",
  reorderLeadSources = "reorderLeadSource",
  createOrUpdateOrder = "createOrUpdateOrder",
  createOrUpdateComplaint = "createOrUpdateComplaint",
  createOrUpdateUser = "createOrUpdateUser",
  createOrUpdateDefaultDocumentDataSetting = "createOrUpdateDefaultDocumentDataSetting",
  lockOrders = "lockOrders",
  unlockOrders = "unlockOrders",
  deleteCarpenter = "deleteCarpenter",
  deleteSupplier = "deleteSupplier",
  deleteExhibitionSalesman = "deleteExhibitionSalesman",
  deleteLeadSource = "deleteLeadSource",
  deleteOrder = "deleteOrder",
  deleteComplaint = "deleteComplaint",
  deleteUser = "deleteUser",
}

export enum RootGetters {
  getCurrentUser = "getCurrentUser",
  getCarpenters = "getCarpenters",
  getSuppliers = "getSuppliers",
  getExhibitionSalesmen = "getExhibitionSalesmen",
  getLeadSources = "getLeadSources",
  getOrders = "getOrders",
  getComplaints = "getComplaints",
  getExhibitionOrders = "getExhibitionOrders",
  getLocations = "getLocations",
  getConstraint = "getConstraint",
  getComplaintLocations = "getComplaintLocations",
  getUsers = "getUsers",
  getTableFooterProps = "getTableFooterProps",
  getLastOrderLockDate = "getLastOrderLockDate",
  getDefaultValues = "getDefaultValues",
  getDefaultDocumentDataSettings = "getDefaultDocumentDataSettings",
  getBasePath = "getBasePath",
}

const store = new Vuex.Store<RootState>({
  state: {},
  mutations: {
    [RootMutations.setCurrentUser]: set("currentUser"),
    [RootMutations.setLocations]: set("locations"),
    [RootMutations.setConstraint]: set("constraint"),
    [RootMutations.setCarpenters]: set("carpenters"),
    [RootMutations.setSuppliers]: set("suppliers"),
    [RootMutations.setExhibitionSalesmen]: set("exhibitionSalesmen"),
    [RootMutations.setLeadSources]: set("leadSources"),
    [RootMutations.setOrders]: set("orders"),
    [RootMutations.setExhibitionOrders]: set("exhibitionOrders"),
    [RootMutations.setComplaints]: set("complaints"),
    [RootMutations.setUsers]: set("users"),
    [RootMutations.setLastOrderLockDate]: set("lastOrderLockDate"),
    [RootMutations.setDefaultValues]: set("defaultValues"),
    [RootMutations.setDefaultDocumentDataSetting]: set("defaultDocumentDataSettings"),
    [RootMutations.addLocation]: addToArray("locations"),
    [RootMutations.addCarpenter]: addToArray("carpenters"),
    [RootMutations.addSupplier]: addToArray("suppliers"),
    [RootMutations.addExhibitionSalesman]: addToArray("exhibitionSalesmen"),
    [RootMutations.addLeadSource]: addToArray("leadSources"),
    [RootMutations.addOrder]: addToArray("orders"),
    [RootMutations.addExhibitionOrder]: addToArray("exhibitionOrders"),
    [RootMutations.addComplaint]: addToArray("complaints"),
    [RootMutations.addUser]: addToArray("users"),
    [RootMutations.updateLocation]: updateFromArray("locations"),
    [RootMutations.updateCarpenter]: updateFromArray("carpenters"),
    [RootMutations.updateSupplier]: updateFromArray("suppliers"),
    [RootMutations.updateExhibitionSalesman]:
      updateFromArray("exhibitionSalesmen"),
    [RootMutations.updateLeadSource]: updateFromArray("leadSources"),
    [RootMutations.updateOrder]: updateFromArray("orders"),
    [RootMutations.updateExhibitionOrder]: updateFromArray("exhibitionOrders"),
    [RootMutations.updateComplaint]: updateFromArray("complaints"),
    [RootMutations.updateUser]: updateFromArray("users"),
    [RootMutations.removeCarpenter]: removeFromArray("carpenters"),
    [RootMutations.removeSupplier]: removeFromArray("suppliers"),
    [RootMutations.removeExhibitionSalesman]:
      removeFromArray("exhibitionSalesmen"),
    [RootMutations.removeLeadSource]: removeFromArray("leadSources"),
    [RootMutations.removeOrder]: removeFromArray("orders"),
    [RootMutations.removeExhibitionOrder]: removeFromArray("exhibitionOrders"),
    [RootMutations.removeComplaint]: removeFromArray("complaints"),
    [RootMutations.removeUser]: removeFromArray("users"),
  },
  actions: {
    [RootActions.getInitialData]: async ({ dispatch, state }) => {
      dispatch(RootActions.getCurrentUser);
      dispatch(RootActions.getBaseData);
      dispatch(RootActions.getUsers);
      dispatch(RootActions.getLastOrderLockDate);
      dispatch(RootActions.getDefaultValues);
      dispatch(RootActions.getDefaultDocumentDataSettings);
    },
    [RootActions.getCurrentUser]: async ({ commit }) => {
      const user: CurrentUserDTO = (await authenticationApi.getCurrentUser())
        .data;
      commit(RootMutations.setCurrentUser, user);
    },
    [RootActions.resetCurrentUser]: async ({ commit }) => {
      commit(RootMutations.setCurrentUser, {});
    },
    [RootActions.getBaseData]: async ({ commit }) => {
      const baseData: BaseDataDTO = (await baseApi.getBaseData()).data;
      commit(RootMutations.setCarpenters, baseData.carpenters);
      commit(RootMutations.setSuppliers, baseData.suppliers);
      commit(RootMutations.setExhibitionSalesmen, baseData.exhibitionSalesmen);
      commit(RootMutations.setLocations, baseData.locations);
      commit(RootMutations.setLeadSources, baseData.leadSources);
      commit(RootMutations.setConstraint, baseData.constraint);
    },
    [RootActions.getOrders]: async ({ commit }) => {
      const orderResponse: OrdersGetDTO = (await orderApi.getOrders()).data;
      commit(RootMutations.setOrders, orderResponse.orders);
      commit(RootMutations.setExhibitionOrders, orderResponse.exhibitionOrders);
    },
    [RootActions.getComplaints]: async ({ commit }) => {
      const complaints: ComplaintOverviewDTO[] = (
        await complaintApi.getComplaints()
      ).data;
      commit(RootMutations.setComplaints, complaints);
    },
    [RootActions.getUsers]: async ({ commit }) => {
      const users: UserGetDTO[] = (await userApi.getUsers()).data;
      commit(RootMutations.setUsers, users);
    },
    [RootActions.getLastOrderLockDate]: async ({ commit }) => {
      commit(
        RootMutations.setLastOrderLockDate,
        (
          await globalValueApi.getGlobalValueByKey(
            GlobalValueKey.LastOrderLockDate
          )
        ).data
      );
    },
    [RootActions.getDefaultValues]: async ({ commit }) => {
      commit(
        RootMutations.setDefaultValues,
        (await globalValueApi.getDefaultValues()).data
      );
    },
    [RootActions.getDefaultDocumentDataSettings]: async ({ commit }) => {
      commit(
        RootMutations.setDefaultDocumentDataSetting,
        (await globalValueApi.getDefaultDocumentDataSettings()).data
      );
    },
    [RootActions.updateDefaultValues]: async (
      { commit },
      payload: DefaultValuesDTO
    ) => {
      await globalValueApi.updateDefaultValues(payload);
      commit(RootMutations.setDefaultValues, payload);
    },
    [RootActions.createOrUpdateLocation]: async (
      { commit, state },
      payload: LocationDTO
    ) => {
      const newLocation: LocationDTO = (
        await baseApi.createOrUpdateLocation(payload)
      ).data;
      if (payload.id) {
        commit(RootMutations.updateLocation, {
          index: state.locations!.findIndex((entry) => entry.id === payload.id),
          item: newLocation,
        });
      } else {
        commit(RootMutations.addLocation, newLocation);
      }
    },
    [RootActions.reorderLocations]: (
      { commit, state },
      payload: SortableEvent
    ) => {
      const locations = state.locations!;
      const locationToMove = state.locations![payload.oldIndex!];
      locations.splice(payload.oldIndex!, 1);
      locations.splice(payload.newIndex!, 0, locationToMove);
      baseApi.updateLocationSort(locations.map((location) => location.id!));
      commit(RootMutations.setLocations, locations);
    },
    [RootActions.createOrUpdateCarpenter]: async (
      { commit, state },
      payload: CarpenterDTO
    ) => {
      const newCarpenter: CarpenterDTO = (
        await baseApi.createOrUpdateCarpenter(payload)
      ).data;
      if (payload.id) {
        commit(RootMutations.updateCarpenter, {
          index: state.carpenters!.findIndex(
            (entry) => entry.id === payload.id
          ),
          item: newCarpenter,
        });
      } else {
        commit(RootMutations.addCarpenter, newCarpenter);
      }
    },
    [RootActions.reorderCarpenters]: (
      { commit, state },
      payload: SortableEvent
    ) => {
      const carpenters = state.carpenters!;
      const carpenterToMove = state.carpenters![payload.oldIndex!];
      carpenters.splice(payload.oldIndex!, 1);
      carpenters.splice(payload.newIndex!, 0, carpenterToMove);
      baseApi.updateCarpenterSort(carpenters.map((carpenter) => carpenter.id!));
      commit(RootMutations.setCarpenters, carpenters);
    },
    [RootActions.createOrUpdateSupplier]: async (
      { commit, state },
      payload: SupplierDTO
    ) => {
      const newSupplier: SupplierDTO = (
        await baseApi.createOrUpdateSupplier(payload)
      ).data;
      if (payload.id) {
        commit(RootMutations.updateSupplier, {
          index: state.suppliers!.findIndex((entry) => entry.id === payload.id),
          item: newSupplier,
        });
      } else {
        commit(RootMutations.addSupplier, newSupplier);
      }
    },
    [RootActions.reorderSuppliers]: (
      { commit, state },
      payload: SortableEvent
    ) => {
      const suppliers = state.suppliers!;
      const supplierToMove = state.suppliers![payload.oldIndex!];
      suppliers.splice(payload.oldIndex!, 1);
      suppliers.splice(payload.newIndex!, 0, supplierToMove);
      baseApi.updateSupplierSort(suppliers.map((supplier) => supplier.id!));
      commit(RootMutations.setSuppliers, suppliers);
    },
    [RootActions.createOrUpdateExhibitionSalesman]: async (
      { commit, state },
      payload: ExhibitionSalesmanDTO
    ) => {
      const newSalesman: ExhibitionSalesmanDTO = (
        await baseApi.createOrUpdateExhibitionSalesman(payload)
      ).data;
      if (payload.id) {
        commit(RootMutations.updateExhibitionSalesman, {
          index: state.exhibitionSalesmen!.findIndex(
            (entry) => entry.id === payload.id
          ),
          item: newSalesman,
        });
      } else {
        commit(RootMutations.addExhibitionSalesman, newSalesman);
      }
    },
    [RootActions.reorderExhibitionSalesmen]: (
      { commit, state },
      payload: SortableEvent
    ) => {
      const exhibitionSalesmen = state.exhibitionSalesmen!;
      const exhibitionSalesmanToMove =
        state.exhibitionSalesmen![payload.oldIndex!];
      exhibitionSalesmen.splice(payload.oldIndex!, 1);
      exhibitionSalesmen.splice(payload.newIndex!, 0, exhibitionSalesmanToMove);
      baseApi.updateExhibitionSalesmanSort(
        exhibitionSalesmen.map((exhibitionSalesman) => exhibitionSalesman.id!)
      );
      commit(RootMutations.setExhibitionSalesmen, exhibitionSalesmen);
    },
    [RootActions.createOrUpdateLeadSource]: async (
      { commit, state },
      payload: LeadSourceDTO
    ) => {
      const newLeadSource: LeadSourceDTO = (
        await baseApi.createOrUpdateLeadSource(payload)
      ).data;
      if (payload.id) {
        commit(RootMutations.updateLeadSource, {
          index: state.leadSources!.findIndex(
            (entry) => entry.id === payload.id
          ),
          item: newLeadSource,
        });
      } else {
        commit(RootMutations.addLeadSource, newLeadSource);
      }
    },
    [RootActions.reorderLeadSources]: (
      { commit, state },
      payload: SortableEvent
    ) => {
      const leadSources = state.leadSources!;
      const leadSourceToMove = state.leadSources![payload.oldIndex!];
      leadSources.splice(payload.oldIndex!, 1);
      leadSources.splice(payload.newIndex!, 0, leadSourceToMove);
      baseApi.updateLeadSourceSort(
        leadSources.map((leadSource) => leadSource.id!)
      );
      commit(RootMutations.setLeadSources, leadSources);
    },
    [RootActions.createOrUpdateOrder]: async (
      { commit, state },
      payload: OrderDTO
    ): Promise<OrderOverviewDTO> => {
      const newOrder: OrderOverviewDTO = (
        await orderApi.createOrUpdateOrder(payload)
      ).data;
      if (payload.id) {
        const orderIndex = state.orders!.findIndex(
          (entry) => entry.id === payload.id
        );
        const exhibitionOrderIndex = state.exhibitionOrders!.findIndex(
          (entry) => entry.id === payload.id
        );
        if (orderIndex >= 0) {
          commit(RootMutations.updateOrder, {
            index: orderIndex,
            item: newOrder,
          });
        } else {
          commit(RootMutations.addOrder, newOrder);
        }
        if (exhibitionOrderIndex >= 0) {
          commit(RootMutations.updateExhibitionOrder, {
            index: exhibitionOrderIndex,
            item: newOrder,
          });
        }
      } else {
        if (newOrder.exhibitionSalesmanId) {
          commit(RootMutations.addExhibitionOrder, newOrder);
        }
        if (newOrder.salesmanId) {
          commit(RootMutations.addOrder, newOrder);
        }
      }
      return newOrder;
    },
    [RootActions.createOrUpdateComplaint]: async (
      { commit, dispatch, state },
      payload: ComplaintDTO
    ): Promise<ComplaintOverviewDTO> => {
      const newComplaint: ComplaintOverviewDTO = (
        await complaintApi.createOrUpdateComplaint(payload)
      ).data;
      // complaint overview not opened yet, complaints will be loaded in the future
      if (!state.complaints) {
        return newComplaint;
      }
      const existingComplaintIndex = state.complaints!.findIndex(
        (entry) => entry.id === payload.id
      );
      if (existingComplaintIndex < 0) {
        commit(RootMutations.addComplaint, newComplaint);
      } else {
        commit(RootMutations.updateComplaint, {
          index: existingComplaintIndex,
          item: newComplaint,
        });
      }
      return newComplaint;
    },
    [RootActions.createOrUpdateUser]: async (
      { commit, state },
      payload: UserDTO
    ) => {
      const newUser: UserDTO = (await userApi.createOrUpdateUser(payload)).data;
      if (payload.id) {
        commit(RootMutations.updateUser, {
          index: state.users!.findIndex((entry) => entry.id === payload.id),
          item: newUser,
        });
      } else {
        commit(RootMutations.addUser, newUser);
      }
    },
    [RootActions.createOrUpdateDefaultDocumentDataSetting]: async (
      { commit, state },
      payload: { type: DocumentType, setting: DocumentDataSettingDTO }
    ) => {
      globalValueApi.updateDefaultDocumentDataSetting(payload.type, payload.setting);
      const defaultDocumentDataSettings = state.defaultDocumentDataSettings!;
      defaultDocumentDataSettings[payload.type.toString()] = payload.setting;
      commit(RootMutations.setDefaultDocumentDataSetting, defaultDocumentDataSettings);
    },
    [RootActions.lockOrders]: async ({ commit }, date: string) => {
      await orderApi.lockOrders(date);
      commit(RootMutations.setLastOrderLockDate, date);
    },
    [RootActions.unlockOrders]: async ({ commit }, date: string) => {
      await orderApi.unlockOrders(date);
      commit(RootMutations.setLastOrderLockDate, date);
    },
    [RootActions.deleteCarpenter]: async ({ commit, state }, id: string) => {
      baseApi.deleteCarpenter(id);
      commit(
        RootMutations.removeCarpenter,
        state.carpenters!.findIndex((entry) => entry.id === id)
      );
    },
    [RootActions.deleteSupplier]: async ({ commit, state }, id: string) => {
      baseApi.deleteSupplier(id);
      commit(
        RootMutations.removeSupplier,
        state.suppliers!.findIndex((entry) => entry.id === id)
      );
    },
    [RootActions.deleteExhibitionSalesman]: async (
      { commit, state },
      id: string
    ) => {
      baseApi.deleteExhibitionSalesman(id);
      commit(
        RootMutations.removeExhibitionSalesman,
        state.exhibitionSalesmen!.findIndex((entry) => entry.id === id)
      );
    },
    [RootActions.deleteLeadSource]: async ({ commit, state }, id: string) => {
      baseApi.deleteLeadSource(id);
      commit(
        RootMutations.removeLeadSource,
        state.leadSources!.findIndex((entry) => entry.id === id)
      );
    },
    [RootActions.deleteOrder]: async ({ commit, state }, id: string) => {
      orderApi.deleteOrder(id);
      commit(
        RootMutations.removeOrder,
        state.orders!.findIndex((entry) => entry.id === id)
      );
      commit(
        RootMutations.removeExhibitionOrder,
        state.exhibitionOrders!.findIndex((entry) => entry.id === id)
      );
    },
    [RootActions.deleteComplaint]: async ({ commit, state }, id: string) => {
      complaintApi.deleteComplaint(id);
      if (state.complaints) {
        commit(
          RootMutations.removeComplaint,
          state.complaints!.findIndex((entry) => entry.id === id)
        );
      }
    },
    [RootActions.deleteUser]: async ({ commit, state }, id: string) => {
      userApi.deleteUser(id);
      commit(
        RootMutations.removeUser,
        state.users!.findIndex((entry) => entry.id === id)
      );
    },
  },
  getters: {
    [RootGetters.getCurrentUser]: (state: RootState) => state.currentUser || {},
    [RootGetters.getLocations]: (state: RootState) => state.locations || [],
    [RootGetters.getConstraint]: (state: RootState) => state.constraint || {},
    [RootGetters.getCarpenters]: (state: RootState) => state.carpenters || [],
    [RootGetters.getSuppliers]: (state: RootState) => state.suppliers || [],
    [RootGetters.getExhibitionSalesmen]: (state: RootState) =>
      state.exhibitionSalesmen || [],
    [RootGetters.getLeadSources]: (state: RootState) => state.leadSources || [],
    [RootGetters.getOrders]: (state: RootState) => state.orders || [],
    [RootGetters.getExhibitionOrders]: (state: RootState) =>
      state.exhibitionOrders || [],
    [RootGetters.getComplaints]: (state: RootState) => state.complaints || [],
    [RootGetters.getUsers]: (state: RootState) => state.users || [],
    [RootGetters.getComplaintLocations]: (state: RootState) => [
      ...(state.locations || []),
      { id: "", name: "STOCK" },
      { id: "", name: "CLIENT" },
    ],
    [RootGetters.getTableFooterProps]: () => ({
      itemsPerPageOptions: [25, 50, -1],
    }),
    [RootGetters.getLastOrderLockDate]: (state: RootState) =>
      state.lastOrderLockDate,
    [RootGetters.getDefaultValues]: (state: RootState) =>
      state.defaultValues || {},
    [RootGetters.getDefaultDocumentDataSettings]: (state: RootState) =>
      state.defaultDocumentDataSettings,
    [RootGetters.getBasePath]: () =>
      process.env.VUE_APP_BASE_PATH || window.location.origin,
  },
  modules: {},
});

export default store;
