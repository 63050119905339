
import Component, { mixins } from "vue-class-component";
import Navigation from "../components/Navigation.vue";
import ResetFiltersButton from "../components/ResetFiltersButton.vue";
import DeleteConfirmation from "../components/DeleteConfirmation.vue";
import PrintSelector, { PrintSelectorParams } from "../components/PrintSelector.vue";
import ComplaintDetail, {
  InitialComplaintValues,
} from "../components/ComplaintDetail.vue";
import { Action, Getter } from "vuex-class";
import { RootActions, RootGetters, complaintApi, exportApi } from "./../store";
import {
  ComplaintOverviewDTO,
  ComplaintState,
  FileRedirectDTO,
  UserRight,
} from "./../api";
import dayjs from "dayjs";
import "dayjs/locale/de";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { LocationMixin } from "./../mixins/location";
import { UserMixin } from "./../mixins/user";
import { ComplaintMixin } from "./../mixins/complaint";
import { DataTableHeader } from "vuetify";
import { Watch } from "vue-property-decorator";
import { openFileInNewTab } from "./../helpers";

dayjs.locale("de");
dayjs.extend(weekOfYear);

@Component({
  components: {
    Navigation,
    ResetFiltersButton,
    DeleteConfirmation,
    ComplaintDetail,
    PrintSelector,
  },
})
export default class Complaints extends mixins(LocationMixin, UserMixin, ComplaintMixin) {
  @Getter(RootGetters.getTableFooterProps)
  dataTableFooterProps: any;
  @Getter(RootGetters.getComplaints)
  complaints!: ComplaintOverviewDTO[];
  @Getter(RootGetters.getBasePath)
  basePath!: string;

  @Action(RootActions.getComplaints)
  getComplaints!: () => void;
  @Action(RootActions.deleteComplaint)
  deleteComplaint!: (id: string) => void;

  editElement: InitialComplaintValues = { id: "", orderId: "" };
  showComplaintDialog: boolean = false;
  showDeleteDialog: boolean = false;
  loading: boolean = false;
  searchText: string = "";
  showChangeStateDialog: boolean = false;
  complaintToChange?: ComplaintOverviewDTO;
  newState?: ComplaintState;

  salesmenIdFilter: string[] = [];
  locationFilter: string[] = [];

  tab: number = 0;
  tabs: string[] = [
    "complaint.allComplaints",
    "complaint.stateEnum.NEW",
    "complaint.doNotForget",
    "complaint.stateEnum.IN_PROGRESS",
    "complaint.stateEnum.ASSIGNED",
    "complaint.stateEnum.DONE",
    "archive",
  ];
  headers: DataTableHeader[] = [
    { text: this.$t("complaint.state").toString(), value: "state" },
    { text: this.$t("createdAt").toString(), value: "createdAt" },
    { text: this.$t("clientName").toString(), value: "clientName" },
    { text: this.$t("order.zipCode").toString(), value: "zipCode" },
    { text: this.$t("salesman").toString(), value: "salesmanName" },
    { text: this.$t("availability").toString(), value: "availability" },
    { text: this.$t("complaint.location").toString(), value: "location" },
    { text: this.$t("order.connections").toString(), value: "connections" },
    { text: this.$t("carpenter").toString(), value: "carpenterName" },
    { text: this.$t("complaint.date").toString(), value: "date" },
    {
      text: this.$t("complaint.repairTime").toString(),
      value: "repairTimeInMinutes",
    },
    { text: this.$t("complaint.notes").toString(), value: "notes" },
    {
      text: "",
      align: "end",
      value: "actions",
      sortable: false,
      width: "100px",
    },
  ];

  async created() {
    this.loading = true;
    if (this.currentUser) {
      this.updateSalesmenFilter();
    }
    try {
      await this.getComplaints();
    } finally {
      this.loading = false;
    }
  }

  resetFilters() {
    this.salesmenIdFilter =
      this.isSalesman && !this.isAdmin ? [this.currentUser.user!.id!] : [];
    this.locationFilter = [];
    this.searchText = "";
  }

  isPartOfFilter(complaint: ComplaintOverviewDTO): boolean {
    let salesmanIdFiltered = false;
    if (!this.salesmenIdFilter.length) {
      salesmanIdFiltered = true;
    } else if (!complaint.salesmanId) {
      salesmanIdFiltered = false;
    } else {
      salesmanIdFiltered = this.salesmenIdFilter.includes(complaint.salesmanId);
    }

    let locationIdFiltered = false;
    if (!this.locationFilter.length) {
      locationIdFiltered = true;
    } else if (!complaint.location) {
      locationIdFiltered = false;
    } else {
      locationIdFiltered = this.locationFilter.includes(complaint.location);
    }

    return salesmanIdFiltered && locationIdFiltered;
  }

  get doNotForgetComplaints(): ComplaintOverviewDTO[] {
    const now = dayjs();
    return this.complaints.filter(
      (element) =>
        this.isPartOfFilter(element) &&
        (element.state === ComplaintState.New ||
          element.state === ComplaintState.InProgress) &&
        ((element.date != null && now.diff(dayjs(element.date), "days") > 20) ||
          element.doNotForget)
    );
  }

  get archiveComplaints(): ComplaintOverviewDTO[] {
    const now = dayjs();
    return this.complaints.filter(
      (element) =>
        this.isPartOfFilter(element) &&
        element.state === ComplaintState.Done &&
        element.date != null &&
        now.diff(dayjs(element.date), "days") > 90
    );
  }

  getComplaintsByState(state: ComplaintState): ComplaintOverviewDTO[] {
    return this.complaints.filter(
      (element) => this.isPartOfFilter(element) && element.state === state
    );
  }

  getComplaintsByTabValue(tabValue: string): ComplaintOverviewDTO[] {
    if (tabValue === "complaint.doNotForget") {
      return this.doNotForgetComplaints;
    } else if (tabValue === "archive") {
      return this.archiveComplaints;
    } else if (tabValue.startsWith("complaint.stateEnum")) {
      const enumValue: string = tabValue.substr(tabValue.lastIndexOf(".") + 1);
      return this.getComplaintsByState(enumValue as ComplaintState);
    }
    return this.complaints.filter((element) => this.isPartOfFilter(element));
  }

  get filteredComplaints(): ComplaintOverviewDTO[] {
    return this.getComplaintsByTabValue(this.tabs[this.tab]);
  }

  complaintItemClass(complaint: ComplaintOverviewDTO): string {
    return complaint.importance === 3
      ? "red lighten-5"
      : complaint.importance === 2
      ? "orange lighten-5"
      : "green lighten-5";
  }

  getDeliveryWeekString(complaint: ComplaintOverviewDTO): string {
    return complaint.availability
      ? this.$t("complaint.calendarWeek", {
          value: `${dayjs(complaint.availability).week()} (${dayjs(
            complaint.availability
          ).format("YYYY")})`,
        }).toString()
      : "";
  }

  async prepareComplaintFile(complaintId: string) {
    const file: FileRedirectDTO = (await exportApi.getComplaintFile(complaintId)).data;
    openFileInNewTab(file, this.basePath);
  }

  // tslint:disable:variable-name
  editComplaintDoubleClick(_event: any, element: any) {
    this.editComplaint(element.item);
  }

  editComplaint(complaint: ComplaintOverviewDTO) {
    this.editElement = { id: complaint.id!, orderId: "" };
    this.showComplaintDialog = true;
  }

  showComplaintStateDialog(complaint: ComplaintOverviewDTO, newState: ComplaintState) {
    this.complaintToChange = complaint;
    this.newState = newState;
    this.showChangeStateDialog = true;
  }

  confirmComplaintChange(changeState: boolean) {
    if (changeState) {
      complaintApi.setComplaintState(this.complaintToChange!.id!, this.newState!);
      this.complaintToChange!.state = this.newState;
    }
    this.showChangeStateDialog = false;
  }

  showDeleteComplaintDialog(complaint: ComplaintOverviewDTO) {
    this.editElement = { id: complaint.id!, orderId: "" };
    this.showDeleteDialog = true;
  }

  confirmDelete(doDelete: boolean) {
    this.showDeleteDialog = false;
    if (!doDelete) {
      return;
    }
    this.deleteComplaint(this.editElement.id!);
  }

  get canReadOtherComplaints(): boolean {
    return this.hasUserRight(UserRight.OtherComplaintRead);
  }

  canDeleteComplaint(complaint: ComplaintOverviewDTO): boolean {
    if (this.hasUserRight(UserRight.OtherComplaintDelete)) {
      return true;
    }
    return (
      this.hasUserRight(UserRight.OwnComplaintDelete) &&
      complaint.salesmanId === this.currentUser.user!.id!
    );
  }

  canEditComplaint(complaint: ComplaintOverviewDTO): boolean {
    if (this.hasUserRight(UserRight.OtherComplaintWrite)) {
      return true;
    }
    return (
      this.hasUserRight(UserRight.OwnComplaintWrite) &&
      complaint.salesmanId === this.currentUser.user!.id!
    );
  }

  @Watch("currentUser")
  updateSalesmenFilter() {
    this.salesmenIdFilter =
      this.isSalesman && !this.isAdmin ? [this.currentUser.user!.id!] : [];
  }
}
