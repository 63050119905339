import Vue from "vue";
import Component from "vue-class-component";
import {
  AdditionalPackageType,
  ConstraintDTO,
  CurrentUserDTO,
  ExhibitionSalesmanDTO,
  UserDTO,
  UserRight,
  UserRole,
} from "../api";
import { Getter } from "vuex-class";
import { RootGetters } from "../store";
import { DropdownPair } from "./../interfaces";

@Component
export class UserMixin extends Vue {
  @Getter(RootGetters.getCurrentUser)
  currentUser!: CurrentUserDTO;
  @Getter(RootGetters.getConstraint)
  constraint!: ConstraintDTO;
  @Getter(RootGetters.getUsers)
  users!: UserDTO[];
  @Getter(RootGetters.getExhibitionSalesmen)
  exhibitionSalesmen!: ExhibitionSalesmanDTO[];

  get salesmenValues(): Array<DropdownPair<string>> {
    if (!this.currentUser?.user) {
      return [];
    }
    if (!this.canReadOtherOrders) {
      const user = this.currentUser.user;
      return [
        {
          key: user.id!,
          label: `${user.firstName} ${user.lastName} (${user.email})`,
        },
      ];
    }
    return this.users.map((entry) => ({
      key: entry.id!,
      label: `${entry.firstName} ${entry.lastName} (${entry.email})`,
    }));
  }

  get exhibitionSalesmenValues(): Array<DropdownPair<string>> {
    return this.exhibitionSalesmen.map((entry) => ({
      key: entry.id!,
      label: entry.name!,
    }));
  }

  get isSuperAdmin(): boolean {
    return this.currentUser.user?.roles?.includes(UserRole.SuperAdmin) || false;
  }

  get isAdmin(): boolean {
    return this.currentUser.user?.roles?.includes(UserRole.Admin) || false;
  }

  get isSalesman(): boolean {
    return this.currentUser.user?.roles?.includes(UserRole.Salesman) || false;
  }

  get isCarpenter(): boolean {
    return this.currentUser.user?.roles?.includes(UserRole.Carpenter) || false;
  }

  get hasComplaintPackage(): boolean {
    return (
      this.constraint.additionalPackageTypes?.includes(
        AdditionalPackageType.Complaint
      ) || false
    );
  }

  get hasExhibitionPackage(): boolean {
    return (
      this.constraint.additionalPackageTypes?.includes(
        AdditionalPackageType.Exhibition
      ) || false
    );
  }

  get hasTodoPackage(): boolean {
    return (
      this.constraint.additionalPackageTypes?.includes(
        AdditionalPackageType.Todo
      ) || false
    );
  }

  get hasSalesStatisticsPackage(): boolean {
    return (
      this.constraint.additionalPackageTypes?.includes(
        AdditionalPackageType.SalesStatistics
      ) || false
    );
  }

  get hasCarpenterAppPackage(): boolean {
    return (
      this.constraint.additionalPackageTypes?.includes(
        AdditionalPackageType.CarpenterApp
      ) || false
    );
  }

  get hasDocumentPrintPackage(): boolean {
    return (
      this.constraint.additionalPackageTypes?.includes(
        AdditionalPackageType.DocumentPrint
      ) || false
    );
  }

  get hasStockPackage(): boolean {
    return (
      this.constraint.additionalPackageTypes?.includes(
        AdditionalPackageType.Stock
      ) || false
    );
  }

  hasUserRight(userRight: UserRight): boolean {
    return this.currentUser.userRights?.includes(userRight) || false;
  }

  get canReadOtherOrders(): boolean {
    return this.hasUserRight(UserRight.OtherOrderRead);
  }
}
