import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store, { authenticationApi, RootActions } from "./store";
import vuetify from "./plugins/vuetify";
import filters from "./plugins/filters";
import i18n from "./i18n";
import VueCurrencyInput from "vue-currency-input";
import axios from "axios";
import { TokenDTO } from "./api";
import VueApexCharts from "vue-apexcharts";
import {
  configure,
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate";
import { required, email, min_value, max_value } from "vee-validate/dist/rules";

if (localStorage.getItem("token")) {
  store.dispatch(RootActions.getInitialData);
}

export const UNAUTHENTICATED_ROUTES: string[] = [
  "Login",
  "Register",
  "Reset Password",
];

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (
      token &&
      !config.url?.endsWith("token") &&
      !config.url?.endsWith("reset-password") &&
      !config.url?.endsWith("set-password")
    ) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !UNAUTHENTICATED_ROUTES.includes(router.currentRoute.name || "") &&
      !error.config.url.endsWith("token") &&
      !error.config.url.endsWith("reset-password") &&
      !error.config.url.endsWith("set-password")
    ) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        router.push("/login");
        return Promise.reject(error);
      }

      try {
        const token: TokenDTO = (
          await authenticationApi.getRefreshToken(refreshToken)
        ).data;
        localStorage.setItem("token", token.token!);
        localStorage.setItem("refreshToken", token.refreshToken!);
        originalRequest.headers.Authorization = `Bearer ${token.token}`;
        return axios(originalRequest);
      } catch (e) {
        router.push("/login");
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;
Vue.use(filters);
Vue.use(VueCurrencyInput, { currency: null, locale: "de" });
Vue.use(VueApexCharts);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
extend("required", required);
extend("email", email);
extend("min_value", min_value);
extend("max_value", max_value);
configure({
  // this will be used to generate messages.
  defaultMessage: (_, values) =>
    i18n.t(`validations.${values._rule_}`, values).toString(),
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
