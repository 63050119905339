
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({ name: "CloseConfirmation", components: {} })
export default class CloseConfirmation extends Vue {
  @Prop({ default: false })
  dialog!: boolean;
  @Prop({ default: false })
  saveDisabled!: boolean;
}
