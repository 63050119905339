import { VueConstructor } from "vue/types/umd";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(weekOfYear);

export default {
  install(Vue: VueConstructor) {
    Vue.filter("formatDate", (value: string) =>
      value != null && value.length ? dayjs(value).format("DD.MM.YYYY") : ""
    );
    Vue.filter("formatDateWithDay", (value: string) =>
      value != null && value.length
        ? dayjs(value).format("DD.MM.YYYY (dd)")
        : ""
    );
    Vue.filter("formatDateObject", (value: dayjs.Dayjs) =>
      value.format("DD.MM.YYYY")
    );
    Vue.filter("formatCalendarWeek", (value: string) =>
      value ? `${dayjs(value).week()} / ${dayjs(value).format("YY")}` : ""
    );
    Vue.filter("formatCurrency", (value: string) =>
      new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" })
        .format(+value)
        .replace("€", "")
        .trim()
    );
    Vue.filter("formatPercentage", (value: string) =>
      value ? `${value} %` : ""
    );
  },
};

declare module "vue/types/vue" {
  interface Vue {
    formatDate: (value: string) => string;
  }
}
