
import { authenticationApi, RootActions } from "./../store";
import Component from "vue-class-component";
import { Action } from "vuex-class";
import router from "./../router";
import { TokenDTO, TokenPostDTO } from "./../api";
import Vue from "vue";
import { marked } from "marked";
import { version } from "./../../package.json";

@Component
export default class Login extends Vue {
  loading: boolean = false;
  show: boolean = false;
  errorMessage: string = "";
  credentials: TokenPostDTO = { username: "", password: "" };
  tabs: string[] = ["login", "forgotPassword"];
  tab: number = 0;
  resetMail: string = "";
  showResetMailHint: boolean = false;
  appVersion: string = version;
  changelog: string = "";
  releaseNotesDialog: boolean = false;

  @Action(RootActions.getInitialData)
  getInitialData!: () => void;

  created() {
    const changelogLines = require("./../../CHANGELOG.md").default.split("\n");
    const filteredChangelogLines = [];
    for (const line of changelogLines) {
      // skip first lines
      if (!line.startsWith("##") && !filteredChangelogLines.length) {
        continue;
      }
      if (line.startsWith("#")) {
        // remove version url
        filteredChangelogLines.push(
          line
            .replaceAll(/\(https:[^\)]*\)/gm, "")
            .replaceAll("[", "")
            .replaceAll("]", "")
        );
        continue;
      }
      if (!line.startsWith("*")) {
        filteredChangelogLines.push(line);
        continue;
      }
      let filteredLine: string = line
        .replaceAll(/\[([^\[\]]*)\]\((.*?)\)/gm, "")
        .replaceAll("()", "")
        .trim();
      if (filteredLine.endsWith("fixes")) {
        filteredLine = filteredLine
          .substring(0, filteredLine.length - "fixes".length)
          .trim();
        if (filteredLine.endsWith(",")) {
          filteredLine = filteredLine
            .substring(0, filteredLine.length - 1)
            .trim();
        }
      }
      if (!filteredChangelogLines.includes(filteredLine)) {
        filteredChangelogLines.push(filteredLine);
      }
    }
    this.changelog = marked.parse(filteredChangelogLines.join("\n"));
  }

  async login() {
    if (!this.credentials.username || !this.credentials.password) {
      return;
    }
    this.errorMessage = "";
    this.loading = true;
    try {
      const accessToken: TokenDTO = (
        await authenticationApi.getToken(this.credentials)
      ).data;
      localStorage.setItem("token", accessToken.token!);
      localStorage.setItem("refreshToken", accessToken.refreshToken!);
      this.getInitialData();
      router.push("/");
    } catch (e: any) {
      this.errorMessage = this.$t(
        e.response?.status === 403 ? "loginTenantError" : "loginError"
      ).toString();
    } finally {
      this.loading = false;
      this.showResetMailHint = false;
    }
  }

  async sendResetMail() {
    if (!this.resetMail) {
      return;
    }
    this.loading = true;
    try {
      await authenticationApi.sendResetPasswordMail(this.resetMail);
    } finally {
      this.loading = false;
      this.credentials = { username: this.resetMail, password: "" };
      this.resetMail = "";
      this.showResetMailHint = true;
      this.tab = 0;
    }
  }
}
