import { FileRedirectDTO } from "./api";

export function openFileInNewTab(fileRedirect: FileRedirectDTO, basePath?: string) {
    const element = document.createElement("a");
    element.setAttribute("href", (basePath || "") + fileRedirect.url!);
    const fileNameToCompare = fileRedirect.filename!.toLowerCase();
    if (['.pdf', '.png', '.gif', '.jpg', '.jpeg'].find(fileEnding => fileNameToCompare.endsWith(fileEnding)) && !navigator.userAgent.match(/iphone/i)) {
        element.setAttribute("target", "_blank");
    }
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export function scrollToFirstValidationError() {
    const el = document.querySelector(
        ".v-messages.error--text:first-of-type"
    );
    el?.scrollIntoView({
        behavior: "smooth",
        block: "center",
    });
}