
import Component, { mixins } from "vue-class-component";
import Navigation from "./Navigation.vue";
import CloseConfirmation from "./CloseConfirmation.vue";
import DeleteConfirmation from "./DeleteConfirmation.vue";
import OrderFiles from "./OrderFiles.vue";
import OrderPayments from "./OrderPayments.vue";
import OrderDocumentDialog from "./OrderDocumentDialog.vue";
import OrderSupplierStockDialog from "./OrderSupplierStockDialog.vue";
import ComplaintDetail, { InitialComplaintValues } from "./ComplaintDetail.vue";
import VCurrencyField from "./VCurrencyField.vue";
import isBetween from "dayjs/plugin/isBetween";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { Prop, Watch } from "vue-property-decorator";
import {
  AdditionalExpenseDTO,
  CarpenterDTO,
  ClientDTO,
  ClientSalutation,
  ClientTargetGroup,
  ComplaintDTO,
  ComplaintImposed,
  ComplaintState,
  DefaultValuesDTO,
  DocumentDataDTO,
  DocumentType,
  ExhibitionProvisionValue,
  FileRedirectDTO,
  LeadSourceDTO,
  OrderConnection,
  OrderDTO,
  OrderOverviewDTO,
  OrderPaymentType,
  OrderState,
  OrderType,
  PaymentDTO,
  ProvisionDTO,
  SalesProvisionValue,
  SupplierCalculationDTO,
  SupplierDTO,
  TargetCompareValue,
  UserRight,
} from "./../api";
import { Action, Getter } from "vuex-class";
import { ObjectID } from "bson";
import {
  complaintApi,
  exportApi,
  orderApi,
  RootActions,
  RootGetters,
  userApi,
} from "./../store";
import { DropdownPair } from "./../interfaces";
import { LocationMixin } from "./../mixins/location";
import PrintSelector, { PrintSelectorParams } from "../components/PrintSelector.vue";
import { UserMixin } from "./../mixins/user";
import { ValidationProvider } from "vee-validate";
import { openFileInNewTab, scrollToFirstValidationError } from "./../helpers";
import { get } from "sortablejs";
dayjs.locale("de");
dayjs.extend(isBetween);

export class InitialOrderValues {
  id: string;
  type?: OrderType;
  salesmanId?: string;
  isExhibitionOrder: boolean;
  constructor(id: string, salesmanId: string, isExhibitionOrder: boolean) {
    this.id = id;
    this.salesmanId = salesmanId;
    this.isExhibitionOrder = isExhibitionOrder;
  }
}

enum PrintArea {
  HeaderData,
  AssemblyAndDelivery,
  PriceAndPayment,
  Calculation,
}

@Component({
  components: {
    Navigation,
    CloseConfirmation,
    DeleteConfirmation,
    VCurrencyField,
    ComplaintDetail,
    OrderFiles,
    PrintSelector,
    OrderDocumentDialog,
    OrderSupplierStockDialog,
    OrderPayments,
  },
})
export default class OrderDetail extends mixins(LocationMixin, UserMixin) {
  $refs!: {
    orderFiles: HTMLFormElement;
    validationObserver: InstanceType<typeof ValidationProvider>;
  };

  @Prop()
  dialog!: boolean;
  @Prop()
  initialOrderValues!: InitialOrderValues;

  @Getter(RootGetters.getCarpenters)
  carpenterValues!: CarpenterDTO[];
  @Getter(RootGetters.getSuppliers)
  supplierValues!: SupplierDTO[];
  @Getter(RootGetters.getLeadSources)
  leadSourceValues!: LeadSourceDTO[];
  @Getter(RootGetters.getLastOrderLockDate)
  lastOrderLockDate!: string;
  @Getter(RootGetters.getBasePath)
  basePath!: string;
  @Getter(RootGetters.getDefaultValues)
  defaultValues!: DefaultValuesDTO;

  @Action(RootActions.createOrUpdateOrder)
  createOrUpdateOrder!: (payload: OrderDTO) => OrderOverviewDTO;
  @Action(RootActions.deleteComplaint)
  deleteComplaint!: (id: string) => void;

  showOrderSupplierStockDialog: boolean = false;
  orderSupplierToEdit: SupplierCalculationDTO = {};

  order: OrderDTO = {
    additionalExpenses: [],
    changes: [],
    connections: [],
    payments: [],
    suppliers: [],
    documentDataHistory: [],
    documentData: {},
    type: OrderType.Normal,
  };
  initialOrderJSON: string = "";
  loadingOrder: boolean = false;
  savingOrder: boolean = false;
  loadingClients: boolean = false;

  allowClientEdit?: boolean = true;
  showLockClientValuesButton: boolean = false;
  showCloseDialog: boolean = false;
  showDeleteDialog: boolean = false;
  deleteType: string = "";
  elementToDelete?: PaymentDTO | SupplierCalculationDTO | AdditionalExpenseDTO;
  showExhibitionSellDate: boolean = false;
  showExhibitionScheduleDate: boolean = false;
  showSellDate: boolean = false;
  showDeliveryDate: boolean = false;
  showAssemblyDate: boolean = false;
  showSecondAssemblyDate: boolean = false;
  showChangesDates: boolean[] = [];
  salesmen: Array<DropdownPair<string>> = [];
  clients: ClientDTO[] = [];
  orderTypeValues: Array<DropdownPair<OrderType>> = [
    {
      key: OrderType.Normal,
      label: this.$t("order.typeEnum.NORMAL").toString(),
    },
    {
      key: OrderType.Small,
      label: this.$t("order.typeEnum.SMALL").toString(),
    },
  ];
  salutationValues: Array<DropdownPair<string>> = Object.keys(ClientSalutation).map(
    (entry: string) => ({
      key: (ClientSalutation as any)[entry],
      label: this.$t(`salutationEnum.${(ClientSalutation as any)[entry]}`).toString(),
    })
  );
  connectionValues: Array<DropdownPair<string>> = Object.keys(OrderConnection).map(
    (entry: string) => ({
      key: (OrderConnection as any)[entry],
      label: this.$t(`connectionsEnum.${(OrderConnection as any)[entry]}`).toString(),
    })
  );
  targetGroupValues: Array<DropdownPair<string>> = Object.keys(ClientTargetGroup).map(
    (entry: string) => ({
      key: (ClientTargetGroup as any)[entry],
      label: this.$t(`targetGroupEnum.${(ClientTargetGroup as any)[entry]}`).toString(),
    })
  );
  supplierToAdd: string = "";
  activeWindow: boolean = false;
  selectedPrintAreas: PrintArea[] = [];

  editComplaintElement: InitialComplaintValues = { id: "", orderId: "" };
  showComplaintDialog: boolean = false;
  complaintHeaders = [
    { text: this.$t("complaint.date"), value: "date" },
    { text: this.$t("complaint.state"), value: "state" },
    { text: this.$t("availability"), value: "availability" },
    { text: this.$t("complaint.location"), value: "location" },
    { text: this.$t("order.connections"), value: "connections" },
    { text: "", align: "right", value: "actions", sortable: false },
  ];
  complaintStateValues: Array<DropdownPair<string>> = [
    { key: "NEW", label: this.$t("complaint.stateEnum.NEW").toString() },
    {
      key: "IN_PROGRESS",
      label: this.$t("complaint.stateEnum.IN_PROGRESS").toString(),
    },
    {
      key: "ASSIGNED",
      label: this.$t("complaint.stateEnum.ASSIGNED").toString(),
    },
    { key: "DONE", label: this.$t("complaint.stateEnum.DONE").toString() },
  ];

  showDocumentDialog: boolean = false;
  showCancellationDialog: boolean = false;
  provision?: ProvisionDTO;
  timeoutCount = 0;
  hasFileChanges: boolean = false;
  doShowOrderTablePart: boolean = true;
  doShowAccountingTablePart: boolean = false;

  beforeMount() {
    window.addEventListener("beforeunload", this.autoSave);
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.autoSave);
  }

  autoSave() {
    if (this.activeWindow && this.didOrderValuesChange) {
      this.saveOrder();
    }
  }

  get stateValues(): OrderState[] {
    const baseStateValues: OrderState[] = [
      OrderState.Unassigned,
      OrderState.InProgress,
      OrderState.Sold,
      OrderState.InDelivery,
      OrderState.Delivered,
    ];
    if (!this.hasComplaintPackage) {
      return baseStateValues;
    }
    baseStateValues.push(OrderState.Complaint);
    return baseStateValues;
  }

  get orderState(): OrderState {
    let state = OrderState.Unassigned;
    if (this.order.salesmanId) {
      state = OrderState.InProgress;
    }
    if (this.order.orderPrice) {
      state = OrderState.Sold;
    }
    if (this.order.deliveryDate) {
      const now = dayjs();
      const deliveryWeekStart = dayjs(this.order.deliveryDate).startOf("week");
      const deliveryWeekEnd = dayjs(this.order.deliveryDate).endOf("week");
      if (now.isAfter(deliveryWeekEnd)) {
        state = OrderState.Delivered;
      } else if (now.isBetween(deliveryWeekStart, deliveryWeekEnd, null, "[]")) {
        state = OrderState.InDelivery;
      }
    }
    if (
      this.hasComplaintPackage &&
      this.order.complaints &&
      this.order.complaints.find((element) => element.state !== ComplaintState.Done)
    ) {
      state = OrderState.Complaint;
    }
    if (
      state === OrderState.Delivered &&
      this.order.suppliers &&
      this.order.suppliers.find((supplier) => !supplier.orderNumber || !supplier.orderSum)
    ) {
      state = OrderState.InDelivery;
    }
    return state;
  }

  get dialogTitle(): string {
    let title: string = this.$t(`order.${this.order.id ? "edit" : "new"}`).toString();
    if (this.$vuetify.breakpoint.mobile) {
      return title;
    }
    if (this.order.lastName) {
      title += `, ${this.order.lastName}`;
    }
    if (this.order.deliveryDate) {
      title += ` (${this.$t(
        "order.deliveryWeek"
      )}: ${this.$options.filters!.formatCalendarWeek(this.order.deliveryDate)})`;
    }
    return title;
  }

  get finalPrice(): number {
    return (
      +(this.order.orderPrice || 0) +
      (this.order.changes != null
        ? this.order.changes
            .map((entry) => +(entry.price || 0))
            .reduce((a, b) => a + b, 0)
        : 0)
    );
  }

  get remainingAmount(): number {
    return (
      this.finalPrice -
      (this.order.payments != null
        ? this.order.payments
            .filter((entry) => entry.paid)
            .map((entry) => +(entry.price || 0))
            .reduce((a, b) => a + b, 0)
        : 0)
    );
  }

  get deliverySumOrder(): number {
    return this.order.suppliers
      ? this.order.suppliers
          .map((entry) => entry.orderSum || 0)
          .reduce((a, b) => a + b, 0)
      : 0;
  }

  get deliverySumAccounting(): number {
    return this.order.suppliers
      ? this.order.suppliers
          .map((entry) => entry.accountingSum || 0)
          .reduce((a, b) => a + b, 0)
      : 0;
  }

  get sellingSum(): number {
    return (
      (+(this.order.orderPrice || 0) +
        (this.order.changes != null
          ? this.order.changes
              .map((entry) => +(entry.price || 0))
              .reduce((a, b) => a + b, 0)
          : 0)) /
      this.vatMultiplier
    );
  }

  get vatMultiplier(): number {
    const location = this.locations.find((entry) => entry.id === this.order.location);
    if (location) {
      return 1 + location.vat / 100;
    }
    return 1.2;
  }

  get margin(): number {
    return this.sellingSum - this.deliverySumOrder - this.complaintExpensesMargin;
  }

  get marginSum(): number {
    return this.margin - this.expenses;
  }

  get salesmanProvision(): number {
    if (!this.provision) {
      return 0;
    }
    const salesProvisionValue: SalesProvisionValue = this.order.exhibitionSale
      ? this.provision.exhibitionProvision!.salesValue!
      : this.provision.salesProvision!.value!;
    const percentage = this.order.exhibitionSale
      ? this.provision.exhibitionProvision!.salesPercentage!
      : this.provision.salesProvision!.percentage!;

    let baseValue = 0;
    if (salesProvisionValue === SalesProvisionValue.Margin) {
      baseValue = this.margin;
    } else if (salesProvisionValue === SalesProvisionValue.SellingSum) {
      baseValue = this.sellingSum;
    } else if (salesProvisionValue === SalesProvisionValue.SellingSumGross) {
      baseValue = this.sellingSum * this.vatMultiplier;
    }

    return baseValue * (percentage / 100);
  }

  get exhibitionProvision(): number {
    if (!this.order.exhibitionSale || !this.provision) {
      return 0;
    }
    let baseValue = this.order.exhibitionPrice || 0;
    if (
      this.provision.exhibitionProvision!.exhibitionValue ===
      ExhibitionProvisionValue.Price
    ) {
      baseValue /= this.vatMultiplier;
    }
    return baseValue * (this.provision!.exhibitionProvision!.exhibitionPercentage! / 100);
  }

  get expenses(): number {
    return (
      (this.order.assemblyExpense || 0) +
      (this.order.shipmentExpense || 0) +
      (this.order.salesmanProvision != null
        ? this.order.salesmanProvision
        : this.salesmanProvision) +
      (this.order.exhibitionSale && this.order.exhibitionProvision != null
        ? this.order.exhibitionProvision
        : this.exhibitionProvision) +
      (this.order.additionalExpenses != null
        ? this.order.additionalExpenses
            .map((entry) => entry.price || 0)
            .reduce((a, b) => a + b, 0)
        : 0) +
      this.complaintExpenses
    );
  }

  get complaintExpensesMargin(): number {
    return this.order.complaints != null
      ? this.order.complaints
          .filter((entry) => entry.imposed === ComplaintImposed.Self)
          .map((entry) => (entry.materialExpense || 0) + (entry.carpenterExpense || 0))
          .reduce((a, b) => a + b, 0)
      : 0;
  }

  get complaintExpenses(): number {
    return this.order.complaints != null
      ? this.order.complaints
          .filter((entry) => entry.imposed !== ComplaintImposed.Self)
          .map((entry) => (entry.materialExpense || 0) + (entry.carpenterExpense || 0))
          .reduce((a, b) => a + b, 0)
      : 0;
  }

  get clientValues(): Array<DropdownPair<string>> {
    if (this.clients.length) {
      return this.clients
        .filter((entry) => entry.lastName && entry.zipCode)
        .map((entry) => ({
          key: entry.id!,
          label: `${entry.lastName} (${entry.zipCode})`,
        }));
    }
    return this.order.lastName != null &&
      this.order.zipCode != null &&
      this.order.clientId != null
      ? [
          {
            key: this.order.clientId!,
            label: `${this.order.lastName} (${this.order.zipCode})`,
          },
        ]
      : [];
  }

  get isExhibitionOrder(): boolean {
    if (!this.initialOrderValues?.isExhibitionOrder) {
      return false;
    }
    return this.initialOrderValues.isExhibitionOrder && this.order.salesmanId == null;
  }

  get didOrderValuesChange(): boolean {
    return JSON.stringify(this.order) !== this.initialOrderJSON || this.hasFileChanges;
  }

  get lastModifiedText(): string {
    const fittingUser = this.users.find(
      (entry) => entry.id === this.order.lastModifiedBy
    );
    return this.$t("lastEditBy", {
      lastModifiedDate: dayjs(this.order.lastModifiedDate).format("DD.MM.YYYY HH:mm"),
      lastModifiedBy: fittingUser?.lastName,
    }).toString();
  }

  get canEditOrder(): boolean {
    if (this.hasUserRight(UserRight.OtherOrderWrite)) {
      return true;
    }
    if (!this.hasUserRight(UserRight.OwnOrderWrite)) {
      return false;
    }
    return !this.order.salesmanId || this.order.salesmanId === this.currentUser.user.id;
  }

  get canChangeSalesmanId(): boolean {
    if (!this.canEditOrder) {
      return false;
    }
    if (!this.order.salesmanId) {
      return true;
    }
    if (this.order.salesmanId !== this.currentUser.user.id) {
      return this.hasUserRight(UserRight.OtherOrderChangeSalesman);
    }
    return true;
  }

  hasOrderRight(ownOrderRight: UserRight, otherOrderRight: UserRight): boolean {
    if (!this.hasUserRight(ownOrderRight)) {
      return false;
    }
    if (!this.order.salesmanId || this.isAdmin || this.isSuperAdmin) {
      return true;
    }
    if (this.order.salesmanId === this.currentUser.user.id) {
      return true;
    }
    return this.hasUserRight(otherOrderRight);
  }

  get canReadCalculation(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderCalculationRead,
      UserRight.OtherOrderCalculationRead
    );
  }

  get canReadSupplierData(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderSupplierDataRead,
      UserRight.OtherOrderSupplierDataRead
    );
  }

  get canWriteSupplierData(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderSupplierDataWrite,
      UserRight.OtherOrderSupplierDataWrite
    );
  }

  get canReadMargin(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderMarginRead,
      UserRight.OtherOrderMarginRead
    );
  }

  get canReadExpenses(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderExpensesRead,
      UserRight.OtherOrderExpensesRead
    );
  }

  get canWriteExpenses(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderExpensesWrite,
      UserRight.OtherOrderExpensesWrite
    );
  }

  get canReadMarginSum(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderMarginSumRead,
      UserRight.OtherOrderMarginSumRead
    );
  }

  get canReadTargetMargin(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderTargetMarginRead,
      UserRight.OtherOrderTargetMarginRead
    );
  }

  get canReadPriceAndPayment(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderPriceAndPaymentRead,
      UserRight.OtherOrderPriceAndPaymentRead
    );
  }

  get canReadExhibitionPrice(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderExhibitionPriceRead,
      UserRight.OtherOrderExhibitionPriceRead
    );
  }

  get canWriteExhibitionPrice(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderExhibitionPriceWrite,
      UserRight.OtherOrderExhibitionPriceWrite
    );
  }

  get canReadOrderPrice(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderOrderPriceRead,
      UserRight.OtherOrderOrderPriceRead
    );
  }

  get canWriteOrderPrice(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderOrderPriceWrite,
      UserRight.OtherOrderOrderPriceWrite
    );
  }

  get canReadOrderChanges(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderChangesRead,
      UserRight.OtherOrderChangesRead
    );
  }

  get canWriteOrderChanges(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderChangesWrite,
      UserRight.OtherOrderChangesWrite
    );
  }

  get canReadPrePayments(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderPrePaymentsRead,
      UserRight.OtherOrderPrePaymentsRead
    );
  }

  get canWritePrePayments(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderPrePaymentsWrite,
      UserRight.OtherOrderPrePaymentsWrite
    );
  }

  get canReadRemainingAmount(): boolean {
    return this.hasOrderRight(
      UserRight.OwnOrderRemainingAmountRead,
      UserRight.OtherOrderRemainingAmountRead
    );
  }

  get canPrintOrderDetail(): boolean {
    return this.hasUserRight(UserRight.PrintOrderDetail);
  }

  get canReadComplaints(): boolean {
    if (this.hasUserRight(UserRight.OtherComplaintRead)) {
      return true;
    }
    if (!this.hasUserRight(UserRight.OwnComplaintRead)) {
      return false;
    }
    if (!this.order.salesmanId || this.order.salesmanId === this.currentUser.user!.id!) {
      return true;
    }
    return false;
  }

  get canEditComplaint(): boolean {
    if (this.hasUserRight(UserRight.OtherComplaintWrite)) {
      return true;
    }
    if (!this.hasUserRight(UserRight.OwnComplaintWrite)) {
      return false;
    }
    if (!this.order.salesmanId || this.order.salesmanId === this.currentUser.user!.id!) {
      return true;
    }
    return false;
  }

  get canDeleteComplaints(): boolean {
    if (this.hasUserRight(UserRight.OtherComplaintDelete)) {
      return true;
    }
    if (!this.hasUserRight(UserRight.OwnComplaintDelete)) {
      return false;
    }
    if (!this.order.salesmanId || this.order.salesmanId === this.currentUser.user!.id!) {
      return true;
    }
    return false;
  }

  get targetMargin(): number {
    if (this.defaultValues.targetMargin.percentage === 0) {
      return 0;
    }
    let valueToCompare = this.sellingSum;
    if (this.defaultValues.targetMargin.compareValue === TargetCompareValue.SumGross) {
      valueToCompare *= this.vatMultiplier;
    }
    return (valueToCompare * this.defaultValues.targetMargin.percentage) / 100;
  }

  get targetMarginSum(): number {
    if (this.defaultValues.targetMarginSum.percentage === 0) {
      return 0;
    }
    let valueToCompare = this.sellingSum;
    if (this.defaultValues.targetMarginSum.compareValue === TargetCompareValue.SumGross) {
      valueToCompare *= this.vatMultiplier;
    }
    return (valueToCompare * this.defaultValues.targetMarginSum.percentage) / 100;
  }

  get targetMarginLine(): string {
    const differenceTargetMargin = this.margin - this.targetMargin;
    const differenceTargetMarginPercentage =
      (differenceTargetMargin / this.targetMargin) * 100;
    return `${this.$t("target")}: € ${this.$options.filters!.formatCurrency(
      this.targetMargin
    )}, ${
      differenceTargetMarginPercentage < 0 ? "-" : "+"
    } ${this.$options.filters!.formatCurrency(
      Math.abs(differenceTargetMarginPercentage)
    )}%, ${
      this.margin < this.targetMargin ? "-" : "+"
    } € ${this.$options.filters!.formatCurrency(Math.abs(differenceTargetMargin))}`;
  }

  get targetMarginSumLine(): string {
    const differenceTargetMarginSum = this.marginSum - this.targetMarginSum;
    const differenceTargetMarginSumPercentage =
      (differenceTargetMarginSum / this.targetMarginSum) * 100;
    return `${this.$t("target")}: € ${this.$options.filters!.formatCurrency(
      this.targetMarginSum
    )}, ${
      differenceTargetMarginSumPercentage < 0 ? "-" : "+"
    } ${this.$options.filters!.formatCurrency(
      Math.abs(differenceTargetMarginSumPercentage)
    )}%, ${
      this.marginSum < this.targetMarginSum ? "-" : "+"
    } € ${this.$options.filters!.formatCurrency(Math.abs(differenceTargetMarginSum))}`;
  }

  get printAreaValues(): Array<DropdownPair<PrintArea>> {
    const toReturn: Array<DropdownPair<PrintArea>> = [
      {
        key: PrintArea.HeaderData,
        label: this.$t("order.headerData").toString(),
      },
      {
        key: PrintArea.AssemblyAndDelivery,
        label: this.$t("order.assemblyAndDelivery").toString(),
      },
    ];
    if (this.canReadPriceAndPayment) {
      toReturn.push({
        key: PrintArea.PriceAndPayment,
        label: this.$t("order.priceAndPaymentHeadline").toString(),
      });
    }
    if (this.canReadCalculation) {
      toReturn.push({
        key: PrintArea.Calculation,
        label: this.$t("calculation").toString(),
      });
    }
    return toReturn;
  }

  get showOrderTablePart(): boolean {
    if (this.$vuetify.breakpoint.name !== "xs") {
      return true;
    }
    return this.doShowOrderTablePart;
  }

  get showAccountingTablePart(): boolean {
    if (this.$vuetify.breakpoint.name !== "xs") {
      return true;
    }
    return this.doShowAccountingTablePart;
  }

  get showSupplierStockColumn(): boolean {
    return this.showOrderTablePart && this.order.id != null && this.hasStockPackage;
  }

  get supplierEntryColspan(): number {
    return this.showOrderTablePart ? (this.showSupplierStockColumn ? 3 : 2) : 1;
  }

  get supplierWholeColspan(): number {
    return this.showOrderTablePart && this.showAccountingTablePart
      ? this.showSupplierStockColumn
        ? 6
        : 5
      : this.showSupplierStockColumn
      ? 4
      : 3;
  }

  toggleMobileTableView() {
    this.doShowOrderTablePart = !this.doShowOrderTablePart;
    this.doShowAccountingTablePart = !this.doShowAccountingTablePart;
  }

  addCurrentSupplier() {
    this.order.suppliers!.push({
      id: new ObjectID().toString(),
      supplierId: this.supplierToAdd,
    });
    this.$nextTick(() => (this.supplierToAdd = ""));
  }

  updateLocation() {
    if (this.order.location) {
      return;
    }
    if (this.isExhibitionOrder) {
      const fittingUser = this.exhibitionSalesmen.find(
        (entry) => entry.id === this.order.exhibitionSalesmanId
      );
      if (!fittingUser?.locationId) {
        return;
      }
      this.order.location = fittingUser.locationId;
    } else {
      const fittingSalesman = this.users.find(
        (entry) => entry.id === this.order.salesmanId
      );
      if (!fittingSalesman?.location) {
        return;
      }
      this.order.location = fittingSalesman.location;
    }
  }

  updateAssemblyDate() {
    if (
      !this.order.assemblyDate ||
      dayjs(this.order.deliveryDate).isAfter(dayjs(this.order.assemblyDate))
    ) {
      this.order.assemblyDate = dayjs(this.order.deliveryDate)
        .add(1, "d")
        .format("YYYY-MM-DD");
    }
  }

  addChange() {
    if (this.order.changes == null) {
      this.order.changes = [];
    }
    this.order.changes.push({
      date: dayjs().format("YYYY-MM-DD"),
      price: 0,
      paymentType: OrderPaymentType.Bank,
    });
  }

  addAdditionalExpense() {
    if (this.order.additionalExpenses == null) {
      this.order.additionalExpenses = [];
    }
    this.order.additionalExpenses.push({ name: "", price: 0 });
  }

  deleteElement(element: PaymentDTO, type: string) {
    this.elementToDelete = element;
    this.deleteType = type;
    this.showDeleteDialog = true;
  }

  confirmDelete(doDelete: boolean) {
    this.showDeleteDialog = false;
    if (!doDelete) {
      return;
    }
    if (this.deleteType === "change") {
      this.order.changes!.splice(
        this.order.changes!.findIndex((entry) => entry === this.elementToDelete),
        1
      );
    } else if (this.deleteType === "supplier") {
      this.order.suppliers!.splice(
        this.order.suppliers!.findIndex((entry) => entry === this.elementToDelete),
        1
      );
    } else if (this.deleteType === "additionalExpense") {
      this.order.additionalExpenses!.splice(
        this.order.additionalExpenses!.findIndex(
          (entry) => entry === this.elementToDelete
        ),
        1
      );
    } else if (this.deleteType === "complaint") {
      this.deleteComplaint(this.editComplaintElement.id!);
      this.order.complaints!.splice(
        this.order.complaints!.findIndex(
          (entry) => entry.id === this.editComplaintElement.id
        ),
        1
      );
    }
  }

  toggleSalesmanProvision() {
    this.$set(
      this.order,
      "salesmanProvision",
      this.order.salesmanProvision != null ? null : this.salesmanProvision
    );
  }

  toggleExhibitionProvision() {
    this.$set(
      this.order,
      "exhibitionProvision",
      this.order.exhibitionProvision != null ? null : this.exhibitionProvision
    );
  }

  complaintItemClass(complaint: ComplaintDTO): string {
    return complaint.importance === 3
      ? "red lighten-5"
      : complaint.importance === 2
      ? "orange lighten-5"
      : "green lighten-5";
  }

  editComplaint(complaint: InitialComplaintValues) {
    this.autoSave();
    this.editComplaintElement = complaint;
    this.showComplaintDialog = true;
  }

  showDeleteComplaintDialog(complaint: InitialComplaintValues) {
    this.editComplaintElement = complaint;
    this.showDeleteDialog = true;
    this.deleteType = "complaint";
  }

  updateComplaintState(complaint: ComplaintDTO) {
    complaintApi.setComplaintState(complaint.id!, complaint.state!);
  }

  toggleFileTypeHidden(parameter: any) {
    if (parameter.hidden) {
      if (!this.order.hiddenFileTypes) {
        this.order.hiddenFileTypes = [parameter.key];
      } else if (
        !this.order.hiddenFileTypes.find((fileType) => fileType === parameter.key)
      ) {
        this.order.hiddenFileTypes.push(parameter.key);
      }
    } else {
      this.order.hiddenFileTypes = this.order.hiddenFileTypes?.filter(
        (fileType) => fileType !== parameter.key
      );
    }
  }

  updateMissingFileTypes(missingFileTypes: string[]) {
    this.order.missingFileTypes = missingFileTypes;
    const initialOrderObject = JSON.parse(this.initialOrderJSON);
    initialOrderObject.missingFileTypes = missingFileTypes;
    this.initialOrderJSON = JSON.stringify(initialOrderObject);
  }

  async saveOrderAndClose() {
    this.showCloseDialog = false;
    await this.saveOrder();
    this.close();
  }

  async saveOrder() {
    this.savingOrder = true;
    try {
      if (!(await this.$refs.validationObserver.validate())) {
        this.$nextTick(() => scrollToFirstValidationError());
        return;
      }
      const createdOrder: OrderOverviewDTO = await this.createOrUpdateOrder(this.order);
      this.order.id = createdOrder.id;
      this.order.lastModifiedDate = createdOrder.lastModifiedDate;
      this.order.lastModifiedBy = createdOrder.lastModifiedBy;
      this.initialOrderJSON = JSON.stringify(this.order);
      await this.$refs.orderFiles.executeFileQueue(this.order.id);
      this.$emit("save");
    } finally {
      this.savingOrder = false;
    }
  }

  close(confirmClose: boolean = true) {
    this.showCloseDialog = false;
    if (!confirmClose) {
      return;
    }
    this.$refs.orderFiles.clearQueue();
    // to reload clients when opening a new order
    this.clients = [];
    this.activeWindow = false;
    this.$emit("close");
  }

  async loadClients() {
    if (this.clients.length) {
      return;
    }
    this.loadingClients = true;
    try {
      this.clients = (await orderApi.getClients()).data;
    } finally {
      this.loadingClients = false;
    }
  }

  overrideClientValues() {
    const fittingClient: ClientDTO | undefined = this.clients.find(
      (entry) => entry.id === this.order.clientId
    );
    if (!fittingClient) {
      return;
    }
    this.allowClientEdit = false;
    this.showLockClientValuesButton = true;
    this.order.targetGroup = fittingClient.targetGroup as any;
    this.order.salutation = fittingClient.salutation as any;
    this.order.firstName = fittingClient.firstName;
    this.order.lastName = fittingClient.lastName;
    this.order.address = fittingClient.address;
    this.order.zipCode = fittingClient.zipCode;
    this.order.city = fittingClient.zipCode;
    this.order.vat = fittingClient.vat;
    this.order.phone1 = fittingClient.phone1;
    this.order.phone2 = fittingClient.phone2;
    this.order.leadSourceId = fittingClient.leadSourceId;
    this.order.externalCustomerId = fittingClient.externalCustomerId;
  }

  async reloadOrder() {
    if (!this.order.id) {
      return;
    }
    this.loadingOrder = true;
    try {
      this.order = (await orderApi.getOrder(this.order.id)).data;
    } finally {
      this.loadingOrder = false;
    }
  }

  async prepareComplaintFile(complaintId?: string) {
    const file: FileRedirectDTO = (await exportApi.getComplaintFile(complaintId!)).data;
    openFileInNewTab(file, this.basePath);
  }

  prepareHeaderDataFile(params: PrintSelectorParams) {
    this.preparePrintDataFile(params, [PrintArea.HeaderData]);
  }

  prepareAssemblyAndDeliveryFile(params: PrintSelectorParams) {
    this.preparePrintDataFile(params, [PrintArea.AssemblyAndDelivery]);
  }

  preparePriceAndPaymentFile(params: PrintSelectorParams) {
    this.preparePrintDataFile(params, [PrintArea.PriceAndPayment]);
  }

  prepareCalculationFile(params: PrintSelectorParams) {
    this.preparePrintDataFile(params, [PrintArea.Calculation]);
  }

  prepareSelectedPrintDataFile(params: PrintSelectorParams) {
    this.preparePrintDataFile(params, this.selectedPrintAreas);
  }

  async preparePrintDataFile(params: PrintSelectorParams, printAreas: PrintArea[]) {
    const clientNames: string[] = [];
    if (this.order.lastName) {
      clientNames.push(this.order.lastName);
    }
    if (this.order.firstName) {
      clientNames.push(this.order.firstName);
    }
    let tableValues: string[][] = [
      [
        this.$t("clientName").toString(),
        this.$t("salesman").toString(),
        this.$t("order.deliveryDate").toString(),
      ],
      [
        clientNames.join(" "),
        this.salesmenValues.find((entry) => entry.key === this.order.salesmanId)?.label,
        this.$options.filters!.formatDate(this.order.deliveryDate),
      ],
    ];

    if (printAreas.includes(PrintArea.HeaderData)) {
      tableValues = [
        ...tableValues,
        [],
        [this.$t("order.headerData").toString()],
        ...this.getHeaderDataTableValues(),
      ];
    }
    if (printAreas.includes(PrintArea.AssemblyAndDelivery)) {
      tableValues = [
        ...tableValues,
        [],
        [this.$t("order.assemblyAndDelivery").toString()],
        ...this.getAssemblyAndDeliveryTableValues(),
      ];
    }
    if (printAreas.includes(PrintArea.PriceAndPayment)) {
      tableValues = [
        ...tableValues,
        [],
        [this.$t("order.priceAndPaymentHeadline").toString()],
        ...this.getPriceAndPaymentTableValues(),
      ];
    }
    if (printAreas.includes(PrintArea.Calculation)) {
      tableValues = [
        ...tableValues,
        [],
        [this.$t("calculation").toString()],
        ...this.getCalculationTableValues(),
      ];
    }

    const file: FileRedirectDTO = (
      await exportApi.getTableFile(params.type!, tableValues[1].join(" "), tableValues)
    ).data;
    openFileInNewTab(file, this.basePath);
  }

  getHeaderDataTableValues(): string[][] {
    const headerValues = [
      ...(this.order.exhibitionSale
        ? [
            this.$t("exhibitionSalesman").toString(),
            this.$t("order.exhibitionSellDate").toString(),
            this.$t("order.exhibitionScheduleDate").toString(),
          ]
        : []),
      this.$t("location").toString(),
      this.$t("order.sellDate").toString(),
      this.$t("email").toString(),
    ];
    const contentValues = [
      ...(this.order.exhibitionSale
        ? [
            this.exhibitionSalesmenValues.find(
              (entry) => entry.key === this.order.exhibitionSalesmanId
            )?.label,
            this.$options.filters!.formatDate(this.order.exhibitionSellDate),
            this.$options.filters!.formatDate(this.order.exhibitionScheduleDate),
          ]
        : []),
      this.locationValues.find((entry) => entry.key === this.order.location)?.label,
      this.$options.filters!.formatDate(this.order.sellDate),
      this.order.email,
    ];
    return [headerValues, contentValues];
  }

  getAssemblyAndDeliveryTableValues(): string[][] {
    const headerValues = [
      this.$t("order.deliveryDate").toString(),
      this.$t("order.assemblyDate").toString(),
      this.$t("order.secondAssemblyDate").toString(),
      this.$t("carpenter").toString(),
      this.$t("order.connections").toString(),
      this.$t("suppliers").toString(),
      this.$t("order.notes").toString(),
    ];
    const contentValues = [
      this.$options.filters!.formatDate(this.order.deliveryDate),
      this.$options.filters!.formatDate(this.order.assemblyDate),
      this.$options.filters!.formatDate(this.order.secondAssemblyDate),
      this.carpenterValues.find((entry) => entry.id === this.order.carpenterId)?.name,
      this.order.connections
        ?.map(
          (entry) =>
            this.connectionValues.find((connectionValue) => connectionValue.key === entry)
              ?.label
        )
        .join(", "),
      this.order.suppliers
        ?.map(
          (entry) =>
            this.supplierValues.find(
              (supplierValue) => supplierValue.id === entry.supplierId
            )?.name
        )
        .join(", "),
      this.order.notes,
    ];
    return [headerValues, contentValues];
  }

  getPriceAndPaymentTableValues(): string[][] {
    const tableContent: string[][] = [];
    if (this.order.exhibitionSale && this.canReadExhibitionPrice) {
      tableContent.push([
        this.$t("order.exhibitionPrice").toString(),
        `€ ${this.$options.filters!.formatCurrency(this.order.exhibitionPrice)}`,
      ]);
    }
    if (this.canReadOrderPrice) {
      tableContent.push([
        this.$t("order.orderPrice").toString(),
        `€ ${this.$options.filters!.formatCurrency(this.order.orderPrice)}`,
      ]);
      tableContent.push([]);
    }
    if (this.canReadOrderChanges) {
      if (this.order.changes?.length) {
        tableContent.push([this.$t("order.changes").toString()]);
        this.order.changes.forEach((entry) => {
          tableContent.push([
            this.$options.filters!.formatDate(entry.date),
            `€ ${this.$options.filters!.formatCurrency(entry.price)}`,
          ]);
        });
        tableContent.push([]);
      }
      tableContent.push([
        this.$t("order.finalPrice").toString(),
        `€ ${this.$options.filters!.formatCurrency(this.finalPrice)}`,
      ]);
      tableContent.push([]);
    }
    if (this.order.payments?.length && this.canReadPrePayments) {
      tableContent.push([this.$t("order.prePayments").toString()]);
      this.order.payments.forEach((entry) => {
        tableContent.push([
          this.$options.filters!.formatDate(entry.date),
          `€ ${this.$options.filters!.formatCurrency(entry.price)}`,
          this.$t(`order.paymentEnum.${entry.paymentType}`).toString(),
          this.$t(entry.paid ? "paid" : "notPaid").toString(),
        ]);
      });
      tableContent.push([]);
    }
    if (this.canReadRemainingAmount) {
      tableContent.push([
        this.$t("order.remainingAmount").toString(),
        `€ ${this.$options.filters!.formatCurrency(this.remainingAmount)}`,
      ]);
    }
    return tableContent;
  }

  getCalculationTableValues(): string[][] {
    const tableContent: string[][] = [];
    if (this.canReadSupplierData) {
      tableContent.push([
        this.$t("order.calculationHeadline.company").toString(),
        this.$t("order.calculationHeadline.supplierConfirmationNumber").toString(),
        this.$t("order.calculationHeadline.supplierConfirmationSum").toString(),
        this.$t("order.calculationHeadline.calculationSum").toString(),
        this.$t("order.calculationHeadline.calculationNumber").toString(),
      ]);
    }
    if (this.order.suppliers?.length && this.canReadSupplierData) {
      this.order.suppliers.forEach((entry) => {
        tableContent.push([
          this.supplierValues.find((supplier) => supplier.id === entry.supplierId)
            ?.name || "",
          entry.orderNumber || "",
          `€ ${this.$options.filters!.formatCurrency(entry.orderSum)}`,
          `€ ${this.$options.filters!.formatCurrency(entry.accountingSum)}`,
          entry.accountingNumber || "",
        ]);
      });
    }
    tableContent.push([
      this.$t("order.deliverySum").toString(),
      "",
      `€ ${this.$options.filters!.formatCurrency(this.deliverySumOrder)}`,
      `€ ${this.$options.filters!.formatCurrency(this.deliverySumAccounting)}`,
    ]);
    if (this.complaintExpensesMargin > 0 && this.canReadComplaints) {
      tableContent.push([
        this.$t("complaints").toString(),
        "",
        `€ ${this.$options.filters!.formatCurrency(this.complaintExpensesMargin)}`,
        `€ ${this.$options.filters!.formatCurrency(this.complaintExpensesMargin)}`,
      ]);
    }
    tableContent.push([
      this.$t("order.sellingSum").toString(),
      "",
      `€ ${this.$options.filters!.formatCurrency(this.sellingSum)}`,
      `€ ${this.$options.filters!.formatCurrency(this.sellingSum)}`,
    ]);
    if (this.canReadMargin) {
      tableContent.push([]);
      tableContent.push([
        this.$t("order.margin").toString(),
        "",
        `€ ${this.$options.filters!.formatCurrency(this.margin)}`,
        `€ ${this.$options.filters!.formatCurrency(
          this.sellingSum - this.deliverySumAccounting - this.complaintExpensesMargin
        )}`,
      ]);
      if (this.targetMargin && this.canReadTargetMargin) {
        tableContent.push([this.targetMarginLine]);
      }
    }
    if (this.canReadExpenses) {
      tableContent.push([]);
      tableContent.push([this.$t("order.expenses").toString()]);
      tableContent.push([
        this.$t("order.assemblyExpense").toString(),
        "",
        `€ ${this.$options.filters!.formatCurrency(this.order.assemblyExpense)}`,
        `€ ${this.$options.filters!.formatCurrency(this.order.assemblyExpense)}`,
      ]);
      tableContent.push([
        this.$t("order.shipmentExpense").toString(),
        "",
        `€ ${this.$options.filters!.formatCurrency(this.order.shipmentExpense)}`,
        `€ ${this.$options.filters!.formatCurrency(this.order.shipmentExpense)}`,
      ]);
      const salesmanProvision =
        this.order.salesmanProvision != null
          ? this.order.salesmanProvision
          : this.salesmanProvision;
      tableContent.push([
        this.$t("order.salesmanProvision").toString(),
        "",
        `€ ${this.$options.filters!.formatCurrency(salesmanProvision)}`,
        `€ ${this.$options.filters!.formatCurrency(salesmanProvision)}`,
      ]);
      if (this.order.exhibitionSale) {
        const exhibitionProvision =
          this.order.exhibitionProvision != null
            ? this.order.exhibitionProvision
            : this.exhibitionProvision;
        tableContent.push([
          this.$t("order.exhibitionProvision").toString(),
          "",
          `€ ${this.$options.filters!.formatCurrency(exhibitionProvision)}`,
          `€ ${this.$options.filters!.formatCurrency(exhibitionProvision)}`,
        ]);
      }
      if (this.complaintExpenses > 0) {
        tableContent.push([
          this.$t("complaints").toString(),
          "",
          `€ ${this.$options.filters!.formatCurrency(this.complaintExpenses)}`,
          `€ ${this.$options.filters!.formatCurrency(this.complaintExpenses)}`,
        ]);
      }
      if (this.order.additionalExpenses?.length) {
        this.order.additionalExpenses.forEach((entry) => {
          tableContent.push([
            entry.name || "",
            "",
            `€ ${this.$options.filters!.formatCurrency(entry.price)}`,
            `€ ${this.$options.filters!.formatCurrency(entry.price)}`,
          ]);
        });
      }
    }
    if (this.canReadMarginSum) {
      tableContent.push([]);
      tableContent.push([
        this.$t("order.marginSum").toString(),
        "",
        `€ ${this.$options.filters!.formatCurrency(this.marginSum)}`,
        `€ ${this.$options.filters!.formatCurrency(
          this.sellingSum -
            this.deliverySumAccounting -
            this.complaintExpensesMargin -
            this.expenses
        )}`,
      ]);
      if (this.targetMarginSum && this.canReadTargetMargin) {
        tableContent.push([this.targetMarginSumLine]);
      }
    }
    return tableContent;
  }

  async addPayment(payment: PaymentDTO, callback?: () => void) {
    this.order.payments.push(payment);
    if (callback) {
      await this.saveOrder();
      callback();
    }
  }

  deletePayment(payment: PaymentDTO) {
    this.order.payments!.splice(
      this.order.payments!.findIndex((entry) => entry === payment),
      1
    );
  }

  async updatePayment(index: number, payment: PaymentDTO, callback?: () => void) {
    this.$set(this.order.payments!, index, payment);
    if (callback) {
      await this.saveOrder();
      callback();
    }
  }

  updateNoteRemainingPayment(note: string) {
    this.order.noteRemainingPayment = note;
  }

  showOrderDocumentDialog() {
    Object.entries(this.order.documentData).forEach((entry) => {
      const documentData = entry[1];
      documentData.salesmanId = documentData.salesmanId || this.order.salesmanId;
      documentData.locationId = documentData.locationId || this.order.location;
      documentData.date = documentData.date || dayjs().format("YYYY-MM-DD");
      documentData.productGroups = documentData.productGroups?.length
        ? documentData.productGroups
        : [{ supplierId: "", products: [] }];
      documentData.settings = documentData.settings || {};
    });
    this.showDocumentDialog = true;
  }

  @Watch("order.changes")
  updateShowChangesArray() {
    this.showChangesDates =
      this.order.changes != null ? this.order.changes.map(() => false) : [];
  }

  @Watch("order.secondAssemblyDate")
  updateAssemblyExpenses() {
    if (
      this.order.assemblyExpense &&
      this.order.assemblyExpense === this.defaultValues.assemblyExpense &&
      this.order.secondAssemblyDate
    ) {
      this.order.assemblyExpense = this.defaultValues.assemblyExpense * 2;
    } else if (
      this.order.assemblyExpense &&
      this.order.assemblyExpense === this.defaultValues.assemblyExpense * 2 &&
      !this.order.secondAssemblyDate
    ) {
      this.order.assemblyExpense = this.defaultValues.assemblyExpense;
    }
  }

  @Watch("dialog")
  resetObserver() {
    this.$refs.validationObserver.reset();
  }

  @Watch("initialOrderValues", { immediate: true })
  async updateInitialOrder() {
    this.loadingOrder = true;
    try {
      if (this.initialOrderValues?.id) {
        this.order = (await orderApi.getOrder(this.initialOrderValues.id)).data;
      } else {
        this.order = {
          type: this.initialOrderValues.type || OrderType.Normal,
          salesmanId: this.initialOrderValues?.salesmanId,
          exhibitionSale: this.initialOrderValues?.isExhibitionOrder,
          additionalExpenses: [],
          changes: [],
          connections: [],
          payments: this.initialOrderValues?.isExhibitionOrder
            ? [
                {
                  date: dayjs().format("YYYY-MM-DD"),
                  price: 2000,
                  paymentType: OrderPaymentType.Bank,
                  paid: false,
                },
              ]
            : [],
          suppliers: [],
          documentDataHistory: [],
          documentData: {},
        };

        if (this.defaultValues.shipmentExpense > 0) {
          this.order.shipmentExpense = this.defaultValues.shipmentExpense;
        }
        if (this.defaultValues.assemblyExpense > 0) {
          this.order.assemblyExpense = this.defaultValues.assemblyExpense;
        }
      }
      this.initialOrderJSON = JSON.stringify(this.order);
      this.allowClientEdit = this.order.clientId == null;
      this.showLockClientValuesButton = !this.allowClientEdit;
      this.updateProvision();
    } finally {
      this.loadingOrder = false;
    }
    this.activeWindow = true;
  }

  async updateDocumentData(
    confirmed: boolean,
    documentData: DocumentDataDTO,
    documentType: DocumentType,
    totalAmountGross: number,
    callback?: () => void
  ) {
    if (!confirmed) {
      this.showDocumentDialog = false;
      return;
    }

    if (documentData.settings.useTotalAmountForOrder) {
      this.order.orderPrice = totalAmountGross;
    }
    if (documentData.settings.usePaymentsForOrder) {
      documentData.payments.forEach((payment) => {
        const paymentIndex = this.order.payments.findIndex(
          (element) => element.id === payment.id
        );
        if (paymentIndex < 0) {
          this.addPayment(payment);
        } else {
          this.updatePayment(paymentIndex, payment);
        }
      });
    }
    if (!this.order.documentData[documentType.toString()]) {
      this.order.documentData[documentType.toString()] = documentData;
    }
    await this.saveOrder();
    if (callback) {
      callback();
    }
  }

  updateSalesmanId() {
    this.updateLocation();
    this.updateProvision();
  }

  updateFileChanges(hasChanges: boolean) {
    this.hasFileChanges = hasChanges;
  }

  async updateProvision() {
    this.loadingOrder = true;
    try {
      this.provision = (await userApi.getProvision(this.order.salesmanId)).data;
    } finally {
      this.loadingOrder = false;
    }
  }

  async toggleOrderCancellation() {
    if (this.order.cancelled) {
      this.order.cancelled = false;
    } else if (this.showCancellationDialog) {
      this.order.cancelled = true;
      this.showCancellationDialog = false;
    } else {
      this.order.cancelled = false;
      this.showCancellationDialog = true;
    }
  }

  saveSupplierStockValues(supplierValue?: SupplierCalculationDTO) {
    this.showOrderSupplierStockDialog = false;
    if (!supplierValue) {
      return;
    }
    const supplierIndex = this.order.suppliers.findIndex(
      (s) => s.id === supplierValue.id
    );
    this.$set(this.order.suppliers, supplierIndex, supplierValue);
    this.saveOrder();
  }

  getSupplierStockColor(supplierValue: SupplierCalculationDTO): string {
    if (supplierValue.deliveryDate) {
      return "success";
    }
    if (!supplierValue.confirmedDeliveryDate) {
      return "error";
    }
    const deliveryDate = dayjs(supplierValue.confirmedDeliveryDate);
    const today = dayjs();
    return deliveryDate.isBefore(today) && deliveryDate.week() !== today.week()
      ? "error"
      : "primary";
  }
}
