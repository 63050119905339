import Vue from "vue";
import Component from "vue-class-component";
import { LocationDTO } from "../api";
import { Getter } from "vuex-class";
import { RootGetters } from "../store";
import { DropdownPair } from "./../interfaces";

@Component
export class LocationMixin extends Vue {
  @Getter(RootGetters.getLocations)
  locations!: LocationDTO[];

  getLocationName(locationId: string): string {
    if (!locationId) {
      return "";
    }
    const existingLocation = this.locations.find(
      (location) => location.id === locationId
    );
    return (
      existingLocation?.name ||
      this.$t(`locationsEnum.${locationId}`).toString()
    );
  }

  get locationValues(): Array<DropdownPair<string>> {
    return this.locations
      .filter((entry) => entry.enabled)
      .map((entry) => ({
        key: entry.id!,
        label: entry.name!,
      }));
  }
}
